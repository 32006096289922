module.exports={
    "adult": {
        "name": "Adult",
        "code": "adult",
        "isJersey": true,
        "isPant": true
    },
    "adultLineman": {
        "name": "Adult Lineman",
        "code": "adultLineman",
        "isJersey": true,
        "isPant": false
    },
    "youth": {
        "name": "Youth",
        "code": "youth",
        "isJersey": true,
        "isPant": true
    },
    "adult7pad": {
        "name": "Adult 7PAD",
        "code": "adult7pad",
        "isJersey": false,
        "isPant": true
    },
    "youth7pad": {
        "name": "Youth 7PAD",
        "code": "youth7pad",
        "isJersey": false,
        "isPant": true
    }
}