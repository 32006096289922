module.exports={
    "compression": {
        "name": "Compression",
        "code": "compression",
        "isJersey": true,
        "isPant": false
    },
    "lean": {
        "name": "Lean",
        "code": "lean",
        "isJersey": true,
        "isPant": false
    },
    "regular": {
        "name": "Regular fit",
        "code": "regular",
        "isJersey": true,
        "isPant": false
    }
}