module.exports={
    "toggles": {
        "all": [
            "on",
            "off"
        ],
        "off": [
            "off"
        ],
        "notAvailable": [
            "notAvailable"
        ]
    },
    "fonts": {
        "all": [
            "anaheim",
            "arizona",
            "brushScript",
            "connecticut",
            "fancy",
            "fullBlock",
            "maryland",
            "method",
            "miami",
            "oldEnglish",
            "plainBlock",
            "script",
            "proBlock",
            "monolith",
            "stinger",
            "rock",
            "anaheimNumber",
            "arizona47",
            "brushScriptNumber",
            "connecticutNumber",
            "fancy47",
            "fullBlockNumber",
            "marylandNumber",
            "methodNumber",
            "miami47",
            "plainBlockNumber",
            "proBlockNumber",
            "monolithNumber",
            "stingerNumber",
            "rockNumber"
        ],
        "teamName": [
            "anaheim",
            "arizona",
            "brushScript",
            "connecticut",
            "fancy",
            "fullBlock",
            "maryland",
            "method",
            "miami",
            "oldEnglish",
            "plainBlock",
            "script",
            "proBlock",
            "monolith",
            "stinger",
            "rock"
        ],
        "playerName": [
            "anaheim",
            "arizona",
            "brushScript",
            "connecticut",
            "fancy",
            "fullBlock",
            "maryland",
            "method",
            "miami",
            "oldEnglish",
            "plainBlock",
            "script",
            "proBlock",
            "monolith",
            "stinger",
            "rock"
        ],
        "watermarkText": [
            "anaheim",
            "arizona",
            "brushScript",
            "connecticut",
            "fancy",
            "fullBlock",
            "maryland",
            "method",
            "miami",
            "oldEnglish",
            "plainBlock",
            "script",
            "proBlock",
            "monolith",
            "stinger",
            "rock"
        ],
        "allText": [
            "anaheim",
            "arizona",
            "brushScript",
            "connecticut",
            "fancy",
            "fullBlock",
            "maryland",
            "method",
            "miami",
            "plainBlock",
            "script",
            "proBlock",
            "monolith",
            "stinger",
            "rock"
        ],
        "fasttrackText": [
            "connecticut",
            "fullBlock",
            "method",
            "miami",
            "script",
            "monolith",
            "stinger",
            "rock"
        ],
        "number": [
            "anaheimNumber",
            "arizona47",
            "brushScriptNumber",
            "connecticutNumber",
            "fancy47",
            "fullBlockNumber",
            "marylandNumber",
            "methodNumber",
            "miami47",
            "plainBlockNumber",
            "proBlockNumber",
            "monolithNumber",
            "stingerNumber",
            "rockNumber"
        ],
        "allNumber": [
            "anaheimNumber",
            "arizona47",
            "brushScriptNumber",
            "connecticutNumber",
            "fancy47",
            "fullBlockNumber",
            "marylandNumber",
            "methodNumber",
            "miami47",
            "plainBlockNumber",
            "monolithNumber",
            "stingerNumber",
            "rockNumber"
        ],
        "fasttrackNumber": [
            "connecticutNumber",
            "fullBlockNumber",
            "methodNumber",
            "miami47",
            "monolithNumber",
            "stingerNumber",
            "rockNumber"
        ]
    },
    "jerseys": {
        "all": [
            "PJDIV47",
            "PJCT47",
            "PJHD47",
            "PJTX47",
            "PJTEC47",
            "PJC147",
            "PJF147",
            "PJCAL47",
            "PACJMU47",
            "PACJSR",
            "GLJ905547",
            "MJA",
            "MJB",
            "MJC",
            "MJD",
            "MJE",
            "FJCT",
            "FJHD",
            "FJDIVF",
            "FJ147",
            "FJ255",
            "LJDIV47VF",
            "LJDIV47VFE",
            "PJ47VF1",
            "PJ47VF2",
            "PJ47VF3",
            "PJ47VF4",
            "PJ47VF5",
            "PJ47VF6",
            "PJ47VF7",
            "PJ47VF8",
            "PJ47VF9",
            "PJMZ47",
            "PJMZ47E",
            "VFCJ1",
            "VFCJ2",
            "VFCJ3",
            "VFCJ4",
            "VFCJ5",
            "VFCJ6",
            "VFCJ7",
            "VFCJ8",
            "VFCJ9",
            "VFCJ10",
            "VFCJD",
            "VFCJDE",
            "CJEHS",
            "RVFJ4",
            "RVFJ7",
            "RVFJ8",
            "RVFJFG",
            "RVFJMZ",
            "RVFJM",
            "RVFJME",
            "PJ47VFD",
            "PJ147",
            "VFCMJA",
            "VFCMJB",
            "VFCMJC",
            "VFCMJD",
            "VFCMJE",
            "VFCJMZ",
            "PJ47VF10E",
            "VFCJ10E",
            "PJ47VF10",
            "GLJ905547VF",
            "GLJ905547VFE",
            "PJ47VFMJC",
            "PJ47VFMJD",
            "PJ47VFMJE",
            "FFVN1",
            "FFVN2",
            "FFVN3",
            "FFVN4",
            "FFVN5",
            "FFVN6",
            "FFVN7",
            "FFSL1",
            "FFSL2",
            "FFSL3",
            "FFSL4",
            "FFSL5",
            "FFSL6",
            "FFSL7"
        ],
        "default": [
            "PJDIV47",
            "PJCT47",
            "PJHD47",
            "PJTX47",
            "PJC147",
            "GLJ905547",
            "LJDIV47VF",
            "LJDIV47VFE",
            "PJ47VF1",
            "PJ47VF3",
            "PJ47VF4",
            "PJ47VF5",
            "PJ47VF7",
            "PJ47VF8",
            "PJ47VF9",
            "PJMZ47",
            "PJMZ47E",
            "VFCJ1",
            "VFCJ3",
            "VFCJ4",
            "VFCJ5",
            "VFCJ7",
            "VFCJ8",
            "VFCJ9",
            "VFCJ10",
            "VFCJD",
            "VFCJDE",
            "CJEHS",
            "RVFJ4",
            "RVFJ7",
            "RVFJ8",
            "RVFJFG",
            "RVFJMZ",
            "RVFJM",
            "RVFJME",
            "PJ47VFD",
            "PJ147",
            "VFCMJA",
            "VFCMJB",
            "VFCMJC",
            "VFCMJD",
            "VFCMJE",
            "VFCJMZ",
            "VFCJ10E",
            "GLJ905547VF",
            "PJ47VFMJC",
            "PJ47VFMJD",
            "PJ47VFMJE",
            "FFVN1",
            "FFVN2",
            "FFVN3",
            "FFVN4",
            "FFVN5",
            "FFVN6",
            "FFVN7",
            "FFSL1",
            "FFSL2",
            "FFSL3",
            "FFSL4",
            "FFSL5",
            "FFSL6",
            "FFSL7"
        ],
        "bsn": [
            "PJDIV47",
            "PJCT47",
            "PJHD47",
            "PJTX47",
            "PJC147",
            "GLJ905547",
            "LJDIV47VF",
            "LJDIV47VFE",
            "PJ47VF1",
            "PJ47VF3",
            "PJ47VF4",
            "PJ47VF5",
            "PJ47VF7",
            "PJ47VF8",
            "PJ47VF9",
            "PJMZ47",
            "PJMZ47E",
            "VFCJ1",
            "VFCJ3",
            "VFCJ4",
            "VFCJ5",
            "VFCJ7",
            "VFCJ8",
            "VFCJ9",
            "VFCJ10",
            "VFCJD",
            "VFCJDE",
            "CJEHS",
            "RVFJ4",
            "RVFJ7",
            "RVFJ8",
            "RVFJFG",
            "RVFJMZ",
            "RVFJM",
            "RVFJME",
            "PJ47VFD",
            "PJ147",
            "VFCMJA",
            "VFCMJB",
            "VFCMJC",
            "VFCMJD",
            "VFCMJE",
            "VFCJMZ",
            "PJ47VF10E",
            "VFCJ10E",
            "PJ47VF10",
            "GLJ905547VF",
            "PJ47VFMJC",
            "PJ47VFMJD",
            "PJ47VFMJE",
            "FFVN1",
            "FFVN2",
            "FFVN3",
            "FFVN4",
            "FFVN5",
            "FFVN6",
            "FFVN7",
            "FFSL1",
            "FFSL2",
            "FFSL3",
            "FFSL4",
            "FFSL5",
            "FFSL6",
            "FFSL7"
        ]
    },
    "pants": {
        "all": [
            "PPDIV",
            "PP45X",
            "PPTEC",
            "PPF4",
            "PPCAL",
            "PACPMU",
            "GLP0909S",
            "GLP0909",
            "MPA",
            "MPB",
            "MPC",
            "MPD",
            "MPE",
            "F45P",
            "F4500P",
            "FP147",
            "F3500P",
            "PPVF1",
            "PPVF2",
            "PPVF3",
            "PPVF4",
            "PPVF5",
            "PPVF6",
            "PPVF7",
            "PPVF8",
            "PPVF9",
            "PPMZ",
            "VFP1",
            "VFP2",
            "VFP3",
            "VFP4",
            "VFP5",
            "VFP6",
            "VFP7",
            "VFP8",
            "VFP9",
            "FP147",
            "FPL",
            "VFPD",
            "PPVFD",
            "PP147",
            "VFPMZ"
        ],
        "default": [
            "PPDIV",
            "GLP0909",
            "MPB",
            "MPC",
            "MPD",
            "MPE",
            "FP147",
            "PPVF1",
            "PPVF3",
            "PPVF4",
            "PPVF5",
            "PPVF7",
            "PPVF8",
            "PPVF9",
            "PPMZ",
            "VFP1",
            "VFP3",
            "VFP4",
            "VFP5",
            "VFP7",
            "VFP8",
            "VFP9",
            "FP147",
            "FPL",
            "VFPD",
            "PPVFD",
            "PP147",
            "VFPMZ"
        ],
        "bsn": [
            "PPDIV",
            "GLP0909",
            "MPB",
            "MPC",
            "MPD",
            "MPE",
            "FP147",
            "PPVF1",
            "PPVF3",
            "PPVF4",
            "PPVF5",
            "PPVF7",
            "PPVF8",
            "PPVF9",
            "PPMZ",
            "VFP1",
            "VFP3",
            "VFP4",
            "VFP5",
            "VFP7",
            "VFP8",
            "VFP9",
            "FP147",
            "FPL",
            "VFPD",
            "PPVFD",
            "PP147",
            "VFPMZ"
        ]
    },
    "garments": {
        "all": [
            "PJDIV47",
            "PJCT47",
            "PJHD47",
            "PJTX47",
            "PJTEC47",
            "PJC147",
            "PJF147",
            "PJCAL47",
            "PACJMU47",
            "PACJSR",
            "GLJ905547",
            "MJA",
            "MJB",
            "MJC",
            "MJD",
            "MJE",
            "FJCT",
            "FJHD",
            "FJDIVF",
            "FJ147",
            "FJ255",
            "LJDIV47VF",
            "LJDIV47VFE",
            "PJ47VF1",
            "PJ47VF2",
            "PJ47VF3",
            "PJ47VF4",
            "PJ47VF5",
            "PJ47VF6",
            "PJ47VF7",
            "PJ47VF8",
            "PJ47VF9",
            "PJMZ47",
            "PJMZ47E",
            "VFCJ1",
            "VFCJ2",
            "VFCJ3",
            "VFCJ4",
            "VFCJ5",
            "VFCJ6",
            "VFCJ7",
            "VFCJ8",
            "VFCJ9",
            "VFCJ10",
            "VFCJD",
            "VFCJDE",
            "CJEHS",
            "RVFJ4",
            "RVFJ7",
            "RVFJ8",
            "RVFJFG",
            "RVFJMZ",
            "RVFJM",
            "RVFJME",
            "PJ47VFD",
            "PJ147",
            "VFCMJA",
            "VFCMJB",
            "VFCMJC",
            "VFCMJD",
            "VFCMJE",
            "VFCJMZ",
            "PJ47VF10E",
            "VFCJ10E",
            "PJ47VF10",
            "GLJ905547VF",
            "GLJ905547VFE",
            "PJ47VFMJC",
            "PJ47VFMJD",
            "PJ47VFMJE",
            "FFVN1",
            "FFVN2",
            "FFVN3",
            "FFVN4",
            "FFVN5",
            "FFVN6",
            "FFVN7",
            "FFSL1",
            "FFSL2",
            "FFSL3",
            "FFSL4",
            "FFSL5",
            "FFSL6",
            "FFSL7",
            "PPDIV",
            "PP45X",
            "PPTEC",
            "PPF4",
            "PPCAL",
            "PACPMU",
            "GLP0909S",
            "GLP0909",
            "MPA",
            "MPB",
            "MPC",
            "MPD",
            "MPE",
            "F45P",
            "F4500P",
            "FP147",
            "F3500P",
            "PPVF1",
            "PPVF2",
            "PPVF3",
            "PPVF4",
            "PPVF5",
            "PPVF6",
            "PPVF7",
            "PPVF8",
            "PPVF9",
            "PPMZ",
            "VFP1",
            "VFP2",
            "VFP3",
            "VFP4",
            "VFP5",
            "VFP6",
            "VFP7",
            "VFP8",
            "VFP9",
            "FP147",
            "FPL",
            "VFPD",
            "PPVFD",
            "PP147",
            "VFPMZ"
        ],
        "default": [
            "PJDIV47",
            "PJCT47",
            "PJHD47",
            "PJTX47",
            "PJC147",
            "GLJ905547",
            "LJDIV47VF",
            "LJDIV47VFE",
            "PJ47VF1",
            "PJ47VF3",
            "PJ47VF4",
            "PJ47VF5",
            "PJ47VF7",
            "PJ47VF8",
            "PJ47VF9",
            "PJMZ47",
            "PJMZ47E",
            "VFCJ1",
            "VFCJ3",
            "VFCJ4",
            "VFCJ5",
            "VFCJ7",
            "VFCJ8",
            "VFCJ9",
            "VFCJ10",
            "VFCJD",
            "VFCJDE",
            "CJEHS",
            "RVFJ4",
            "RVFJ7",
            "RVFJ8",
            "RVFJFG",
            "RVFJMZ",
            "RVFJM",
            "RVFJME",
            "PJ47VFD",
            "PJ147",
            "VFCMJA",
            "VFCMJB",
            "VFCMJC",
            "VFCMJD",
            "VFCMJE",
            "VFCJMZ",
            "VFCJ10E",
            "GLJ905547VF",
            "PJ47VFMJC",
            "PJ47VFMJD",
            "PJ47VFMJE",
            "FFVN1",
            "FFVN2",
            "FFVN3",
            "FFVN4",
            "FFVN5",
            "FFVN6",
            "FFVN7",
            "FFSL1",
            "FFSL2",
            "FFSL3",
            "FFSL4",
            "FFSL5",
            "FFSL6",
            "FFSL7",
            "PPDIV",
            "GLP0909",
            "MPB",
            "MPC",
            "MPD",
            "MPE",
            "FP147",
            "PPVF1",
            "PPVF3",
            "PPVF4",
            "PPVF5",
            "PPVF7",
            "PPVF8",
            "PPVF9",
            "PPMZ",
            "VFP1",
            "VFP3",
            "VFP4",
            "VFP5",
            "VFP7",
            "VFP8",
            "VFP9",
            "FP147",
            "FPL",
            "VFPD",
            "PPVFD",
            "PP147",
            "VFPMZ"
        ],
        "bsn": [
            "PJDIV47",
            "PJCT47",
            "PJHD47",
            "PJTX47",
            "PJC147",
            "GLJ905547",
            "LJDIV47VF",
            "LJDIV47VFE",
            "PJ47VF1",
            "PJ47VF3",
            "PJ47VF4",
            "PJ47VF5",
            "PJ47VF7",
            "PJ47VF8",
            "PJ47VF9",
            "PJMZ47",
            "PJMZ47E",
            "VFCJ1",
            "VFCJ3",
            "VFCJ4",
            "VFCJ5",
            "VFCJ7",
            "VFCJ8",
            "VFCJ9",
            "VFCJ10",
            "VFCJD",
            "VFCJDE",
            "CJEHS",
            "RVFJ4",
            "RVFJ7",
            "RVFJ8",
            "RVFJFG",
            "RVFJMZ",
            "RVFJM",
            "RVFJME",
            "PJ47VFD",
            "PJ147",
            "VFCMJA",
            "VFCMJB",
            "VFCMJC",
            "VFCMJD",
            "VFCMJE",
            "VFCJMZ",
            "PJ47VF10E",
            "VFCJ10E",
            "PJ47VF10",
            "GLJ905547VF",
            "PJ47VFMJC",
            "PJ47VFMJD",
            "PJ47VFMJE",
            "FFVN1",
            "FFVN2",
            "FFVN3",
            "FFVN4",
            "FFVN5",
            "FFVN6",
            "FFVN7",
            "FFSL1",
            "FFSL2",
            "FFSL3",
            "FFSL4",
            "FFSL5",
            "FFSL6",
            "FFSL7",
            "PPDIV",
            "GLP0909",
            "MPB",
            "MPC",
            "MPD",
            "MPE",
            "FP147",
            "PPVF1",
            "PPVF3",
            "PPVF4",
            "PPVF5",
            "PPVF7",
            "PPVF8",
            "PPVF9",
            "PPMZ",
            "VFP1",
            "VFP3",
            "VFP4",
            "VFP5",
            "VFP7",
            "VFP8",
            "VFP9",
            "FP147",
            "FPL",
            "VFPD",
            "PPVFD",
            "PP147",
            "VFPMZ"
        ]
    },
    "colors": {
        "customCompression": [
            "B",
            "W",
            "SG",
            "GR",
            "MA",
            "C",
            "S",
            "BO",
            "LG",
            "DG",
            "CB",
            "R",
            "N",
            "PU",
            "VG"
        ],
        "customCompressionOptional": [
            "none",
            "B",
            "W",
            "SG",
            "GR",
            "MA",
            "C",
            "S",
            "BO",
            "LG",
            "DG",
            "CB",
            "R",
            "N",
            "PU",
            "VG"
        ],
        "customLean": [
            "B",
            "W",
            "SG",
            "GR",
            "MA",
            "C",
            "S",
            "BO",
            "LG",
            "DG",
            "CB",
            "R",
            "N",
            "PU",
            "VG"
        ],
        "customLeanOptional": [
            "none",
            "B",
            "W",
            "SG",
            "GR",
            "MA",
            "C",
            "S",
            "BO",
            "LG",
            "DG",
            "CB",
            "R",
            "N",
            "PU",
            "VG"
        ],
        "customZone": [
            "B",
            "W",
            "SG",
            "MA",
            "C",
            "S",
            "BO",
            "LG",
            "DG",
            "CB",
            "R",
            "N",
            "PU",
            "VG"
        ],
        "customZoneOptional": [
            "none",
            "B",
            "W",
            "SG",
            "MA",
            "C",
            "S",
            "BO",
            "LG",
            "DG",
            "CB",
            "R",
            "N",
            "PU",
            "VG"
        ],
        "customPant": [
            "B",
            "W",
            "SG",
            "GR",
            "MA",
            "C",
            "S",
            "BO",
            "LG",
            "DG",
            "CB",
            "R",
            "N",
            "PU",
            "VG"
        ],
        "customPantOptional": [
            "none",
            "B",
            "W",
            "SG",
            "GR",
            "MA",
            "C",
            "S",
            "BO",
            "LG",
            "DG",
            "CB",
            "R",
            "N",
            "PU",
            "VG"
        ],
        "FJCT": [
            "B",
            "BO",
            "DG",
            "N",
            "R",
            "S",
            "W-B",
            "W-BO",
            "W-DG",
            "W-N",
            "W-R",
            "W-S"
        ],
        "FJCTOptional": [
            "none",
            "B",
            "BO",
            "DG",
            "N",
            "R",
            "S",
            "W-B",
            "W-BO",
            "W-DG",
            "W-N",
            "W-R",
            "W-S"
        ],
        "FJ147": [
            "B",
            "N",
            "R",
            "S",
            "DG",
            "GR",
            "W"
        ],
        "FJ147Optional": [
            "none",
            "B",
            "N",
            "R",
            "S",
            "DG",
            "GR",
            "W"
        ],
        "FJHD": [
            "W",
            "B",
            "C",
            "CB",
            "DG",
            "MA",
            "N",
            "R",
            "S",
            "PU",
            "GR"
        ],
        "FJHDOptional": [
            "none",
            "W",
            "B",
            "C",
            "CB",
            "DG",
            "MA",
            "N",
            "R",
            "S",
            "PU",
            "GR"
        ],
        "FJDIVF": [
            "B",
            "DG",
            "MA",
            "N",
            "R",
            "S",
            "W-B",
            "W-DG",
            "W-MA",
            "W-N",
            "W-R",
            "W-S"
        ],
        "FJDIVFOptional": [
            "none",
            "B",
            "DG",
            "MA",
            "N",
            "R",
            "S",
            "W-B",
            "W-DG",
            "W-MA",
            "W-N",
            "W-R",
            "W-S"
        ],
        "FJ255": [
            "B",
            "DG",
            "GR",
            "MA",
            "N",
            "R",
            "S",
            "W"
        ],
        "FJ255Optional": [
            "none",
            "B",
            "DG",
            "GR",
            "MA",
            "N",
            "R",
            "S",
            "W"
        ],
        "knit": [
            "B",
            "W",
            "S",
            "GR",
            "BG",
            "DB",
            "DM",
            "MA",
            "HP",
            "LP",
            "C",
            "SG",
            "TX",
            "BO",
            "TN",
            "LG",
            "LT",
            "OG",
            "VG",
            "K",
            "DG",
            "AQ",
            "MB",
            "CB",
            "AF",
            "R",
            "N",
            "PU"
        ],
        "knitOptional": [
            "none",
            "B",
            "W",
            "S",
            "GR",
            "BG",
            "DB",
            "DM",
            "MA",
            "HP",
            "LP",
            "C",
            "SG",
            "TX",
            "BO",
            "TN",
            "LG",
            "LT",
            "OG",
            "VG",
            "K",
            "DG",
            "AQ",
            "MB",
            "CB",
            "AF",
            "R",
            "N",
            "PU"
        ],
        "F45P": [
            "B",
            "GR",
            "W",
            "N",
            "R",
            "S"
        ],
        "F45POptional": [
            "none",
            "B",
            "GR",
            "W",
            "N",
            "R",
            "S"
        ],
        "F4500P": [
            "B",
            "DG",
            "GR",
            "N",
            "R",
            "S",
            "SG",
            "VG",
            "W"
        ],
        "F4500POptional": [
            "none",
            "B",
            "DG",
            "GR",
            "N",
            "R",
            "S",
            "SG",
            "VG",
            "W"
        ],
        "FP147": [
            "B",
            "DG",
            "GR",
            "LG",
            "N",
            "PU",
            "R",
            "S",
            "SG",
            "VG",
            "W"
        ],
        "FP147Optional": [
            "none",
            "B",
            "DG",
            "GR",
            "LG",
            "N",
            "PU",
            "R",
            "S",
            "SG",
            "VG",
            "W"
        ],
        "F3500P": [
            "B",
            "GR",
            "W"
        ],
        "F3500POptional": [
            "none",
            "B",
            "GR",
            "W"
        ],
        "F45014": [
            "W"
        ],
        "F45014Optional": [
            "none",
            "W"
        ],
        "ribKnit": [
            "GR",
            "B",
            "W",
            "SG",
            "BG",
            "DB",
            "DM",
            "HP",
            "LP",
            "C",
            "S",
            "TX",
            "BO",
            "TO",
            "LG",
            "LT",
            "K",
            "DG",
            "AQ",
            "MB",
            "CB",
            "AF",
            "R",
            "N",
            "PU",
            "OG",
            "VG"
        ],
        "ribKnitOptional": [
            "none",
            "GR",
            "B",
            "W",
            "SG",
            "BG",
            "DB",
            "DM",
            "HP",
            "LP",
            "C",
            "S",
            "TX",
            "BO",
            "TO",
            "LG",
            "LT",
            "K",
            "DG",
            "AQ",
            "MB",
            "CB",
            "AF",
            "R",
            "N",
            "PU",
            "OG",
            "VG"
        ],
        "sublimated": [
            "AF",
            "AQ",
            "B",
            "BG",
            "BO",
            "C",
            "CAB",
            "CB",
            "CMB",
            "CMG",
            "CMT",
            "DB",
            "DG",
            "FLB",
            "FLG",
            "GR",
            "HP",
            "K",
            "LG",
            "LIME",
            "LP",
            "LT",
            "MA",
            "MB",
            "N",
            "OG",
            "P",
            "PU",
            "R",
            "S",
            "SG",
            "TE",
            "TO",
            "TR",
            "TX",
            "VG",
            "W",
            "YLW",
            "DSW"
        ],
        "sublimatedOptional": [
            "none",
            "AF",
            "AQ",
            "B",
            "BG",
            "BO",
            "C",
            "CAB",
            "CB",
            "CMB",
            "CMG",
            "CMT",
            "DB",
            "DG",
            "FLB",
            "FLG",
            "GR",
            "HP",
            "K",
            "LG",
            "LIME",
            "LP",
            "LT",
            "MA",
            "MB",
            "N",
            "OG",
            "P",
            "PU",
            "R",
            "S",
            "SG",
            "TE",
            "TO",
            "TR",
            "TX",
            "VG",
            "W",
            "YLW",
            "DSW"
        ],
        "embroidery": [
            "B",
            "W",
            "SG",
            "DB",
            "DM",
            "HP",
            "LP",
            "C",
            "S",
            "BO",
            "TO",
            "LG",
            "LT",
            "OG",
            "VG",
            "K",
            "DG",
            "FLG",
            "MB",
            "CB",
            "R",
            "N",
            "PU"
        ],
        "embroideryOptional": [
            "none",
            "B",
            "W",
            "SG",
            "DB",
            "DM",
            "HP",
            "LP",
            "C",
            "S",
            "BO",
            "TO",
            "LG",
            "LT",
            "OG",
            "VG",
            "K",
            "DG",
            "FLG",
            "MB",
            "CB",
            "R",
            "N",
            "PU"
        ],
        "PP": [
            "B",
            "W",
            "PL",
            "SG",
            "BG",
            "DB",
            "DM",
            "HP",
            "LP",
            "C",
            "S",
            "BO",
            "TX",
            "LG",
            "LT",
            "OG",
            "VG",
            "AZ",
            "K",
            "DG",
            "AQ",
            "MB",
            "CB",
            "AF",
            "R",
            "N",
            "PU"
        ],
        "PPOptional": [
            "none",
            "B",
            "W",
            "PL",
            "SG",
            "BG",
            "DB",
            "DM",
            "HP",
            "LP",
            "C",
            "S",
            "BO",
            "TX",
            "LG",
            "LT",
            "OG",
            "VG",
            "AZ",
            "K",
            "DG",
            "AQ",
            "MB",
            "CB",
            "AF",
            "R",
            "N",
            "PU"
        ],
        "RVT": [
            "AF",
            "AQ",
            "B",
            "BG",
            "BO",
            "C",
            "CB",
            "CMB",
            "CMG",
            "CMT",
            "DB",
            "DG",
            "FLB",
            "FLG",
            "GR",
            "HP",
            "K",
            "LG",
            "LIME",
            "LP",
            "LT",
            "MA",
            "MB",
            "N",
            "OG",
            "P",
            "PU",
            "R",
            "S",
            "SG",
            "TE",
            "TO",
            "TX",
            "VG",
            "W",
            "YLW",
            "REF"
        ],
        "RVTOptional": [
            "none",
            "AF",
            "AQ",
            "B",
            "BG",
            "BO",
            "C",
            "CB",
            "CMB",
            "CMG",
            "CMT",
            "DB",
            "DG",
            "FLB",
            "FLG",
            "GR",
            "HP",
            "K",
            "LG",
            "LIME",
            "LP",
            "LT",
            "MA",
            "MB",
            "N",
            "OG",
            "P",
            "PU",
            "R",
            "S",
            "SG",
            "TE",
            "TO",
            "TX",
            "VG",
            "W",
            "YLW",
            "REF"
        ],
        "fabricCuff": [
            "B",
            "W"
        ],
        "fabricCuffOptional": [
            "none",
            "B",
            "W"
        ],
        "fabricCollar": [
            "B",
            "W"
        ],
        "fabricCollarOptional": [
            "none",
            "B",
            "W"
        ],
        "reflective": [
            "REF"
        ],
        "reflectiveOptional": [
            "none",
            "REF"
        ]
    },
    "opPatterns": {
        "all": []
    },
    "ribKnitPatterns": {
        "all": [
            "none",
            "CLSU"
        ],
        "CLSU": [
            "none",
            "CLSU"
        ]
    },
    "sleeveStripes": {
        "all": [
            "none",
            "2",
            "5",
            "6",
            "15",
            "26"
        ]
    },
    "designPatterns": {
        "all": [
            "solidColor",
            "argyle",
            "autoCamo",
            "autoDigitalCamo",
            "bustedDots",
            "bustedHoneycomb",
            "camo",
            "carbonBlock",
            "carbonFiber",
            "check",
            "check5",
            "digitalCamo",
            "digitalWave",
            "electric",
            "fade",
            "flannel",
            "geo",
            "gradientDots",
            "gradientPlaid",
            "hound",
            "plaid",
            "plated",
            "proStripe",
            "scratch",
            "shutter",
            "splat",
            "split",
            "tieDye",
            "crackle",
            "triadStripe",
            "heather",
            "zebraDots",
            "houndsTooth",
            "rvfj4",
            "rvfj7",
            "rvfj8",
            "rvfjfg",
            "rvfjmz",
            "ohio",
            "streak",
            "predator",
            "bearcat",
            "wingback",
            "aggie",
            "flag",
            "digitalWave-element",
            "tigerStripe",
            "ribKnit",
            "ribKnitPattern01",
            "ribKnitPattern02",
            "ribKnitPattern03",
            "ribKnitPattern06",
            "pattern01",
            "pattern02",
            "pattern03",
            "pattern06"
        ],
        "solidColors": [
            "solidColor"
        ],
        "insert": [
            "solidColor",
            "ohio",
            "streak",
            "predator",
            "bearcat",
            "digitalCamo",
            "camo",
            "electric",
            "fade",
            "check5",
            "zebraDots",
            "wingback",
            "aggie",
            "flag"
        ],
        "ribKnit": [
            "ribKnitPattern01",
            "ribKnitPattern02",
            "ribKnitPattern03",
            "ribKnitPattern06"
        ],
        "pattern": [
            "pattern01",
            "pattern02",
            "pattern03",
            "pattern06"
        ],
        "lettering": [
            "solidColor",
            "bustedDots",
            "bustedHoneycomb",
            "camo",
            "check",
            "check5",
            "digitalCamo",
            "electric",
            "fade",
            "flannel",
            "gradientDots",
            "plated",
            "proStripe",
            "scratch",
            "shutter",
            "split",
            "crackle",
            "heather",
            "zebraDots",
            "houndsTooth"
        ],
        "lettering7v7": [
            "solidColor",
            "autoCamo",
            "autoDigitalCamo",
            "bustedDots",
            "bustedHoneycomb",
            "camo",
            "check",
            "digitalCamo",
            "electric",
            "fade",
            "flannel",
            "gradientDots",
            "plated",
            "proStripe",
            "scratch",
            "shutter",
            "splat",
            "split",
            "crackle",
            "triadStripe",
            "heather",
            "zebraDots",
            "houndsTooth"
        ]
    },
    "logos": {
        "all": [
            "USFLAG",
            "7ON7",
            "BASEBALL1",
            "BASEBALL2",
            "BASEBALL3",
            "BASEBALL4",
            "BASEBALL5",
            "BASEBALL6",
            "BASKETBALL1",
            "BASKETBALL2",
            "BASKETBALL3",
            "BASKETBALL4",
            "BEAR1",
            "BEAR2",
            "BEAR3",
            "BEAR4",
            "BEAR5",
            "BEAR6",
            "BIRD1",
            "BIRD2",
            "BIRD3",
            "BIRD4",
            "BIRD5",
            "BIRD6",
            "BIRD7",
            "BIRD8",
            "BIRD9",
            "BIRD10",
            "BIRD11",
            "BIRD12",
            "BIRD13",
            "BIRD14",
            "BIRD15",
            "BIRD16",
            "BIRD17",
            "BIRD18",
            "BIRD19",
            "BIRD20",
            "BIRD21",
            "BIRD22",
            "BIRD23",
            "BIRD24",
            "BIRD25",
            "BIRD26",
            "BIRD27",
            "BIRD28",
            "BIRD29",
            "BIRD30",
            "BRAVE1",
            "BRAVE2",
            "BUCCANEER",
            "BUFFALO",
            "BULLDOG1",
            "BULLDOG2",
            "BULLDOG3",
            "BULLDOG4",
            "BULLDOG5",
            "BULLDOG6",
            "BULLDOG7",
            "BULLDOG8",
            "BULLDOG9",
            "BULLDOG10",
            "CARDINAL1",
            "CARDINAL2",
            "CAT1",
            "CAT2",
            "CAT3",
            "CAT4",
            "CAT5",
            "CAT6",
            "CAT7",
            "CAT8",
            "CAT9",
            "CAT10",
            "CAT11",
            "CAT12",
            "CAT13",
            "CAT14",
            "CAT15",
            "CAT16",
            "CAT17",
            "CAT18",
            "CAT19",
            "CAVALIER1",
            "CAVALIER2",
            "CAVALIER3",
            "CHIEF",
            "COBRA",
            "COMICTROJAN",
            "COUGAR",
            "DEVIL",
            "DEVIL2",
            "DEVIL3",
            "DEVIL4",
            "DEVIL5",
            "DRAGON",
            "DRAGONH13",
            "DRAGONI13",
            "EAGLE1",
            "EAGLE2",
            "EAGLE3",
            "EAGLE4",
            "EAGLE5",
            "FOOTBALL1",
            "FOOTBALL2",
            "FOOTBALL3",
            "FOOTBALL4",
            "FOOTBALL5",
            "HELMET",
            "HORSE1",
            "HORSE10",
            "HORSE2",
            "HORSE3",
            "HORSE4",
            "HORSE5",
            "HORSE6",
            "HORSE7",
            "HORSE8",
            "HORSE9",
            "HUSKYF1",
            "INDIAN1",
            "INDIAN2",
            "INDIAN3",
            "INDIAN4",
            "INDIAN5",
            "INDIAN6",
            "INDIAN7",
            "INDIAN8",
            "INDIAN9",
            "INDIAN10",
            "INDIAN11",
            "INDIAN12",
            "INDIAN13",
            "INDIAN14",
            "INDIAN15",
            "INSECT1",
            "INSECT2",
            "INSECT3",
            "INSECT4",
            "INSECT5",
            "KNIGHT1",
            "KNIGHT2",
            "KNIGHT3",
            "KNIGHT4",
            "KNIGHT5",
            "KNIGHT6",
            "KNIGHT7",
            "KNIGHT8",
            "KNIGHT9",
            "KNIGHT10",
            "LIGHTNING1",
            "LIGHTNING2",
            "LIGHTNING3",
            "LIGHTNING4",
            "LION1",
            "LION2",
            "LION3",
            "LION4",
            "LION5",
            "LION6",
            "LION7",
            "LION8",
            "LONGHORNS",
            "MARLIN",
            "MISC1",
            "MISC2",
            "MISC3",
            "MISC4",
            "MISC5",
            "MISC6",
            "MISC7",
            "MISC8",
            "MISC9",
            "MISC10",
            "MISC11",
            "MISC12",
            "MISC13",
            "MISC14",
            "MISC15",
            "MISC16",
            "MISC17",
            "MISC18",
            "MISC19",
            "MISC20",
            "MISC21",
            "MISC22",
            "MISC23",
            "MUSTANG1",
            "MUSTANG2",
            "PANTHER1",
            "PANTHER2",
            "PAW1",
            "PAW2",
            "PAW3",
            "PAW4",
            "PAW5",
            "PAW6",
            "PAW7",
            "PAW8",
            "PAW9",
            "PAW10",
            "PAW11",
            "PAW12",
            "PAW13",
            "PAW14",
            "PAW15",
            "PAW16",
            "PAW17",
            "PAW18",
            "PIRATE1",
            "PIRATE2",
            "PIRATE3",
            "PIRATE4",
            "PIRATE5",
            "PIRATE6",
            "PIRATE7",
            "PIRATE8",
            "PIRATE9",
            "PIRATE10",
            "PIRATE11",
            "PIRATE12",
            "PIRATE13",
            "RAM1",
            "RAM2",
            "REBEL",
            "SHAMROCK1",
            "SHAMROCK2",
            "SHARK1",
            "SNAKE1",
            "SNAKE2",
            "SNAKE3",
            "SPARTAN1",
            "SPARTAN2",
            "SPARTAN3",
            "SPARTAN4",
            "SPARTAN5",
            "SPARTAN6",
            "SPARTAN7",
            "SPARTAN8",
            "SPARTAN9",
            "SPARTAN10",
            "SPARTAN11",
            "SPARTAN12",
            "SPARTAN13",
            "SPARTAN14",
            "STAR1",
            "STAR2",
            "STAR3",
            "STAR4",
            "STAR5",
            "TIGER1",
            "TIGER2",
            "TIGER3",
            "VIKING1",
            "VIKING2",
            "VIKING3",
            "VIKING4",
            "VIKING5",
            "VIKING6",
            "WILDCAT1",
            "WILDCAT2",
            "WILDCAT3",
            "WOLF",
            "WOLF1",
            "WOLF2",
            "WOLF3",
            "WOLF4",
            "WOLF5"
        ],
        "watermark": [
            "7ON7",
            "BASEBALL4",
            "BASKETBALL3",
            "BEAR2",
            "BIRD15",
            "BIRD16",
            "BIRD24",
            "BIRD25",
            "BIRD30",
            "CAT9",
            "DRAGONH13",
            "DRAGONI13",
            "HORSE7",
            "HORSE8",
            "HORSE9",
            "INDIAN3",
            "INDIAN4",
            "INDIAN6",
            "INDIAN7",
            "INSECT4",
            "KNIGHT3",
            "KNIGHT5",
            "MISC2",
            "MISC14",
            "MISC16",
            "MISC17",
            "MISC20",
            "MISC22",
            "MISC23",
            "PAW1",
            "PAW3",
            "PAW4",
            "PAW5",
            "PAW7",
            "PAW8",
            "PAW9",
            "PAW10",
            "PAW11",
            "PAW13",
            "PAW14",
            "PAW15",
            "PAW16",
            "PIRATE11",
            "PIRATE12",
            "SHAMROCK1",
            "SHAMROCK2",
            "SNAKE1",
            "SPARTAN9",
            "SPARTAN10",
            "SPARTAN11",
            "SPARTAN14",
            "STAR1",
            "STAR4",
            "STAR5",
            "WOLF2"
        ]
    },
    "tabs": {
        "all": [
            "select",
            "fabricColors",
            "options",
            "decorate"
        ]
    },
    "clothingCollections": {
        "all": [
            "customJerseys",
            "stockJerseys",
            "sublimatedJerseys",
            "noTuckSublimatedJerseys",
            "flagFootball7V7SublimatedJerseys",
            "customPants",
            "stockPants",
            "sublimatedPants"
        ]
    },
    "clothingTypes": {
        "all": [
            "jersey",
            "pant"
        ]
    },
    "clothingQualities": {
        "all": [
            "varsity",
            "league"
        ]
    },
    "areaFillExceptions": {
        "all": [
            "PJHD47",
            "PJ47VF2",
            "PJ47VF3",
            "PJ47VF5",
            "VFCJ2",
            "VFCJ3",
            "VFCJ5",
            "CJEHS",
            "RVFJ4",
            "RVFJ7",
            "RVFJ8",
            "RVFJFG",
            "RVFJMZ",
            "RVFJM",
            "RVFJME",
            "GLJ905547",
            "GLJ905547VF",
            "PACJSR",
            "MPB",
            "GLP0909",
            "PPVF2",
            "PPVF3",
            "PPVF5",
            "VFP2",
            "VFP3",
            "VFP5",
            "PPMZ"
        ]
    },
    "decos": {
        "all": [
            "jersey",
            "pant"
        ]
    },
    "decoOptions": {
        "all": [
            "beltColor",
            "logoColor",
            "CLSU",
            "sleeveStripes"
        ]
    },
    "decoSets": {
        "all": [
            "vt",
            "rvt",
            "fullSublimation",
            "fasttrack",
            "pantDeco",
            "none"
        ]
    },
    "decoPlacements": {
        "all": [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "14",
            "30",
            "none",
            "5B",
            "14P",
            "17LR",
            "18LR",
            "27LR",
            "28LR",
            "30B",
            "5and30"
        ]
    },
    "decoPlacementSizes": {
        "all": [
            "vt-0",
            "vt-1",
            "vt-2",
            "vt-3",
            "vt-4",
            "vt-5",
            "vt-5B",
            "vt-6",
            "vt-14P",
            "vt-14",
            "vt-17L",
            "vt-17R",
            "vt-17LR",
            "vt-18L",
            "vt-18R",
            "vt-18LR",
            "vt-30",
            "vt-30B",
            "rvt-0",
            "rvt-1",
            "rvt-2",
            "rvt-3",
            "rvt-4",
            "rvt-5",
            "rvt-5B",
            "rvt-6",
            "rvt-14P",
            "rvt-14",
            "rvt-17L",
            "rvt-17R",
            "rvt-17LR",
            "rvt-18L",
            "rvt-18R",
            "rvt-18LR",
            "rvt-30",
            "rvt-30B",
            "fullSublimation-0",
            "fullSublimation-1",
            "fullSublimation-2",
            "fullSublimation-3",
            "fullSublimation-4",
            "fullSublimation-5",
            "fullSublimation-5B",
            "fullSublimation-6",
            "fullSublimation-14P",
            "fullSublimation-14",
            "fullSublimation-17L",
            "fullSublimation-17R",
            "fullSublimation-17LR",
            "fullSublimation-18L",
            "fullSublimation-18R",
            "fullSublimation-18LR",
            "fullSublimation-30",
            "fullSublimation-30B",
            "fasttrack-0",
            "fasttrack-1",
            "fasttrack-2",
            "fasttrack-3",
            "fasttrack-4",
            "fasttrack-5",
            "fasttrack-5B",
            "fasttrack-6",
            "fasttrack-14P",
            "fasttrack-14",
            "fasttrack-17L",
            "fasttrack-17R",
            "fasttrack-17LR",
            "fasttrack-18L",
            "fasttrack-18R",
            "fasttrack-18LR",
            "fasttrack-30",
            "fasttrack-30B",
            "pantDeco-27L",
            "pantDeco-27R",
            "pantDeco-27LR",
            "pantDeco-28L",
            "pantDeco-28R",
            "pantDeco-28LR"
        ]
    },
    "layouts": {
        "all": [
            "straight",
            "angled",
            "verticalArch"
        ]
    },
    "cutTypes": {
        "all": [
            "adult",
            "adultLineman",
            "youth",
            "adult7pad",
            "youth7pad"
        ]
    },
    "fitTypes": {
        "all": [
            "compression",
            "lean",
            "regular"
        ]
    },
    "decoSizes": {
        "all": [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "8",
            "10",
            "12",
            "0.125",
            "0.375",
            "0.75",
            "1.5",
            "2.5",
            "3.5"
        ]
    }
}