module.exports={
    "customJerseys": {
        "code": "customJerseys",
        "name": "Custom Jerseys"
    },
    "stockJerseys": {
        "code": "stockJerseys",
        "name": "Stock Jerseys"
    },
    "sublimatedJerseys": {
        "code": "sublimatedJerseys",
        "name": "Sublimated Jerseys"
    },
    "noTuckSublimatedJerseys": {
        "code": "noTuckSublimatedJerseys",
        "name": "No Tuck Sublimated Jerseys"
    },
    "flagFootball7V7SublimatedJerseys": {
        "code": "flagFootball7V7SublimatedJerseys",
        "name": "Flag Football / 7v7 Sublimated Jerseys"
    },
    "customPants": {
        "code": "customPants",
        "name": "Custom Pants"
    },
    "stockPants": {
        "code": "stockPants",
        "name": "Stock Pants"
    },
    "sublimatedPants": {
        "code": "sublimatedPants",
        "name": "Sublimated Pants"
    }
}