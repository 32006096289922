module.exports={
    "PJDIV47": {
        "icon": "assets/product-icons/PJDIV47.jpg?2014-09-30",
        "category": "customJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "PJDIV47",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2014",
        "modelId": "PJDIV47",
        "textureId": "PJDIV47",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "shoulderRight",
                    "shoulderLeft"
                ],
                "area2": [
                    "front",
                    "backLower",
                    "collarFrontOut",
                    "collarFrontIn"
                ],
                "area3": [
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area4": [
                    "insetRight",
                    "insetLeft"
                ],
                "area5": [],
                "area6": [
                    "*.piping"
                ],
                "area7": [
                    "collarBack"
                ],
                "area8": [
                    "frontLower",
                    "back"
                ],
                "area9": [
                    "sleeveLowerRight",
                    "sleeveLowerLeft"
                ],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "insert",
                "area4": "insert",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors",
                "area8": "solidColors",
                "area9": "solidColors",
                "area10": null
            },
            "titles": {
                "area1": "Cowl",
                "area2": "Upper Front/Lower Back Body",
                "area3": "Sleeves",
                "area4": "Side inserts",
                "area5": "Mesh Underarm",
                "area6": "Piping",
                "area7": "Collar",
                "area8": "Lower Front/Upper Back Body",
                "area9": "Fabric Cuff",
                "area10": null
            },
            "colorSets": [
                "customCompression"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "embroidery",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "vt",
                "rvt"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": {
                    "sku": "PJDIV47",
                    "price": null,
                    "note": "Fill In Through 9/1/20"
                },
                "cutType_adultLineman": {
                    "sku": "LJDIV47",
                    "price": null,
                    "note": "Fill In Through 9/1/20"
                },
                "cutType_youth": {
                    "sku": "YPJDIV47",
                    "price": null,
                    "note": "Fill In Through 9/1/20"
                }
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "PJCT47": {
        "icon": "assets/product-icons/PJCT47.jpg?2014-09-30",
        "category": "customJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "PJCT47",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2014",
        "modelId": "PJCT47",
        "textureId": "PJCT47",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "shoulderRight",
                    "shoulderLeft"
                ],
                "area2": [
                    "front",
                    "back",
                    "collarFrontOut",
                    "collarFrontIn"
                ],
                "area3": [
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area4": [
                    "insetLowerRight",
                    "insetLowerLeft"
                ],
                "area5": [
                    "*.piping"
                ],
                "area6": [
                    "insetUpperRight",
                    "insetUpperLeft"
                ],
                "area7": [
                    "sleeveLowerRight",
                    "sleeveLowerLeft"
                ],
                "area8": [
                    "collarBack"
                ],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors",
                "area8": "solidColors",
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Cowl",
                "area2": "Body",
                "area3": "Sleeves",
                "area4": "Inserts",
                "area5": "Piping",
                "area6": "Underarm Insert",
                "area7": "Cuff",
                "area8": "Collar",
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "customCompression"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "embroidery",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "vt",
                "rvt"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "PJCT47",
                    "price": null,
                    "note": "Fill In Through 9/1/20"
                },
                "cutType_youth": {
                    "sku": "YPJCT47",
                    "price": null,
                    "note": "Fill In Through 9/1/20"
                }
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "PJHD47": {
        "icon": "assets/product-icons/PJHD47.jpg?2014-09-30",
        "category": "customJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "PJHD47",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2014",
        "modelId": "PJHD47",
        "textureId": "PJHD47",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "shoulderLeft",
                    "shoulderRight"
                ],
                "area2": [
                    "front",
                    "back",
                    "frontLower",
                    "backLower",
                    "insetLowerRight",
                    "insetLowerLeft",
                    "collarFrontOutLeft",
                    "collarFrontOutRight",
                    "collarFrontInLeft",
                    "collarFrontInRight"
                ],
                "area3": [
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area4": [
                    "insetUpperRight",
                    "insetUpperLeft"
                ],
                "area5": [
                    "sleeveLowerRight",
                    "sleeveLowerLeft"
                ],
                "area6": [
                    "collarBack"
                ],
                "area7": [
                    "collarFrontOutLeft",
                    "collarFrontOutRight",
                    "collarFrontInLeft",
                    "collarFrontInRight",
                    "*.piping"
                ],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors",
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Cowl",
                "area2": "Body",
                "area3": "Sleeves",
                "area4": "Side Inserts",
                "area5": "Cuff",
                "area6": "Collar",
                "area7": "Reflective Front Collar / Reflective Cuff Piping",
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "customCompression"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "embroidery",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "vt",
                "rvt"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "PJHD47",
                    "price": null,
                    "note": "Fill In Through 9/1/20"
                },
                "cutType_youth": {
                    "sku": "YPJHD47",
                    "price": null,
                    "note": "Fill In Through 9/1/20"
                }
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "PJTX47": {
        "icon": "assets/product-icons/PJTX47.jpg?2014-09-30",
        "category": "customJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "PJTX47",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2014",
        "modelId": "PJTX47",
        "textureId": "PJTX47",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "shoulders"
                ],
                "area2": [
                    "front",
                    "back",
                    "insetLowerRight",
                    "insetLowerLeft"
                ],
                "area3": [
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area4": [
                    "insetShoulderInnerRight",
                    "insetShoulderInnerLeft"
                ],
                "area5": [
                    "insetShoulderOuterRight",
                    "insetShoulderOuterLeft"
                ],
                "area6": [
                    "insetUpperRight",
                    "insetUpperLeft"
                ],
                "area7": [
                    "sleeveLowerRight",
                    "sleeveLowerLeft"
                ],
                "area8": [
                    "sleeveLowerAccentRight",
                    "sleeveLowerAccentLeft"
                ],
                "area9": [
                    "collar"
                ],
                "area10": [
                    "collarInsert"
                ]
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors",
                "area8": "solidColors",
                "area9": "solidColors",
                "area10": "solidColors"
            },
            "titles": {
                "area1": "Cowl",
                "area2": "Body",
                "area3": "Sleeve",
                "area4": "Inside Cowl Insert",
                "area5": "Outside Cowl Insert",
                "area6": "Side Inserts",
                "area7": "Cuff",
                "area8": "Cuff Accent",
                "area9": "Collar",
                "area10": "Collar Insert"
            },
            "colorSets": [
                "customCompression"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "embroidery",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "vt",
                "rvt"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "PJTX47",
                    "price": null,
                    "note": "Fill In Through 9/1/20"
                },
                "cutType_youth": {
                    "sku": "YPJTX47",
                    "price": null,
                    "note": "Fill In Through 9/1/20"
                }
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "PJTEC47": {
        "icon": "assets/product-icons/PJTEC47.jpg?2014-09-30",
        "category": "customJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "quality": "varsity",
        "code": "PJTEC47",
        "isReversible": false,
        "hasAssets": false,
        "history": "Added 2014, Removed 2016",
        "modelId": "PJTEC47",
        "textureId": "PJTEC47",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "shoulderRight",
                    "shoulderLeft"
                ],
                "area2": [
                    "front",
                    "back",
                    "collar"
                ],
                "area3": [
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area4": [
                    "insetLowerRight",
                    "insetLowerLeft"
                ],
                "area5": [
                    "insetBack",
                    "insetUpperRight",
                    "insetUpperLeft"
                ],
                "area6": [
                    "*.piping"
                ],
                "area7": [
                    "sleeveLowerRight",
                    "sleeveLowerLeft"
                ],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors",
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Cowl",
                "area2": "Body",
                "area3": "Sleeves",
                "area4": "Side Inserts",
                "area5": "Neck Inserts",
                "area6": "Piping",
                "area7": "Fabric Cuff",
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "customCompression"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "embroidery",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "vt",
                "rvt"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": {
                    "sku": "PJTEC47",
                    "price": null,
                    "note": null
                },
                "cutType_adultLineman": {
                    "sku": "LJTEC47",
                    "price": null,
                    "note": null
                },
                "cutType_youth": {
                    "sku": "YPJTEC47",
                    "price": null,
                    "note": null
                }
            },
            "fitType_lean": {
                "cutType_adult": {
                    "sku": "PJTEC",
                    "price": null,
                    "note": null
                },
                "cutType_adultLineman": {
                    "sku": "LJTEC",
                    "price": null,
                    "note": null
                },
                "cutType_youth": {
                    "sku": "YPJTEC",
                    "price": null,
                    "note": null
                }
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "PJC147": {
        "icon": "assets/product-icons/PJC147.jpg?2014-09-30",
        "category": "customJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "PJC147",
        "isReversible": false,
        "hasAssets": true,
        "history": "Before 2014",
        "modelId": "PJC147",
        "textureId": "PJC147",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area2": [
                    "shoulderRight",
                    "shoulderLeft",
                    "front",
                    "back",
                    "frontLower",
                    "backLower",
                    "collarFrontIn",
                    "collarFrontOut"
                ],
                "area3": [
                    "insetRight",
                    "insetLeft",
                    "shoulderInsetRight",
                    "shoulderInsetLeft"
                ],
                "area4": [
                    "sleeveLowerRight",
                    "sleeveLowerLeft",
                    "collarBack"
                ],
                "area5": [],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Sleeves",
                "area2": "Body/Cowl",
                "area3": "Side & Shoulder Inserts",
                "area4": "Cuff / Collar",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "customCompression"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "embroidery",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "vt",
                "rvt"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "PJC147",
                    "price": null,
                    "note": "Fill In Through 9/1/20"
                },
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "PJF147": {
        "icon": "assets/product-icons/PJF147.jpg?2014-09-30",
        "category": "customJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "quality": "varsity",
        "code": "PJF147",
        "isReversible": false,
        "hasAssets": true,
        "history": "Before 2014",
        "modelId": "PJF147",
        "textureId": "PJF147",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "shoulders",
                    "insetBackRight",
                    "insetBackLeft"
                ],
                "area2": [
                    "front",
                    "backLower",
                    "insetLeft",
                    "insetRight"
                ],
                "area3": [
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area4": [
                    "insetFrontRight",
                    "insetFrontLeft"
                ],
                "area5": [
                    "*.insertPiping"
                ],
                "area6": [
                    "*.bottomCollarPiping"
                ],
                "area7": [
                    "collar"
                ],
                "area8": [
                    "sleeveLowerRight",
                    "sleeveLowerLeft"
                ],
                "area9": [
                    "frontLower",
                    "back"
                ],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors",
                "area8": "solidColors",
                "area9": "solidColors",
                "area10": null
            },
            "titles": {
                "area1": "Cowl",
                "area2": "Upper Front / Lower Back Body",
                "area3": "Sleeves",
                "area4": "Inserts",
                "area5": "Insert Piping",
                "area6": "Bottom Collar Piping",
                "area7": "Collar",
                "area8": "Cuffs",
                "area9": "Lower Front / Upper Back Body",
                "area10": null
            },
            "colorSets": [
                "customCompression"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "embroidery",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "vt",
                "rvt"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": {
                    "sku": "PJF147",
                    "price": null,
                    "note": null
                },
                "cutType_adultLineman": {
                    "sku": "LJF147",
                    "price": null,
                    "note": null
                },
                "cutType_youth": {
                    "sku": "YPJF147",
                    "price": null,
                    "note": null
                }
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "PJCAL47": {
        "icon": "assets/product-icons/PJCAL47.jpg?2014-09-30",
        "category": "customJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "quality": "varsity",
        "code": "PJCAL47",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2014",
        "modelId": "PJCAL47",
        "textureId": "PJCAL47",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "shoulderRight",
                    "shoulderLeft"
                ],
                "area2": [
                    "front",
                    "back",
                    "sideInsetMiddleRight",
                    "sideInsetMiddleLeft",
                    "sideInsetUpperRight",
                    "sideInsetUpperLeft",
                    "backUpper",
                    "collarFront",
                    "insetCollar",
                    "frontInsetUpperRight",
                    "frontInsetUpperLeft",
                    "frontInsetLowerRight",
                    "frontInsetLowerLeft",
                    "frontInsetMiddleUpRight",
                    "frontInsetMiddleUpLeft",
                    "collarBack"
                ],
                "area3": [
                    "sleeveRight",
                    "sleeveLeft",
                    "sleeveInsetLeft",
                    "sleeveInsetRight"
                ],
                "area4": [
                    "insetNeckLeft",
                    "insetNeckRight"
                ],
                "area5": [
                    "frontInsetMiddleRight",
                    "frontInsetMiddleLeft"
                ],
                "area6": [
                    "sideInsetLowerLeft",
                    "sideInsetLowerRight"
                ],
                "area7": [
                    "*.backSleeveInsert"
                ],
                "area8": [
                    "sleeveLowerRight",
                    "sleeveLowerLeft"
                ],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors",
                "area8": "solidColors",
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Cowl",
                "area2": "Body",
                "area3": "Sleeves",
                "area4": "Neck",
                "area5": "Chest Inserts",
                "area6": "Lower Side Insert",
                "area7": "Back Sleeve Insert",
                "area8": "Fabric Cuff",
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "customCompression"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "embroidery",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "vt",
                "rvt"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": {
                    "sku": "PJCAL47",
                    "price": null,
                    "note": null
                },
                "cutType_adultLineman": {
                    "sku": "LJCAL47",
                    "price": null,
                    "note": null
                },
                "cutType_youth": {
                    "sku": "YPJCAL47",
                    "price": null,
                    "note": null
                }
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "PACJMU47": {
        "icon": "assets/product-icons/PACJMU47.jpg?2014-09-30",
        "category": "customJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "quality": "varsity",
        "code": "PACJMU47",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2014",
        "modelId": "PACJMU47",
        "textureId": "PACJMU47",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "sleeveRight",
                    "sleeveLeft",
                    "backInsetRight",
                    "backInsetLeft"
                ],
                "area2": [
                    "front",
                    "backLower",
                    "collarFront"
                ],
                "area3": [
                    "sideInsetUpperLeft",
                    "sideInsetUpperRight"
                ],
                "area4": [
                    "sideInsetLowerLeft",
                    "sideInsetLowerRight"
                ],
                "area5": [
                    "*.frontPiping"
                ],
                "area6": [
                    "*.backPiping"
                ],
                "area7": [
                    "collarBack"
                ],
                "area8": [
                    "frontLower",
                    "back",
                    "sleeveInsetRight",
                    "sleeveInsetLeft"
                ],
                "area9": [
                    "sleeveLowerLeft",
                    "sleeveLowerRight"
                ],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors",
                "area8": "solidColors",
                "area9": "solidColors",
                "area10": null
            },
            "titles": {
                "area1": "Cowl/Sleeves",
                "area2": "Upper Front / Lower Back Body",
                "area3": "Front of Inserts",
                "area4": "Back of Inserts",
                "area5": "Front Piping",
                "area6": "Back Piping",
                "area7": "Collar",
                "area8": "Lower Front / Upper Back Body",
                "area9": "Fabric Cuff",
                "area10": null
            },
            "colorSets": [
                "customCompression"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "embroidery",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "vt",
                "rvt"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": {
                    "sku": "PACJMU47",
                    "price": null,
                    "note": null
                },
                "cutType_adultLineman": {
                    "sku": "LJMU47",
                    "price": null,
                    "note": null
                },
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "PACJSR": {
        "icon": "assets/product-icons/PACJSR.jpg?2014-09-30",
        "category": "customJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "quality": "varsity",
        "code": "PACJSR",
        "isReversible": false,
        "hasAssets": false,
        "history": "Added 2014, Removed 2016",
        "modelId": "PACJSR",
        "textureId": "PACJSR",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "shoulderRight",
                    "shoulderLeft"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area4": [
                    "*.piping"
                ],
                "area5": [
                    "sleeveLowerRight",
                    "sleeveLowerLeft"
                ],
                "area6": [
                    "collar"
                ],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "ribKnit",
                "area6": "ribKnit",
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Cowl",
                "area2": "Body",
                "area3": "Sleeves",
                "area4": "Piping",
                "area5": "Fabric Cuff",
                "area6": "Fabric Collar",
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "customCompression"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "embroidery",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "vt",
                "rvt"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": {
                    "sku": "PACJSR",
                    "price": null,
                    "note": null
                },
                "cutType_adultLineman": {
                    "sku": "LJSR",
                    "price": null,
                    "note": null
                },
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "GLJ905547": {
        "icon": "assets/product-icons/GLJ905547.jpg?2014-09-30",
        "category": "customJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "GLJ905547",
        "isReversible": false,
        "hasAssets": true,
        "history": "Before 2014",
        "modelId": "GLJ905547",
        "textureId": "GLJ905547",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area1",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "shoulders"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area4": [
                    "insetRight",
                    "insetLeft"
                ],
                "area5": [
                    "sleeveLowerRight",
                    "sleeveLowerLeft"
                ],
                "area6": [
                    "collar"
                ],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "ribKnit",
                "area6": "ribKnit",
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Cowl",
                "area2": "Body",
                "area3": "Sleeves",
                "area4": "Inserts",
                "area5": "Cuff",
                "area6": "Collar",
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "customCompression"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "embroidery",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "vt"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": {
                    "sku": "GLJ905547",
                    "price": null,
                    "note": "Fill In Through 9/1/20"
                },
                "cutType_adultLineman": {
                    "sku": "LJ905547",
                    "price": null,
                    "note": "Fill In Through 9/1/20"
                },
                "cutType_youth": {
                    "sku": "YGLJ905547",
                    "price": null,
                    "note": "Fill In Through 9/1/20"
                }
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "MJA": {
        "icon": "assets/product-icons/MJA.jpg?2014-09-30",
        "category": "customJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "quality": "league",
        "code": "MJA",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2014, Removed 2018",
        "modelId": "MJA",
        "textureId": "MJA",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area1",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "shoulders"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area4": [
                    "insetRight",
                    "insetLeft"
                ],
                "area5": [
                    "collar"
                ],
                "area6": [
                    "sleeveLowerRight",
                    "sleeveLowerLeft"
                ],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Cowl",
                "area2": "Body",
                "area3": "Sleeves",
                "area4": "Side Inserts",
                "area5": "Collar",
                "area6": "Cuff",
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "customZone"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "embroidery",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "vt"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": {
                    "sku": "MJA",
                    "price": null,
                    "note": null
                },
                "cutType_adultLineman": null,
                "cutType_youth": {
                    "sku": "YMJA",
                    "price": null,
                    "note": null
                }
            }
        },
        "skuNote": null
    },
    "MJB": {
        "icon": "assets/product-icons/MJB.jpg?2014-09-30",
        "category": "customJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "quality": "league",
        "code": "MJB",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2014, Removed 2018",
        "modelId": "MJB",
        "textureId": "MJB",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area1",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "shoulders"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area4": [
                    "sideInsetRight",
                    "sideInsetLeft"
                ],
                "area5": [
                    "*.piping"
                ],
                "area6": [
                    "shoulderInsetRight",
                    "shoulderInsetLeft"
                ],
                "area7": [
                    "collar"
                ],
                "area8": [
                    "sleeveLowerRight",
                    "sleeveLowerLeft"
                ],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors",
                "area8": "solidColors",
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Cowl",
                "area2": "Body",
                "area3": "Sleeves",
                "area4": "Side Inserts",
                "area5": "Piping on Side Insert",
                "area6": "Shoulder Insert",
                "area7": "Collar",
                "area8": "Cuff",
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "customZone"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "embroidery",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "vt"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": {
                    "sku": "MJB",
                    "price": null,
                    "note": null
                },
                "cutType_adultLineman": null,
                "cutType_youth": {
                    "sku": "YMJB",
                    "price": null,
                    "note": null
                }
            }
        },
        "skuNote": null
    },
    "MJC": {
        "icon": "assets/product-icons/MJC.jpg?2014-09-30",
        "category": "customJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "quality": "league",
        "code": "MJC",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2014, Removed 2018",
        "modelId": "MJC",
        "textureId": "MJC",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "shoulderRight",
                    "shoulderLeft"
                ],
                "area2": [
                    "front",
                    "back",
                    "collarFront",
                    "frontInsetOuterRight",
                    "frontInsetOuterLeft"
                ],
                "area3": [
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area4": [
                    "frontInsetInnerRight",
                    "frontInsetInnerLeft"
                ],
                "area5": [
                    "*.piping"
                ],
                "area6": [
                    "collarBack"
                ],
                "area7": [
                    "sleeveLowerRight",
                    "sleeveLowerLeft"
                ],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors",
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Cowl",
                "area2": "Body",
                "area3": "Sleeves",
                "area4": "Inserts",
                "area5": "Piping",
                "area6": "Back of Neck",
                "area7": "Cuff",
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "customZone"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "embroidery",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "vt"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": {
                    "sku": "MJC",
                    "price": null,
                    "note": null
                },
                "cutType_adultLineman": null,
                "cutType_youth": {
                    "sku": "YMJC",
                    "price": null,
                    "note": null
                }
            }
        },
        "skuNote": null
    },
    "MJD": {
        "icon": "assets/product-icons/MJD.jpg?2014-09-30",
        "category": "customJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "quality": "league",
        "code": "MJD",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2014, Removed 2018",
        "modelId": "MJD",
        "textureId": "MJD",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area1",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "shoulders"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area4": [
                    "insetLowerRight",
                    "insetLowerLeft",
                    "insetUpperRight",
                    "insetUpperLeft"
                ],
                "area5": [
                    "insetMiddleRight",
                    "insetMiddleLeft"
                ],
                "area6": [
                    "collar"
                ],
                "area7": [
                    "sleeveLowerRight",
                    "sleeveLowerLeft"
                ],
                "area8": [
                    "sleeveLowerAccentRight",
                    "sleeveLowerAccentLeft"
                ],
                "area9": [
                    "collarInsert"
                ],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors",
                "area8": "solidColors",
                "area9": "solidColors",
                "area10": null
            },
            "titles": {
                "area1": "Cowl",
                "area2": "Body",
                "area3": "Sleeves",
                "area4": "Upper & Lower Side Inserts",
                "area5": "Middle Side Inserts",
                "area6": "Collar",
                "area7": "Cuff Top",
                "area8": "Cuff Bottom",
                "area9": "Collar Insert",
                "area10": null
            },
            "colorSets": [
                "customZone"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "embroidery",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "vt"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": {
                    "sku": "MJD",
                    "price": null,
                    "note": null
                },
                "cutType_adultLineman": null,
                "cutType_youth": {
                    "sku": "YMJD",
                    "price": null,
                    "note": null
                }
            }
        },
        "skuNote": null
    },
    "MJE": {
        "icon": "assets/product-icons/MJE.jpg?2014-09-30",
        "category": "customJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "quality": "league",
        "code": "MJE",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2014, Removed 2018",
        "modelId": "MJE",
        "textureId": "MJE",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area1",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "shoulders"
                ],
                "area2": [
                    "front",
                    "back",
                    "insetSideLowerRight",
                    "insetSideLowerLeft"
                ],
                "area3": [
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area4": [
                    "shoulderInsetOuterRight",
                    "shoulderInsetOuterLeft"
                ],
                "area5": [
                    "shoulderInsetInnerRight",
                    "shoulderInsetInnerLeft"
                ],
                "area6": [
                    "insetSideUpperRight",
                    "insetSideUpperLeft"
                ],
                "area7": [
                    "sleeveLowerRight",
                    "sleeveLowerLeft"
                ],
                "area8": [
                    "sleeveLowerAccentRight",
                    "sleeveLowerAccentLeft"
                ],
                "area9": [
                    "collar"
                ],
                "area10": [
                    "collarInsert"
                ]
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors",
                "area8": "solidColors",
                "area9": "solidColors",
                "area10": "solidColors"
            },
            "titles": {
                "area1": "Cowl",
                "area2": "Body & Lower Side Inserts",
                "area3": "Sleeves",
                "area4": "Outer Cowl Insert",
                "area5": "Inner Cowl Insert",
                "area6": "Side Inserts",
                "area7": "Cuff",
                "area8": "Cuff Accent",
                "area9": "Collar",
                "area10": "Collar Insert"
            },
            "colorSets": [
                "customZone"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "embroidery",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "vt"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": {
                    "sku": "MJE",
                    "price": null,
                    "note": null
                },
                "cutType_adultLineman": null,
                "cutType_youth": {
                    "sku": "YMJE",
                    "price": null,
                    "note": null
                }
            }
        },
        "skuNote": null
    },
    "FJCT": {
        "icon": "assets/product-icons/FJCT.jpg?2014-09-30",
        "category": "stockJerseys",
        "optionList": [],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "quality": "varsity",
        "code": "FJCT",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2014, Removed 2018",
        "modelId": "PJCT47",
        "textureId": "PJCT47",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [
                    "shoulderRight",
                    "shoulderLeft",
                    "front",
                    "back",
                    "collarFrontOut",
                    "collarFrontIn",
                    "sleeveRight",
                    "sleeveLeft",
                    "insetUpperRight",
                    "insetUpperLeft",
                    "sleeveLowerRight",
                    "sleeveLowerLeft",
                    "collarBack"
                ],
                "area2-1": [
                    "insetLowerRight",
                    "insetLowerLeft",
                    "*.piping"
                ],
                "area2-2": [],
                "area1": [],
                "area2": [],
                "area3": [],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": null,
                "area2": "Garment",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "FJCT"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": null,
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "vt"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "FJCT",
                    "price": null,
                    "note": null
                },
                "cutType_youth": {
                    "sku": "YFJCT",
                    "price": null,
                    "note": null
                }
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "FJHD": {
        "icon": "assets/product-icons/FJHD.jpg?2014-09-30",
        "category": "stockJerseys",
        "optionList": [],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "quality": "varsity",
        "code": "FJHD",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2014, Removed 2018",
        "modelId": "PJHD47",
        "textureId": "PJHD47",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "shoulderLeft",
                    "shoulderRight",
                    "front",
                    "back",
                    "frontLower",
                    "backLower",
                    "collarFrontOutRight",
                    "collarFrontOutLeft",
                    "collarFrontInRight",
                    "collarFrontInLeft",
                    "sleeveRight",
                    "sleeveLeft",
                    "insetUpperRight",
                    "insetUpperLeft",
                    "insetLowerRight",
                    "insetLowerLeft",
                    "sleeveLowerRight",
                    "sleeveLowerLeft",
                    "collarBack"
                ],
                "area3": [],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": null,
                "area2": "Garment",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "FJHD"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": null,
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "vtx"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "FJHD",
                    "price": "72",
                    "note": null
                },
                "cutType_youth": {
                    "sku": "YFJHD",
                    "price": "68",
                    "note": null
                }
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "FJDIVF": {
        "icon": "assets/product-icons/FJDIVF.jpg?2014-09-30",
        "category": "stockJerseys",
        "optionList": [],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "quality": "varsity",
        "code": "FJDIVF",
        "isReversible": false,
        "hasAssets": false,
        "history": "Before 2014, Removed 2016",
        "modelId": "FJDIVF",
        "textureId": "FJDIVF",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [
                    "shoulderRight",
                    "shoulderLeft",
                    "sleeveRight",
                    "sleeveLeft",
                    "insetRight",
                    "insetLeft",
                    "collarFront",
                    "backLower",
                    "frontLower",
                    "back",
                    "front"
                ],
                "area2-1": [
                    "sleeveLowerRight",
                    "sleeveLowerLeft",
                    "collarBack",
                    "*.piping"
                ],
                "area2-2": [],
                "area1": [],
                "area2": [],
                "area3": [],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": null,
                "area2": "Garment",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "FJDIVF"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": null,
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "vt"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": {
                    "sku": "FJDIVF",
                    "price": null,
                    "note": null
                },
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "FJ147": {
        "icon": "assets/product-icons/FJ147.jpg?2014-09-30",
        "category": "stockJerseys",
        "optionList": [],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "quality": "varsity",
        "code": "FJ147",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2016, Removed 2018",
        "modelId": "FJ147",
        "textureId": "FJ147",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [
                    "back",
                    "front",
                    "backLower",
                    "frontLower",
                    "sleeveLowerRight",
                    "sleeveLowerLeft",
                    "insetLeft",
                    "insetRight",
                    "insetLowerRight",
                    "insetLowerLeft",
                    "collar",
                    "placket",
                    "shoulderRight",
                    "shoulderLeft"
                ],
                "area2-1": [
                    "insertMiddleRight",
                    "insertMiddleLeft",
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area2-2": [],
                "area1": [],
                "area2": [],
                "area3": [],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": null,
                "area2": "Garment",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "FJ147"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": null,
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "vtx"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "FJ147",
                    "price": "108",
                    "note": null
                },
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "FJ255": {
        "icon": "assets/product-icons/FJ255.jpg?2014-09-30",
        "category": "stockJerseys",
        "optionList": [],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "quality": "varsity",
        "code": "FJ255",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2014, Removed 2018",
        "modelId": "FJ255",
        "textureId": "FJ255",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "shoulders",
                    "front",
                    "back",
                    "sleeveRight",
                    "sleeveLeft",
                    "sleeveLowerLeft",
                    "sleeveLowerRight",
                    "collar"
                ],
                "area3": [],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": null,
                "area2": "Garment",
                "area3": null,
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "FJ255"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": null,
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "vt"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "LJDIV47VF": {
        "icon": "assets/product-icons/LJDIV47VF.jpg?2014-09-30",
        "category": "sublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "LJDIV47VF",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2016",
        "modelId": "PJDIV47",
        "textureId": "PJDIV47",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "shoulderRight",
                    "shoulderLeft"
                ],
                "area2": [
                    "front",
                    "backLower",
                    "collarFrontOut",
                    "collarFrontIn"
                ],
                "area3": [
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area4": [
                    "insetRight",
                    "insetLeft"
                ],
                "area5": [],
                "area6": [
                    "*.piping"
                ],
                "area7": [
                    "collarBack"
                ],
                "area8": [
                    "frontLower",
                    "back"
                ],
                "area9": [
                    "sleeveLowerRight",
                    "sleeveLowerLeft"
                ],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "insert",
                "area4": "insert",
                "area5": null,
                "area6": "solidColors",
                "area7": "solidColors",
                "area8": "solidColors",
                "area9": "solidColors",
                "area10": null
            },
            "titles": {
                "area1": "Cowl",
                "area2": "Upper Front/Lower Back Body",
                "area3": "Sleeves",
                "area4": "Side inserts",
                "area5": null,
                "area6": "Piping",
                "area7": "Collar",
                "area8": "Lower Front/Upper Back Body",
                "area9": "Fabric Cuff",
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": {
                    "sku": "PJDIV47VF",
                    "price": null,
                    "note": null
                },
                "cutType_adultLineman": {
                    "sku": "LJDIV47VF",
                    "price": "132",
                    "note": null
                },
                "cutType_youth": {
                    "sku": "YPJDIV47VF",
                    "price": null,
                    "note": null
                }
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "LJDIV47VFE": {
        "icon": "assets/product-icons/LJDIV47VFE.jpg?2014-09-30",
        "category": "noTuckSublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "LJDIV47VFE",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2018",
        "modelId": "LJDIV47VFE",
        "textureId": "LJDIV47VFE",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "shoulderRight",
                    "shoulderLeft"
                ],
                "area2": [
                    "front",
                    "backLower",
                    "collarFrontOut",
                    "collarFrontIn"
                ],
                "area3": [
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area4": [
                    "insetRight",
                    "insetLeft"
                ],
                "area5": [],
                "area6": [
                    "*.piping"
                ],
                "area7": [
                    "collarBack"
                ],
                "area8": [
                    "frontLower",
                    "back"
                ],
                "area9": [
                    "sleeveLowerRight",
                    "sleeveLowerLeft"
                ],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "insert",
                "area4": "insert",
                "area5": null,
                "area6": "solidColors",
                "area7": "solidColors",
                "area8": "solidColors",
                "area9": "solidColors",
                "area10": null
            },
            "titles": {
                "area1": "Cowl",
                "area2": "Upper Front/Lower Back Body",
                "area3": "Sleeves",
                "area4": "Side inserts",
                "area5": null,
                "area6": "Piping",
                "area7": "Collar",
                "area8": "Lower Front/Upper Back Body",
                "area9": "Fabric Cuff",
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": {
                    "sku": "PJDIV47VFE",
                    "price": null,
                    "note": null
                },
                "cutType_adultLineman": {
                    "sku": "LJDIV47VFE",
                    "price": "132",
                    "note": null
                },
                "cutType_youth": {
                    "sku": "YPJDIV47VFE",
                    "price": null,
                    "note": null
                }
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "PJ47VF1": {
        "icon": "assets/product-icons/PJ47VF1.jpg?2014-09-30",
        "category": "sublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "PJ47VF1",
        "isReversible": false,
        "hasAssets": true,
        "history": "Before 2014",
        "modelId": "PJ47VF",
        "textureId": "PJ47VF",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.stripe.color1"
                ],
                "area2": [
                    "front",
                    "back",
                    "shoulders",
                    "sleeveRight",
                    "sleeveLeft",
                    "sleeveLowerRight",
                    "sleeveLowerLeft",
                    "collarInsert"
                ],
                "area3": [
                    "*.stripe.color3"
                ],
                "area4": [
                    "collar",
                    "*.stripe.color4"
                ],
                "area5": [],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Main Sleeve Stripes",
                "area2": "Body, Cowl, Top of Sleeves, 1st Sleeve Stripe Outline",
                "area3": "2nd Sleeve Stripe Outline",
                "area4": "Collar, Side Inserts",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "PJ47VF1",
                    "price": null,
                    "note": null
                },
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "PJ47VF2": {
        "icon": "assets/product-icons/PJ47VF2.jpg?2014-09-30",
        "category": "sublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "quality": "varsity",
        "code": "PJ47VF2",
        "isReversible": false,
        "hasAssets": true,
        "history": "Before 2014, Removed 2018",
        "modelId": "PJ47VF",
        "textureId": "PJ47VF",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area2": [
                    "front",
                    "back",
                    "shoulders",
                    "collarInsert"
                ],
                "area3": [
                    "collar"
                ],
                "area4": [
                    "sleeveLowerRight",
                    "sleeveLowerLeft"
                ],
                "area5": [],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Sleeves",
                "area2": "Body Camo Main Color, Cowl Camo Main Color",
                "area3": "Collar",
                "area4": "Cuff",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "PJ47VF2",
                    "price": null,
                    "note": null
                },
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "PJ47VF3": {
        "icon": "assets/product-icons/PJ47VF3.jpg?2014-09-30",
        "category": "sublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "PJ47VF3",
        "isReversible": false,
        "hasAssets": true,
        "history": "Before 2014",
        "modelId": "PJ47VF",
        "textureId": "PJ47VF",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "sleeveLeft",
                    "sleeveRight",
                    "*.carbon.color1"
                ],
                "area2": [
                    "front",
                    "back",
                    "shoulders",
                    "collarInsert"
                ],
                "area3": [
                    "*.carbon.color3"
                ],
                "area4": [
                    "collar",
                    "sleeveLowerRight",
                    "sleeveLowerLeft"
                ],
                "area5": [],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Sleeves, Top of Side Inserts",
                "area2": "Body, Cowl",
                "area3": "Front Collar, Side Insert Accents",
                "area4": "Collar Back, Cuff",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "PJ47VF3",
                    "price": null,
                    "note": null
                },
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "PJ47VF4": {
        "icon": "assets/product-icons/PJ47VF4.jpg?2014-09-30",
        "category": "sublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "PJ47VF4",
        "isReversible": false,
        "hasAssets": true,
        "history": "Before 2014",
        "modelId": "PJ47VF",
        "textureId": "PJ47VF",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area1",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "front",
                    "back",
                    "shoulders",
                    "sleeveRight",
                    "sleeveLeft",
                    "collarInsert"
                ],
                "area2": [
                    "*.fade.color2"
                ],
                "area3": [
                    "collar"
                ],
                "area4": [
                    "sleeveLowerRight",
                    "sleeveLowerLeft"
                ],
                "area5": [],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Upper Body, Cowl, Sleeves",
                "area2": "Lower Body",
                "area3": "Collar",
                "area4": "Cuff",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "PJ47VF4",
                    "price": null,
                    "note": null
                },
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "PJ47VF5": {
        "icon": "assets/product-icons/PJ47VF5.jpg?2014-09-30",
        "category": "sublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "PJ47VF5",
        "isReversible": false,
        "hasAssets": true,
        "history": "Before 2014",
        "modelId": "PJ47VF",
        "textureId": "PJ47VF",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "sleeveLeft",
                    "sleeveRight",
                    "front.sideInsert"
                ],
                "area2": [
                    "front",
                    "back",
                    "shoulders",
                    "collarInsert"
                ],
                "area3": [
                    "front.sideInsert.digitalWave-outline"
                ],
                "area4": [
                    "collar",
                    "sleeveLowerRight",
                    "sleeveLowerLeft"
                ],
                "area5": [],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Sleeves, Side Inserts",
                "area2": "Body, Cowl, Top of Side Inserts",
                "area3": "Outline on Side Inserts",
                "area4": "Collar, Cuff",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "PJ47VF5",
                    "price": null,
                    "note": null
                },
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "PJ47VF6": {
        "icon": "assets/product-icons/PJ47VF6.jpg?2014-09-30",
        "category": "sublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "quality": "varsity",
        "code": "PJ47VF6",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2014, Removed 2018",
        "modelId": "PJ47VF",
        "textureId": "PJ47VF",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.cowl",
                    "*.hawk.color1"
                ],
                "area2": [
                    "front",
                    "back",
                    "shoulders",
                    "collar",
                    "sleeveRight",
                    "sleeveLeft",
                    "collarInsert"
                ],
                "area3": [
                    "*.hawk.color3"
                ],
                "area4": [
                    "sleeveLowerRight",
                    "sleeveLowerLeft",
                    "*.hawk.color4"
                ],
                "area5": [],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Cowl/Sleeve Inserts",
                "area2": "Body, Cowl, Sleeve, Collar",
                "area3": "Sleeve Inserts, Collar Outline",
                "area4": "Cuff, Side Inserts",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "PJ47VF6",
                    "price": null,
                    "note": null
                },
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "PJ47VF7": {
        "icon": "assets/product-icons/PJ47VF7.jpg?2014-09-30",
        "category": "sublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "PJ47VF7",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2014",
        "modelId": "PJ47VF",
        "textureId": "PJ47VF",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.wingback.color1"
                ],
                "area2": [
                    "front",
                    "back",
                    "shoulders",
                    "collarInsert"
                ],
                "area3": [
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area4": [
                    "sleeveLowerRight",
                    "sleeveLowerLeft"
                ],
                "area5": [],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Cowl Pattern, Side Insert Pattern",
                "area2": "Body, Cowl",
                "area3": "Sleeve",
                "area4": "Cuff",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "PJ47VF7",
                    "price": null,
                    "note": null
                },
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "PJ47VF8": {
        "icon": "assets/product-icons/PJ47VF8.jpg?2014-09-30",
        "category": "sublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "PJ47VF8",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2014",
        "modelId": "PJ47VF",
        "textureId": "PJ47VF",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "shoulders"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "sleeveLeft",
                    "sleeveRight",
                    "sleeveLowerLeft",
                    "sleeveLowerRight"
                ],
                "area4": [
                    "collar",
                    "collarInsert"
                ],
                "area5": [],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Cowl",
                "area2": "Body, Side Inserts",
                "area3": "Sleeve, Cuff",
                "area4": "Collar",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "PJ47VF8",
                    "price": null,
                    "note": null
                },
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "PJ47VF9": {
        "icon": "assets/product-icons/PJ47VF9.jpg?2014-09-30",
        "category": "sublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "PJ47VF9",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2014",
        "modelId": "PJ47VF",
        "textureId": "PJ47VF",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.bronco.color1"
                ],
                "area2": [
                    "front",
                    "back",
                    "collar",
                    "shoulders",
                    "sleeveRight",
                    "sleeveLeft",
                    "sleeveLowerLeft",
                    "sleeveLowerRight",
                    "collarInsert"
                ],
                "area3": [
                    "*.bronco.color3"
                ],
                "area4": [
                    "*.bronco.color4"
                ],
                "area5": [],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Back Collar",
                "area2": "Body",
                "area3": "Insert",
                "area4": "Piping",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "PJ47VF9",
                    "price": null,
                    "note": null
                },
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "PJMZ47": {
        "icon": "assets/product-icons/PJMZ47.jpg?2014-09-30",
        "category": "sublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "PJMZ47",
        "isReversible": false,
        "hasAssets": true,
        "history": "Before 2014",
        "modelId": "PJMZ47",
        "textureId": "PJMZ47",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "sleeveRight",
                    "sleeveLeft",
                    "sleeveLowerLeft",
                    "sleeveLowerRight",
                    "collarBack"
                ],
                "area2": [
                    "front",
                    "back",
                    "shoulderRight",
                    "shoulderLeft",
                    "insetRight",
                    "insetLeft",
                    "frontLower",
                    "backLower",
                    "insetUpperRight",
                    "insetUpperLeft",
                    "collarFrontIn",
                    "collarFrontOut"
                ],
                "area3": [
                    "*.mz.color3"
                ],
                "area4": [
                    "*.mz.color4"
                ],
                "area5": [
                    "insetBack"
                ],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Sleeves / Collar / Cuffs",
                "area2": "Body / Cowl",
                "area3": "Sleeve Scratch",
                "area4": "Cowl Scratch",
                "area5": "Collar Insert",
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "PJMZ47",
                    "price": null,
                    "note": null
                },
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "PJMZ47E": {
        "icon": "assets/product-icons/PJMZ47E.jpg?2014-09-30",
        "category": "noTuckSublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "PJMZ47E",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2018",
        "modelId": "PJMZ47E",
        "textureId": "PJMZ47E",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "sleeveRight",
                    "sleeveLeft",
                    "sleeveLowerLeft",
                    "sleeveLowerRight",
                    "collarBack"
                ],
                "area2": [
                    "front",
                    "back",
                    "shoulderRight",
                    "shoulderLeft",
                    "insertRight",
                    "insertLeft",
                    "frontLower",
                    "backLower",
                    "insertUpperRight",
                    "insertUpperLeft",
                    "collarFrontIn",
                    "collarFrontOut"
                ],
                "area3": [
                    "*.mz.color3"
                ],
                "area4": [
                    "*.mz.color4"
                ],
                "area5": [
                    "insertBack"
                ],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Sleeves / Collar / Cuffs",
                "area2": "Body / Cowl",
                "area3": "Sleeve Scratch",
                "area4": "Cowl Scratch",
                "area5": "Collar Insert",
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "PJMZ47E",
                    "price": null,
                    "note": null
                },
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "VFCJ1": {
        "icon": "assets/product-icons/VFCJ1.jpg?2014-09-30",
        "category": "sublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "league",
        "code": "VFCJ1",
        "isReversible": false,
        "hasAssets": true,
        "history": "Before 2014",
        "modelId": "VFCJ",
        "textureId": "VFCJ",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.stripe.color1"
                ],
                "area2": [
                    "front",
                    "back",
                    "shoulderLeft",
                    "shoulderRight",
                    "sleeveLeft",
                    "sleeveRight",
                    "sleeveLowerRight",
                    "sleeveLowerLeft"
                ],
                "area3": [
                    "*.stripe.color3"
                ],
                "area4": [
                    "collar",
                    "*.stripe.color4"
                ],
                "area5": [],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Main Sleeve Stripes",
                "area2": "Body, Cowl, Top of Sleeves, 1st Sleeve Stripe Outline",
                "area3": "2nd Sleeve Stripe Outline",
                "area4": "Collar, Side Inserts",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "VFCJ1",
                    "price": null,
                    "note": null
                },
                "cutType_youth": {
                    "sku": "YVFCJ1",
                    "price": null,
                    "note": null
                }
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "VFCJ2": {
        "icon": "assets/product-icons/VFCJ2.jpg?2014-09-30",
        "category": "sublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "quality": "league",
        "code": "VFCJ2",
        "isReversible": false,
        "hasAssets": true,
        "history": "Before 2014, Removed 2018",
        "modelId": "VFCJ",
        "textureId": "VFCJ",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area2": [
                    "front",
                    "back",
                    "shoulderLeft",
                    "shoulderRight"
                ],
                "area3": [
                    "collar"
                ],
                "area4": [
                    "sleeveLowerRight",
                    "sleeveLowerLeft"
                ],
                "area5": [],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Sleeves",
                "area2": "Body Camo Main Color, Cowl Camo Main Color",
                "area3": "Collar",
                "area4": "Cuff",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "VFCJ2",
                    "price": null,
                    "note": null
                },
                "cutType_youth": {
                    "sku": "YVFCJ2",
                    "price": null,
                    "note": null
                }
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "VFCJ3": {
        "icon": "assets/product-icons/VFCJ3.jpg?2014-09-30",
        "category": "sublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "league",
        "code": "VFCJ3",
        "isReversible": false,
        "hasAssets": true,
        "history": "Before 2014",
        "modelId": "VFCJ",
        "textureId": "VFCJ",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "sleeveLeft",
                    "sleeveRight",
                    "*.carbon.color1"
                ],
                "area2": [
                    "front",
                    "back",
                    "shoulderLeft",
                    "shoulderRight"
                ],
                "area3": [
                    "collar",
                    "*.carbon.color3"
                ],
                "area4": [
                    "*.backCollar.color1",
                    "sleeveLowerRight",
                    "sleeveLowerLeft"
                ],
                "area5": [],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Sleeves, Top of Side Inserts",
                "area2": "Body, Cowl",
                "area3": "Front Collar, Side Insert Accents",
                "area4": "Collar Back, Cuff",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "VFCJ3",
                    "price": null,
                    "note": null
                },
                "cutType_youth": {
                    "sku": "YVFCJ3",
                    "price": null,
                    "note": null
                }
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "VFCJ4": {
        "icon": "assets/product-icons/VFCJ4.jpg?2014-09-30",
        "category": "sublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "league",
        "code": "VFCJ4",
        "isReversible": false,
        "hasAssets": true,
        "history": "Before 2014",
        "modelId": "VFCJ",
        "textureId": "VFCJ",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area1",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "front",
                    "back",
                    "shoulderLeft",
                    "shoulderRight",
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area2": [
                    "*.fade.color2"
                ],
                "area3": [
                    "collar"
                ],
                "area4": [
                    "sleeveLowerRight",
                    "sleeveLowerLeft",
                    "*.fade.color4"
                ],
                "area5": [],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Upper Body, Cowl, Sleeves",
                "area2": "Lower Body",
                "area3": "Collar",
                "area4": "Cuff",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "VFCJ4",
                    "price": null,
                    "note": null
                },
                "cutType_youth": {
                    "sku": "YVFCJ4",
                    "price": null,
                    "note": null
                }
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "VFCJ5": {
        "icon": "assets/product-icons/VFCJ5.jpg?2014-09-30",
        "category": "sublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "league",
        "code": "VFCJ5",
        "isReversible": false,
        "hasAssets": true,
        "history": "Before 2014",
        "modelId": "VFCJ",
        "textureId": "VFCJ",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "sleeveLeft",
                    "sleeveRight",
                    "front.design"
                ],
                "area2": [
                    "front",
                    "back",
                    "shoulderLeft",
                    "shoulderRight"
                ],
                "area3": [
                    "front.design.digitalWave-outline"
                ],
                "area4": [
                    "collar",
                    "sleeveLowerRight",
                    "sleeveLowerLeft"
                ],
                "area5": [],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Sleeves, Side Inserts",
                "area2": "Body, Cowl, Top of Side Inserts",
                "area3": "Outline on Side Inserts",
                "area4": "Collar, Cuff",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "VFCJ5",
                    "price": null,
                    "note": null
                },
                "cutType_youth": {
                    "sku": "YVFCJ5",
                    "price": null,
                    "note": null
                }
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "VFCJ6": {
        "icon": "assets/product-icons/VFCJ6.jpg?2014-09-30",
        "category": "sublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "quality": "league",
        "code": "VFCJ6",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2014, Removed 2018",
        "modelId": "VFCJ",
        "textureId": "VFCJ",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.hawk.color1"
                ],
                "area2": [
                    "front",
                    "back",
                    "collar",
                    "shoulderLeft",
                    "shoulderRight",
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area3": [
                    "*.hawk.color3"
                ],
                "area4": [
                    "sleeveLowerRight",
                    "sleeveLowerLeft",
                    "*.hawk.color4"
                ],
                "area5": [],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Cowl/Sleeve Inserts",
                "area2": "Body, Cowl, Sleeve, Collar",
                "area3": "Sleeve Inserts, Collar Outline",
                "area4": "Cuff, Side Inserts",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "VFCJ6",
                    "price": null,
                    "note": null
                },
                "cutType_youth": {
                    "sku": "YVFCJ6",
                    "price": null,
                    "note": null
                }
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "VFCJ7": {
        "icon": "assets/product-icons/VFCJ7.jpg?2014-09-30",
        "category": "sublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "league",
        "code": "VFCJ7",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2014",
        "modelId": "VFCJ",
        "textureId": "VFCJ",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.backCollar.color1",
                    "shoulderRight.wingback.color1",
                    "shoulderLeft.wingback.color1",
                    "front.wingback.color1"
                ],
                "area2": [
                    "front",
                    "back",
                    "shoulderLeft",
                    "shoulderRight",
                    "collar"
                ],
                "area3": [
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area4": [
                    "sleeveLowerRight",
                    "sleeveLowerLeft"
                ],
                "area5": [],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Collar, Cowl Pattern, Side Insert Pattern",
                "area2": "Body, Cowl",
                "area3": "Sleeve",
                "area4": "Cuff",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "VFCJ7",
                    "price": null,
                    "note": null
                },
                "cutType_youth": {
                    "sku": "YVFCJ7",
                    "price": null,
                    "note": null
                }
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "VFCJ8": {
        "icon": "assets/product-icons/VFCJ8.jpg?2014-09-30",
        "category": "sublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "league",
        "code": "VFCJ8",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2014",
        "modelId": "VFCJ",
        "textureId": "VFCJ",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "shoulderLeft",
                    "shoulderRight"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "sleeveLeft",
                    "sleeveRight",
                    "sleeveLowerLeft",
                    "sleeveLowerRight"
                ],
                "area4": [
                    "collar"
                ],
                "area5": [],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Cowl",
                "area2": "Body, Side Inserts",
                "area3": "Sleeve, Cuff",
                "area4": "Collar",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "VFCJ8",
                    "price": null,
                    "note": null
                },
                "cutType_youth": {
                    "sku": "YVFCJ8",
                    "price": null,
                    "note": null
                }
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "VFCJ9": {
        "icon": "assets/product-icons/VFCJ9.jpg?2014-09-30",
        "category": "sublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "league",
        "code": "VFCJ9",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2014",
        "modelId": "VFCJ",
        "textureId": "VFCJ",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.backCollarLong.color1"
                ],
                "area2": [
                    "front",
                    "back",
                    "collar",
                    "shoulderLeft",
                    "shoulderRight",
                    "sleeveLeft",
                    "sleeveRight",
                    "sleeveLowerLeft",
                    "sleeveLowerRight"
                ],
                "area3": [
                    "*.bronco.color3"
                ],
                "area4": [
                    "*.bronco.color4"
                ],
                "area5": [],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Back Collar",
                "area2": "Body",
                "area3": "Insert",
                "area4": "Piping",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "VFCJ9",
                    "price": null,
                    "note": null
                },
                "cutType_youth": {
                    "sku": "YVFCJ9",
                    "price": null,
                    "note": null
                }
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "VFCJ10": {
        "icon": "assets/product-icons/VFCJ10.jpg?2014-09-30",
        "category": "sublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "league",
        "code": "VFCJ10",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2018",
        "modelId": "VFCJ",
        "textureId": "VFCJ10",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "shoulderLeft",
                    "shoulderRight"
                ],
                "area4": [
                    "*.vfcj10.color4"
                ],
                "area5": [
                    "*.vfcj10.color5"
                ],
                "area6": [
                    "*.vfcj10.color6"
                ],
                "area7": [
                    "*.vfcj10.color7"
                ],
                "area8": [
                    "collar"
                ],
                "area9": [
                    "sleeveLowerLeft",
                    "sleeveLowerRight"
                ],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors",
                "area8": "solidColors",
                "area9": "solidColors",
                "area10": null
            },
            "titles": {
                "area1": "Sleeve",
                "area2": "Body",
                "area3": "Cowl",
                "area4": "Sleeve Design",
                "area5": "Body Design",
                "area6": "Cowl Design",
                "area7": "Insert Design",
                "area8": "Collar",
                "area9": "Cuff",
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "VFCJ10",
                    "price": null,
                    "note": null
                },
                "cutType_youth": {
                    "sku": "YVFCJ10",
                    "price": null,
                    "note": null
                }
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "VFJ10",
                    "price": null,
                    "note": null
                },
                "cutType_youth": {
                    "sku": "YVFJ10",
                    "price": null,
                    "note": null
                }
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": "When building a white body jersey, colors #2, 3, 5, and 6 must be white to be NFHS legal."
    },
    "VFCJD": {
        "icon": "assets/product-icons/VFCJD.jpg?2014-09-30",
        "category": "sublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "league",
        "code": "VFCJD",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2016",
        "modelId": "VFCJ",
        "textureId": "VFCJ",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "shoulderLeft",
                    "shoulderRight"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area4": [
                    "front.sideInsert"
                ],
                "area5": [
                    "collar"
                ],
                "area6": [
                    "sleeveLowerRight",
                    "sleeveLowerLeft"
                ],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "insert",
                "area2": "solidColors",
                "area3": "insert",
                "area4": "insert",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Cowl (Sublimated Design)",
                "area2": "Body",
                "area3": "Sleeves (Sublimated Design)",
                "area4": "Side inserts (Sublimated Design)",
                "area5": "Collar",
                "area6": "Cuff",
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "VFCJD",
                    "price": "80",
                    "note": null
                },
                "cutType_youth": {
                    "sku": "YVFCJD",
                    "price": "80",
                    "note": null
                }
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "VFCJDE": {
        "icon": "assets/product-icons/VFCJDE.jpg?2014-09-30",
        "category": "noTuckSublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "league",
        "code": "VFCJDE",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2018",
        "modelId": "VFCJDE",
        "textureId": "VFCJDE",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "shoulderLeft",
                    "shoulderRight"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area4": [
                    "front.sideInsert"
                ],
                "area5": [
                    "collar"
                ],
                "area6": [
                    "sleeveLowerRight",
                    "sleeveLowerLeft"
                ],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "insert",
                "area2": "solidColors",
                "area3": "insert",
                "area4": "insert",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Cowl (Sublimated Design)",
                "area2": "Body",
                "area3": "Sleeves (Sublimated Design)",
                "area4": "Side inserts (Sublimated Design)",
                "area5": "Collar",
                "area6": "Cuff",
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "VFCJDE",
                    "price": "80",
                    "note": null
                },
                "cutType_youth": {
                    "sku": "YVFCJDE",
                    "price": "80",
                    "note": null
                }
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "CJEHS": {
        "icon": "assets/product-icons/CJEHS.jpg?2014-09-30",
        "category": "sublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "CJEHS",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2014",
        "modelId": "CJEHS",
        "textureId": "CJEHS",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area 2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.stitching"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "collar"
                ],
                "area4": [
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area5": [],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "insert",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Stitching",
                "area2": "Body",
                "area3": "Collar",
                "area4": "Sleeve",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": {
                    "sku": "CJEHS",
                    "price": null,
                    "note": "Fill In Through 9/1/20"
                },
                "cutType_adultLineman": {
                    "sku": "CJEHS",
                    "price": null,
                    "note": "Fill In Through 9/1/20"
                },
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "RVFJ4": {
        "icon": "assets/product-icons/RVFJ4.jpg?2014-09-30",
        "category": "sublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "league",
        "code": "RVFJ4",
        "isReversible": true,
        "hasAssets": true,
        "history": "Added 2016",
        "modelId": "RVFJ",
        "textureId": "RVFJ",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area1",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "back",
                    "front",
                    "shoulders",
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area2": [],
                "area3": [
                    "cuffRight",
                    "cuffLeft",
                    "collar"
                ],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Front Design",
                "area2": "Back Design",
                "area3": "Cuff and Collar",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "RVFJ4",
                    "price": "105.56",
                    "note": null
                },
                "cutType_youth": {
                    "sku": "YRVFJ4",
                    "price": "105.56",
                    "note": null
                }
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "RVFJ7": {
        "icon": "assets/product-icons/RVFJ7.jpg?2014-09-30",
        "category": "sublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "league",
        "code": "RVFJ7",
        "isReversible": true,
        "hasAssets": true,
        "history": "Added 2016",
        "modelId": "RVFJ",
        "textureId": "RVFJ",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area1",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "back",
                    "front",
                    "shoulders",
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area2": [],
                "area3": [
                    "cuffRight",
                    "cuffLeft",
                    "collar"
                ],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Front Design",
                "area2": "Back Design",
                "area3": "Cuff and Collar",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "RVFJ7",
                    "price": "105.56",
                    "note": null
                },
                "cutType_youth": {
                    "sku": "YRVFJ7",
                    "price": "105.56",
                    "note": null
                }
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "RVFJ8": {
        "icon": "assets/product-icons/RVFJ8.jpg?2014-09-30",
        "category": "sublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "league",
        "code": "RVFJ8",
        "isReversible": true,
        "hasAssets": true,
        "history": "Added 2016",
        "modelId": "RVFJ",
        "textureId": "RVFJ",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area1",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "back",
                    "front",
                    "shoulders",
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area2": [],
                "area3": [
                    "cuffRight",
                    "cuffLeft",
                    "collar"
                ],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Front Design",
                "area2": "Back Design",
                "area3": "Cuff and Collar",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "RVFJ8",
                    "price": "105.56",
                    "note": null
                },
                "cutType_youth": {
                    "sku": "YRVFJ8",
                    "price": "105.56",
                    "note": null
                }
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "RVFJFG": {
        "icon": "assets/product-icons/RVFJFG.jpg?2014-09-30",
        "category": "sublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "league",
        "code": "RVFJFG",
        "isReversible": true,
        "hasAssets": true,
        "history": "Added 2018",
        "modelId": "RVFJ",
        "textureId": "RVFJ",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area1",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "front",
                    "back",
                    "sleeveRight",
                    "sleeveLeft",
                    "shoulders"
                ],
                "area2": [],
                "area3": [
                    "cuffRight",
                    "cuffLeft",
                    "collar"
                ],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Front Design",
                "area2": "Back Design",
                "area3": "Cuff and Collar",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "RVFJFG",
                    "price": null,
                    "note": null
                },
                "cutType_youth": {
                    "sku": "YRVFJFG",
                    "price": null,
                    "note": null
                }
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "RVFJMZ": {
        "icon": "assets/product-icons/RVFJMZ.jpg?2014-09-30",
        "category": "sublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "league",
        "code": "RVFJMZ",
        "isReversible": true,
        "hasAssets": true,
        "history": "Added 2018",
        "modelId": "RVFJ",
        "textureId": "RVFJ",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area1",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "front",
                    "back",
                    "sleeveRight",
                    "sleeveLeft",
                    "shoulders"
                ],
                "area2": [],
                "area3": [
                    "cuffRight",
                    "cuffLeft",
                    "collar"
                ],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Front Design",
                "area2": "Back Design",
                "area3": "Cuff and Collar",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "RVFJMZ",
                    "price": null,
                    "note": null
                },
                "cutType_youth": {
                    "sku": "YRVFJMZ",
                    "price": null,
                    "note": null
                }
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "RVFJM": {
        "icon": "assets/product-icons/RVFJM.jpg?2014-09-30",
        "category": "sublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "league",
        "code": "RVFJM",
        "isReversible": true,
        "hasAssets": true,
        "history": "Added 2018",
        "modelId": "RVFJ",
        "textureId": "RVFJ",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area1",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "front",
                    "back",
                    "sleeveRight",
                    "sleeveLeft",
                    "shoulders"
                ],
                "area2": [],
                "area3": [
                    "cuffRight",
                    "cuffLeft",
                    "collar"
                ],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Front Design",
                "area2": "Back Design",
                "area3": "Cuff and Collar",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "RVFJM",
                    "price": null,
                    "note": null
                },
                "cutType_youth": {
                    "sku": "YRVFJM",
                    "price": null,
                    "note": null
                }
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "RVFJME": {
        "icon": "assets/product-icons/RVFJME.jpg?2014-09-30",
        "category": "noTuckSublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "league",
        "code": "RVFJME",
        "isReversible": true,
        "hasAssets": true,
        "history": "Added 2018",
        "modelId": "RVFJME",
        "textureId": "RVFJME",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area1",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "front",
                    "back",
                    "sleeveRight",
                    "sleeveLeft",
                    "shoulders"
                ],
                "area2": [],
                "area3": [
                    "cuffRight",
                    "cuffLeft",
                    "collar"
                ],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Front Design",
                "area2": "Back Design",
                "area3": "Cuff and Collar",
                "area4": null,
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "RVFJME",
                    "price": null,
                    "note": null
                },
                "cutType_youth": {
                    "sku": "YRVFJME",
                    "price": null,
                    "note": null
                }
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "PJ47VFD": {
        "icon": "assets/product-icons/PJ47VFD.jpg?2014-09-30",
        "category": "sublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "PJ47VFD",
        "isReversible": false,
        "hasAssets": false,
        "history": "Added 2016",
        "modelId": "PJ47VF",
        "textureId": "PJ47VF",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "shoulders"
                ],
                "area2": [
                    "front",
                    "back",
                    "collarInsert"
                ],
                "area3": [
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area4": [
                    "front.sideInsert"
                ],
                "area5": [
                    "collar"
                ],
                "area6": [
                    "sleeveLowerRight",
                    "sleeveLowerLeft"
                ],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "insert",
                "area2": "solidColors",
                "area3": "insert",
                "area4": "insert",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Cowl (Sublimated Design)",
                "area2": "Body",
                "area3": "Sleeves (Sublimated Design)",
                "area4": "Side inserts (Sublimated Design)",
                "area5": "Collar",
                "area6": "Cuff",
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "PJ47VFD",
                    "price": null,
                    "note": null
                },
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "PJVFD",
                    "price": null,
                    "note": null
                },
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "PJ147": {
        "icon": "assets/product-icons/PJ147.jpg?2014-09-30",
        "category": "customJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "PJ147",
        "isReversible": false,
        "hasAssets": false,
        "history": "Added 2016",
        "modelId": null,
        "textureId": null,
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": null,
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [],
                "area3": [],
                "area4": [],
                "area5": [],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "insert",
                "area4": "insert",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors",
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Cowl",
                "area2": "Body",
                "area3": "Sleeves (Sublimated Design)",
                "area4": "Side inserts (Sublimated Design)",
                "area5": "Lower side insert",
                "area6": "Collar",
                "area7": "Cuff",
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "customCompression"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "embroidery",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "vt",
                "rvt"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "PJ147",
                    "price": null,
                    "note": null
                },
                "cutType_youth": {
                    "sku": "YPJ147",
                    "price": null,
                    "note": null
                }
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "VFCMJA": {
        "icon": "assets/product-icons/VFCMJA.jpg?2014-09-30",
        "category": "sublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "VFCMJA",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2017",
        "modelId": "VFCJ",
        "textureId": "VFCJ",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "shoulderLeft",
                    "shoulderRight"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area4": [
                    "*.vfcmja.color4"
                ],
                "area5": [
                    "collar"
                ],
                "area6": [
                    "sleeveLowerLeft",
                    "sleeveLowerRight"
                ],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Cowl",
                "area2": "Body",
                "area3": "Sleeve",
                "area4": "Insert",
                "area5": "Collar",
                "area6": "Cuff",
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "VFCMJA",
                    "price": null,
                    "note": null
                },
                "cutType_youth": {
                    "sku": "YVFCMJA",
                    "price": null,
                    "note": null
                }
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "VFMJA",
                    "price": null,
                    "note": null
                },
                "cutType_youth": {
                    "sku": "YVFMJA",
                    "price": null,
                    "note": null
                }
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "VFCMJB": {
        "icon": "assets/product-icons/VFCMJB.jpg?2014-09-30",
        "category": "sublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "VFCMJB",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2017",
        "modelId": "VFCJ",
        "textureId": "VFCJ",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "shoulderLeft",
                    "shoulderRight"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area4": [
                    "*.vfcmjb.color4"
                ],
                "area5": [
                    "*.vfcmjb.color5"
                ],
                "area6": [
                    "*.vfcmjb.color6"
                ],
                "area7": [
                    "collar"
                ],
                "area8": [
                    "sleeveLowerLeft",
                    "sleeveLowerRight"
                ],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors",
                "area8": "solidColors",
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Cowl",
                "area2": "Body",
                "area3": "Sleeve",
                "area4": "Side insert",
                "area5": "Piping on side insert",
                "area6": "Shoulder insert",
                "area7": "Collar",
                "area8": "Cuff",
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "VFCMJB",
                    "price": null,
                    "note": null
                },
                "cutType_youth": {
                    "sku": "YVFCMJB",
                    "price": null,
                    "note": null
                }
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "VFCMJC": {
        "icon": "assets/product-icons/VFCMJC.jpg?2014-09-30",
        "category": "sublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "VFCMJC",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2017",
        "modelId": "VFCJ",
        "textureId": "VFCJ",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "shoulderLeft",
                    "shoulderRight"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area4": [
                    "*.vfcmjc.color4"
                ],
                "area5": [
                    "*.vfcmjc.color5"
                ],
                "area6": [
                    "collar"
                ],
                "area7": [
                    "sleeveLowerLeft",
                    "sleeveLowerRight"
                ],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors",
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Cowl",
                "area2": "Body",
                "area3": "Sleeve",
                "area4": "Front panel insert",
                "area5": "Piping on front panel insert",
                "area6": "Collar",
                "area7": "Cuff",
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "VFCMJC",
                    "price": null,
                    "note": null
                },
                "cutType_youth": {
                    "sku": "YVFCMJC",
                    "price": null,
                    "note": null
                }
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "VFCMJD": {
        "icon": "assets/product-icons/VFCMJD.jpg?2014-09-30",
        "category": "sublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "VFCMJD",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2017",
        "modelId": "VFCJ",
        "textureId": "VFCJ",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "shoulderLeft",
                    "shoulderRight"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area4": [
                    "*.vfcmjd.color4"
                ],
                "area5": [
                    "*.vfcmjd.color5"
                ],
                "area6": [
                    "collar"
                ],
                "area7": [
                    "sleeveLowerLeft",
                    "sleeveLowerRight"
                ],
                "area8": [
                    "*.vfcmjd.color8"
                ],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors",
                "area8": "solidColors",
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Cowl",
                "area2": "Body",
                "area3": "Sleeve",
                "area4": "Side insert top and bottom",
                "area5": "Side insert middle",
                "area6": "Collar",
                "area7": "Cuff top",
                "area8": "Cuff bottom",
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "VFCMJD",
                    "price": null,
                    "note": null
                },
                "cutType_youth": {
                    "sku": "YVFCMJD",
                    "price": null,
                    "note": null
                }
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "VFCMJE": {
        "icon": "assets/product-icons/VFCMJE.jpg?2014-09-30",
        "category": "sublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "VFCMJE",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2017",
        "modelId": "VFCJ",
        "textureId": "VFCJ",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "shoulderLeft",
                    "shoulderRight"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area4": [
                    "*.vfcmje.color4"
                ],
                "area5": [
                    "*.vfcmje.color5"
                ],
                "area6": [
                    "*.vfcmje.color6"
                ],
                "area7": [
                    "sleeveLowerLeft",
                    "sleeveLowerRight"
                ],
                "area8": [
                    "*.vfcmje.color8"
                ],
                "area9": [
                    "collar"
                ],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors",
                "area8": "solidColors",
                "area9": "solidColors",
                "area10": null
            },
            "titles": {
                "area1": "Cowl",
                "area2": "Body and lower side insert",
                "area3": "Sleeve",
                "area4": "Cowl insert closest to collar",
                "area5": "Cowl insert closest to sleeve",
                "area6": "Upper side insert",
                "area7": "Cuff",
                "area8": "Cuff accent",
                "area9": "Collar",
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "VFCMJE",
                    "price": null,
                    "note": null
                },
                "cutType_youth": {
                    "sku": "YVFCMJE",
                    "price": null,
                    "note": null
                }
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "VFCJMZ": {
        "icon": "assets/product-icons/VFCJMZ.jpg?2014-09-30",
        "category": "sublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "VFCJMZ",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2017",
        "modelId": "VFCJ",
        "textureId": "VFCJ",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.vfcjmz.color1",
                    "sleeveLowerLeft",
                    "sleeveLowerRight",
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area2": [
                    "front",
                    "back",
                    "shoulderLeft",
                    "shoulderRight",
                    "collar"
                ],
                "area3": [
                    "*.vfcjmz.color3"
                ],
                "area4": [
                    "*.vfcjmz.color4"
                ],
                "area5": [
                    "*.vfcjmz.color5"
                ],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Sleeve, Collar, Cuff",
                "area2": "Body, Cowl",
                "area3": "Scratch on Sleeve",
                "area4": "Scratch on cowl",
                "area5": "Insert around collar",
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "VFCJMZ",
                    "price": null,
                    "note": null
                },
                "cutType_youth": {
                    "sku": "YVFCJMZ",
                    "price": null,
                    "note": null
                }
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "PJ47VF10E": {
        "icon": "assets/product-icons/PJ47VF10E.jpg?2014-09-30",
        "category": "noTuckSublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": true
        },
        "quality": "varsity",
        "code": "PJ47VF10E",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2017",
        "modelId": "PJ47VF10E",
        "textureId": "PJ47VF10E",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "shoulders"
                ],
                "area4": [
                    "*.pj47vf10e.color4"
                ],
                "area5": [
                    "*.pj47vf10e.color5"
                ],
                "area6": [
                    "*.pj47vf10e.color6"
                ],
                "area7": [
                    "*.pj47vf10e.color7"
                ],
                "area8": [
                    "collar"
                ],
                "area9": [
                    "sleeveLowerLeft",
                    "sleeveLowerRight"
                ],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors",
                "area8": "solidColors",
                "area9": "solidColors",
                "area10": null
            },
            "titles": {
                "area1": "Sleeve",
                "area2": "Body",
                "area3": "Cowl",
                "area4": "Sleeve Design",
                "area5": "Body Design",
                "area6": "Cowl Design",
                "area7": "Insert Design",
                "area8": "Collar",
                "area9": "Cuff",
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "PJ47VF10E",
                    "price": null,
                    "note": null
                },
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": "When building a white body jersey, colors #2, 3, 5, and 6 must be white to be NFHS legal."
    },
    "VFCJ10E": {
        "icon": "assets/product-icons/VFCJ10E.jpg?2014-09-30",
        "category": "noTuckSublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "VFCJ10E",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2018",
        "modelId": "VFCJ10E",
        "textureId": "VFCJ10E",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "shoulderLeft",
                    "shoulderRight"
                ],
                "area4": [
                    "*.pj47vf10e.color4"
                ],
                "area5": [
                    "*.pj47vf10e.color5"
                ],
                "area6": [
                    "*.pj47vf10e.color6"
                ],
                "area7": [
                    "*.pj47vf10e.color7"
                ],
                "area8": [
                    "collar"
                ],
                "area9": [
                    "sleeveLowerLeft",
                    "sleeveLowerRight"
                ],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors",
                "area8": "solidColors",
                "area9": "solidColors",
                "area10": null
            },
            "titles": {
                "area1": "Sleeve",
                "area2": "Body",
                "area3": "Cowl",
                "area4": "Sleeve Design",
                "area5": "Body Design",
                "area6": "Cowl Design",
                "area7": "Insert Design",
                "area8": "Collar",
                "area9": "Cuff",
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "VFCJ10E",
                    "price": null,
                    "note": null
                },
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": "When building a white body jersey, colors #2, 3, 5, and 6 must be white to be NFHS legal."
    },
    "PJ47VF10": {
        "icon": "assets/product-icons/PJ47VF10.jpg?2014-09-30",
        "category": "sublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": true
        },
        "quality": "varsity",
        "code": "PJ47VF10",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2017",
        "modelId": "PJ47VF",
        "textureId": "PJ47VF",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "sleeveLeft",
                    "sleeveRight"
                ],
                "area2": [
                    "front",
                    "back",
                    "collarInsert"
                ],
                "area3": [
                    "shoulders"
                ],
                "area4": [
                    "*.pj47vf10.color4"
                ],
                "area5": [
                    "*.pj47vf10.color5"
                ],
                "area6": [
                    "*.pj47vf10.color6"
                ],
                "area7": [
                    "*.pj47vf10.color7"
                ],
                "area8": [
                    "collar"
                ],
                "area9": [
                    "sleeveLowerLeft",
                    "sleeveLowerRight"
                ],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors",
                "area8": "solidColors",
                "area9": "solidColors",
                "area10": null
            },
            "titles": {
                "area1": "Sleeve",
                "area2": "Body",
                "area3": "Cowl",
                "area4": "Sleeve Design",
                "area5": "Body Design",
                "area6": "Cowl Design",
                "area7": "Insert Design",
                "area8": "Collar",
                "area9": "Cuff",
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": {
                    "sku": "PJ47VF10",
                    "price": null,
                    "note": null
                },
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": "When building a white body jersey, colors #2, 3, 5, and 6 must be white to be NFHS legal."
    },
    "GLJ905547VF": {
        "icon": "assets/product-icons/GLJ905547VF.jpg?2014-09-30",
        "category": "sublimatedJerseys",
        "optionList": [
            "logoColor",
            "CLSU",
            "sleeveStripes"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "GLJ905547VF",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2018",
        "modelId": "GLJ905547",
        "textureId": "GLJ905547",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area1",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "shoulders"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area4": [
                    "insetRight",
                    "insetLeft"
                ],
                "area5": [
                    "sleeveLowerRight",
                    "sleeveLowerLeft"
                ],
                "area6": [
                    "collar"
                ],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "pattern",
                "area6": "pattern",
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Cowl",
                "area2": "Body",
                "area3": "Sleeves",
                "area4": "Inserts",
                "area5": "Cuff",
                "area6": "Collar",
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": true,
            "sleeveStripes": true
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": {
                    "sku": "GLJ905547VF",
                    "price": null,
                    "note": null
                },
                "cutType_adultLineman": {
                    "sku": "LJ905547VF",
                    "price": null,
                    "note": null
                },
                "cutType_youth": {
                    "sku": "YGLJ905547VF",
                    "price": null,
                    "note": null
                }
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": {
                    "sku": "GLJ9055VF",
                    "price": null,
                    "note": "Fill In Through 9/1/20"
                },
                "cutType_adultLineman": {
                    "sku": "LJ9055VF",
                    "price": null,
                    "note": "Fill In Through 9/1/20"
                },
                "cutType_youth": {
                    "sku": "YGLJ9055VF",
                    "price": null,
                    "note": "Fill In Through 9/1/20"
                }
            }
        },
        "skuNote": null
    },
    "GLJ905547VFE": {
        "icon": "assets/product-icons/GLJ905547VFE.jpg?2014-09-30",
        "category": "noTuckSublimatedJerseys",
        "optionList": [
            "logoColor",
            "CLSU",
            "sleeveStripes"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "quality": "varsity",
        "code": "GLJ905547VFE",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2018",
        "modelId": "GLJ905547VFE",
        "textureId": "GLJ905547VFE",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area1",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "shoulders"
                ],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area4": [
                    "insertRight",
                    "insertLeft"
                ],
                "area5": [
                    "sleeveLowerRight",
                    "sleeveLowerLeft"
                ],
                "area6": [
                    "collar"
                ],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "pattern",
                "area6": "pattern",
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Cowl",
                "area2": "Body",
                "area3": "Sleeves",
                "area4": "Inserts",
                "area5": "Cuff",
                "area6": "Collar",
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": true,
            "sleeveStripes": true
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": {
                    "sku": "GLJ905547VFE",
                    "price": null,
                    "note": null
                },
                "cutType_adultLineman": {
                    "sku": "LJ905547VFE",
                    "price": null,
                    "note": null
                },
                "cutType_youth": {
                    "sku": "YGLJ905547VFE",
                    "price": null,
                    "note": null
                }
            },
            "fitType_lean": {
                "cutType_adult": {
                    "sku": "PJ9055VFE",
                    "price": null,
                    "note": null
                },
                "cutType_adultLineman": {
                    "sku": "LPJ9055VFE",
                    "price": null,
                    "note": null
                },
                "cutType_youth": {
                    "sku": "YPJ9055VFE",
                    "price": null,
                    "note": null
                }
            },
            "fitType_regular": {
                "cutType_adult": {
                    "sku": "GLJ9055VFE",
                    "price": null,
                    "note": null
                },
                "cutType_adultLineman": {
                    "sku": "LJ9055VFE",
                    "price": null,
                    "note": null
                },
                "cutType_youth": {
                    "sku": "YGLJ9055VFE",
                    "price": null,
                    "note": null
                }
            }
        },
        "skuNote": null
    },
    "PJ47VFMJC": {
        "icon": "assets/product-icons/PJ47VFMJC.jpg?2014-09-30",
        "category": "sublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "PJ47VFMJC",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2018",
        "modelId": "PJ47VF",
        "textureId": "PJ47VF",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.pj47vfmjc.color1"
                ],
                "area2": [
                    "front",
                    "back",
                    "shoulders",
                    "collarInsert"
                ],
                "area3": [
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area4": [
                    "*.pj47vfmjc.color4"
                ],
                "area5": [
                    "*.pj47vfmjc.color5"
                ],
                "area6": [
                    "collar"
                ],
                "area7": [
                    "sleeveLowerRight",
                    "sleeveLowerLeft"
                ],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors",
                "area8": null,
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Cowl",
                "area2": "Body",
                "area3": "Sleeves",
                "area4": "Side Inserts",
                "area5": "Piping",
                "area6": "Collar",
                "area7": "Cuff",
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": {
                    "sku": "PJ47VFMJC",
                    "price": null,
                    "note": null
                },
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "PJ47VFMJD": {
        "icon": "assets/product-icons/PJ47VFMJD.jpg?2014-09-30",
        "category": "sublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "PJ47VFMJD",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2018",
        "modelId": "PJ47VF",
        "textureId": "PJ47VF",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.pj47vfmjd.color1"
                ],
                "area2": [
                    "front",
                    "back",
                    "shoulders",
                    "collarInsert"
                ],
                "area3": [
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area4": [
                    "*.pj47vfmjd.color4"
                ],
                "area5": [
                    "*.pj47vfmjd.color5"
                ],
                "area6": [
                    "collar"
                ],
                "area7": [
                    "*.pj47vfmjd.color7"
                ],
                "area8": [
                    "*.pj47vfmjd.color8"
                ],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors",
                "area8": "solidColors",
                "area9": null,
                "area10": null
            },
            "titles": {
                "area1": "Cowl",
                "area2": "Body",
                "area3": "Sleeves",
                "area4": "Insert Top/Bottom",
                "area5": "Insert Middle",
                "area6": "Collar",
                "area7": "Cuff Top",
                "area8": "Cuff Bottom",
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": {
                    "sku": "PJ47VFMJD",
                    "price": null,
                    "note": null
                },
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "PJ47VFMJE": {
        "icon": "assets/product-icons/PJ47VFMJE.jpg?2014-09-30",
        "category": "sublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "PJ47VFMJE",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2018",
        "modelId": "PJ47VF",
        "textureId": "PJ47VF",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [
                    "*.pj47vfmje.color1"
                ],
                "area2": [
                    "front",
                    "back",
                    "shoulders",
                    "collarInsert"
                ],
                "area3": [
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area4": [
                    "*.pj47vfmje.color4"
                ],
                "area5": [
                    "*.pj47vfmje.color5"
                ],
                "area6": [
                    "*.pj47vfmje.color6"
                ],
                "area7": [
                    "*.pj47vfmje.color7"
                ],
                "area8": [
                    "*.pj47vfmje.color8"
                ],
                "area9": [
                    "collar"
                ],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors",
                "area8": "solidColors",
                "area9": "solidColors",
                "area10": null
            },
            "titles": {
                "area1": "Cowl",
                "area2": "Body",
                "area3": "Sleeves",
                "area4": "Inner Cowl Insert",
                "area5": "Outer Cowl Insert",
                "area6": "Side Insert",
                "area7": "Cuff",
                "area8": "Cuff Accent",
                "area9": "Collar",
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fullSublimation"
            ],
            "teamnameAlign": null
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": {
                    "sku": "PJ47VFMJE",
                    "price": null,
                    "note": null
                },
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            }
        },
        "skuNote": null
    },
    "FFVN1": {
        "icon": "assets/product-icons/FFVN1.jpg?2014-09-30",
        "category": "flagFootball7V7SublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "FFVN1",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2019",
        "modelId": "FFVN1",
        "textureId": "FFVN1",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [
            "0"
        ],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area3",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area3": [
                    "*.ffvn1.color3"
                ],
                "area4": [
                    "*.ffvn1.color4",
                    "collar"
                ],
                "area5": [],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors",
                "area8": "solidColors",
                "area9": "solidColors",
                "area10": null
            },
            "titles": {
                "area1": null,
                "area2": "Body / Sleeves",
                "area3": "Cowl / Side Insert",
                "area4": "Cowl and Insert Stripes",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fasttrack"
            ],
            "teamnameAlign": "m"
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": {
                    "sku": "FFVN1",
                    "price": null,
                    "note": null
                },
                "cutType_adultLineman": null,
                "cutType_youth": {
                    "sku": "YFFVN1",
                    "price": null,
                    "note": null
                }
            }
        },
        "skuNote": null
    },
    "FFVN2": {
        "icon": "assets/product-icons/FFVN2.jpg?2014-09-30",
        "category": "flagFootball7V7SublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "FFVN2",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2019",
        "modelId": "FFVN1",
        "textureId": "FFVN2",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area3": [
                    "*.ffvn2.color3"
                ],
                "area4": [
                    "*.ffvn2.color4"
                ],
                "area5": [
                    "collar"
                ],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors",
                "area8": "solidColors",
                "area9": "solidColors",
                "area10": null
            },
            "titles": {
                "area1": null,
                "area2": "Body / Top Sleeves",
                "area3": "Lower Sleeve / Side Insert",
                "area4": "Middle Insert",
                "area5": "Collar",
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fasttrack"
            ],
            "teamnameAlign": "m"
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": {
                    "sku": "FFVN2",
                    "price": null,
                    "note": null
                },
                "cutType_adultLineman": null,
                "cutType_youth": {
                    "sku": "YFFVN2",
                    "price": null,
                    "note": null
                }
            }
        },
        "skuNote": null
    },
    "FFVN3": {
        "icon": "assets/product-icons/FFVN3.jpg?2014-09-30",
        "category": "flagFootball7V7SublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "FFVN3",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2019",
        "modelId": "FFVN1",
        "textureId": "FFVN2",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "*.ffvn3.color2"
                ],
                "area3": [
                    "front",
                    "back",
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area4": [
                    "*.ffvn3.color4",
                    "collar"
                ],
                "area5": [],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors",
                "area8": "solidColors",
                "area9": "solidColors",
                "area10": null
            },
            "titles": {
                "area1": null,
                "area2": "Upper Body",
                "area3": "Lower Body",
                "area4": "Collar and Cuff",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fasttrack"
            ],
            "teamnameAlign": "m"
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": {
                    "sku": "FFVN3",
                    "price": null,
                    "note": null
                },
                "cutType_adultLineman": null,
                "cutType_youth": {
                    "sku": "YFFVN3",
                    "price": null,
                    "note": null
                }
            }
        },
        "skuNote": null
    },
    "FFVN4": {
        "icon": "assets/product-icons/FFVN4.jpg?2014-09-30",
        "category": "flagFootball7V7SublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "FFVN4",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2019",
        "modelId": "FFVN1",
        "textureId": "FFVN2",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area3": [
                    "*.ffvn4.color3"
                ],
                "area4": [
                    "*.ffvn4.color4",
                    "collar"
                ],
                "area5": [],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors",
                "area8": "solidColors",
                "area9": "solidColors",
                "area10": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Body Design",
                "area4": "Collar and Cuff",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fasttrack"
            ],
            "teamnameAlign": "m"
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": {
                    "sku": "FFVN4",
                    "price": null,
                    "note": null
                },
                "cutType_adultLineman": null,
                "cutType_youth": {
                    "sku": "YFFVN4",
                    "price": null,
                    "note": null
                }
            }
        },
        "skuNote": null
    },
    "FFVN5": {
        "icon": "assets/product-icons/FFVN5.jpg?2014-09-30",
        "category": "flagFootball7V7SublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "FFVN5",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2019",
        "modelId": "FFVN1",
        "textureId": "FFVN5",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area3": [
                    "*.ffvn5.color3"
                ],
                "area4": [
                    "*.ffvn5.color4",
                    "collar"
                ],
                "area5": [],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors",
                "area8": "solidColors",
                "area9": "solidColors",
                "area10": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Shoulder / Side Insert",
                "area4": "Collar and Cuff",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fasttrack"
            ],
            "teamnameAlign": "m"
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": {
                    "sku": "FFVN5",
                    "price": null,
                    "note": null
                },
                "cutType_adultLineman": null,
                "cutType_youth": {
                    "sku": "YFFVN5",
                    "price": null,
                    "note": null
                }
            }
        },
        "skuNote": null
    },
    "FFVN6": {
        "icon": "assets/product-icons/FFVN6.jpg?2014-09-30",
        "category": "flagFootball7V7SublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "FFVN6",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2019",
        "modelId": "FFVN1",
        "textureId": "FFVN6",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area3",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area3": [
                    "*.ffvn6.color3"
                ],
                "area4": [
                    "*.ffvn6.color4"
                ],
                "area5": [
                    "collar"
                ],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors",
                "area8": "solidColors",
                "area9": "solidColors",
                "area10": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Cowl / Sleeve Cuff / Insert stripe",
                "area4": "Side Insert and Sleeve Accents",
                "area5": "Collar",
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fasttrack"
            ],
            "teamnameAlign": "m"
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": {
                    "sku": "FFVN6",
                    "price": null,
                    "note": null
                },
                "cutType_adultLineman": null,
                "cutType_youth": {
                    "sku": "YFFVN6",
                    "price": null,
                    "note": null
                }
            }
        },
        "skuNote": null
    },
    "FFVN7": {
        "icon": "assets/product-icons/FFVN7.jpg?2014-09-30",
        "category": "flagFootball7V7SublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "FFVN7",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2019",
        "modelId": "FFVN1",
        "textureId": "FFVN2",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "isSleeveless": false,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back",
                    "sleeveRight",
                    "sleeveLeft"
                ],
                "area3": [
                    "*.ffvn7.color3"
                ],
                "area4": [
                    "*.ffvn7.color4"
                ],
                "area5": [
                    "collar",
                    "*.ffvn7.color5"
                ],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors",
                "area8": "solidColors",
                "area9": "solidColors",
                "area10": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Cowl / Sleeve",
                "area4": "Side Insert and Bottom Sleeve",
                "area5": "Collar and Insert stripe",
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fasttrack"
            ],
            "teamnameAlign": "m"
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": {
                    "sku": "FFVN7",
                    "price": null,
                    "note": null
                },
                "cutType_adultLineman": null,
                "cutType_youth": {
                    "sku": "YFFVN7",
                    "price": null,
                    "note": null
                }
            }
        },
        "skuNote": null
    },
    "FFSL1": {
        "icon": "assets/product-icons/FFSL1.jpg?2014-09-30",
        "category": "flagFootball7V7SublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "FFSL1",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2019",
        "modelId": "FFSL1",
        "textureId": "FFSL1",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [
            "0",
            "17LR",
            "18LR"
        ],
        "asset": {
            "isSleeveless": true,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area3",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "*.ffsl1.color3"
                ],
                "area4": [
                    "*.ffsl1.color4",
                    "collar"
                ],
                "area5": [],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors",
                "area8": "solidColors",
                "area9": "solidColors",
                "area10": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Cowl / Side Insert",
                "area4": "Cowl and Insert Stripes",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fasttrack"
            ],
            "teamnameAlign": "m"
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": {
                    "sku": "FFSL1",
                    "price": null,
                    "note": null
                },
                "cutType_adultLineman": null,
                "cutType_youth": {
                    "sku": "YFFSL1",
                    "price": null,
                    "note": null
                }
            }
        },
        "skuNote": null
    },
    "FFSL2": {
        "icon": "assets/product-icons/FFSL2.jpg?2014-09-30",
        "category": "flagFootball7V7SublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "FFSL2",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2019",
        "modelId": "FFSL1",
        "textureId": "FFSL2",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [
            "17LR",
            "18LR"
        ],
        "asset": {
            "isSleeveless": true,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "*.ffsl2.color3"
                ],
                "area4": [
                    "*.ffsl2.color4"
                ],
                "area5": [
                    "collar"
                ],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors",
                "area8": "solidColors",
                "area9": "solidColors",
                "area10": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Lower / Side Insert",
                "area4": "Middle Insert",
                "area5": "Collar",
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fasttrack"
            ],
            "teamnameAlign": "m"
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": {
                    "sku": "FFSL2",
                    "price": null,
                    "note": null
                },
                "cutType_adultLineman": null,
                "cutType_youth": {
                    "sku": "YFFSL2",
                    "price": null,
                    "note": null
                }
            }
        },
        "skuNote": null
    },
    "FFSL3": {
        "icon": "assets/product-icons/FFSL3.jpg?2014-09-30",
        "category": "flagFootball7V7SublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "FFSL3",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2019",
        "modelId": "FFSL1",
        "textureId": "FFSL2",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [
            "17LR",
            "18LR"
        ],
        "asset": {
            "isSleeveless": true,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "*.ffsl3.color2"
                ],
                "area3": [
                    "front",
                    "back"
                ],
                "area4": [
                    "collar"
                ],
                "area5": [],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors",
                "area8": "solidColors",
                "area9": "solidColors",
                "area10": null
            },
            "titles": {
                "area1": null,
                "area2": "Upper Body",
                "area3": "Lower Body",
                "area4": "Collar",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fasttrack"
            ],
            "teamnameAlign": "m"
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": {
                    "sku": "FFSL3",
                    "price": null,
                    "note": null
                },
                "cutType_adultLineman": null,
                "cutType_youth": {
                    "sku": "YFFSL3",
                    "price": null,
                    "note": null
                }
            }
        },
        "skuNote": null
    },
    "FFSL4": {
        "icon": "assets/product-icons/FFSL4.jpg?2014-09-30",
        "category": "flagFootball7V7SublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "FFSL4",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2019",
        "modelId": "FFSL1",
        "textureId": "FFSL2",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [
            "17LR",
            "18LR"
        ],
        "asset": {
            "isSleeveless": true,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "*.ffsl4.color3"
                ],
                "area4": [
                    "collar"
                ],
                "area5": [],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors",
                "area8": "solidColors",
                "area9": "solidColors",
                "area10": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Body Design",
                "area4": "Collar",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fasttrack"
            ],
            "teamnameAlign": "m"
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": {
                    "sku": "FFSL4",
                    "price": null,
                    "note": null
                },
                "cutType_adultLineman": null,
                "cutType_youth": {
                    "sku": "YFFSL4",
                    "price": null,
                    "note": null
                }
            }
        },
        "skuNote": null
    },
    "FFSL5": {
        "icon": "assets/product-icons/FFSL5.jpg?2014-09-30",
        "category": "flagFootball7V7SublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "FFSL5",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2019",
        "modelId": "FFSL1",
        "textureId": "FFSL5",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [
            "17LR",
            "18LR"
        ],
        "asset": {
            "isSleeveless": true,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "*.ffsl5.color3"
                ],
                "area4": [
                    "collar"
                ],
                "area5": [],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors",
                "area8": "solidColors",
                "area9": "solidColors",
                "area10": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Shoulder / Side Insert",
                "area4": "Collar",
                "area5": null,
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fasttrack"
            ],
            "teamnameAlign": "m"
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": {
                    "sku": "FFSL5",
                    "price": null,
                    "note": null
                },
                "cutType_adultLineman": null,
                "cutType_youth": {
                    "sku": "YFFSL5",
                    "price": null,
                    "note": null
                }
            }
        },
        "skuNote": null
    },
    "FFSL6": {
        "icon": "assets/product-icons/FFSL6.jpg?2014-09-30",
        "category": "flagFootball7V7SublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "FFSL6",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2019",
        "modelId": "FFSL1",
        "textureId": "FFSL6",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [
            "17LR",
            "18LR"
        ],
        "asset": {
            "isSleeveless": true,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area3",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "*.ffsl6.color3"
                ],
                "area4": [
                    "*.ffsl6.color4"
                ],
                "area5": [
                    "collar"
                ],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors",
                "area8": "solidColors",
                "area9": "solidColors",
                "area10": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Cowl / Insert stripe",
                "area4": "Side Insert and Cowl Accents",
                "area5": "Collar",
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fasttrack"
            ],
            "teamnameAlign": "m"
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": {
                    "sku": "FFSL6",
                    "price": null,
                    "note": null
                },
                "cutType_adultLineman": null,
                "cutType_youth": {
                    "sku": "YFFSL6",
                    "price": null,
                    "note": null
                }
            }
        },
        "skuNote": null
    },
    "FFSL7": {
        "icon": "assets/product-icons/FFSL7.jpg?2014-09-30",
        "category": "flagFootball7V7SublimatedJerseys",
        "optionList": [
            "logoColor"
        ],
        "clothingType": "jersey",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "FFSL7",
        "isReversible": false,
        "hasAssets": true,
        "history": "Added 2019",
        "modelId": "FFSL1",
        "textureId": "FFSL2",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [
            "17LR",
            "18LR"
        ],
        "asset": {
            "isSleeveless": true,
            "placeholder": null
        },
        "colorAreas": {
            "logoBgAreaId": "area2",
            "layers": {
                "area2-0": [],
                "area2-1": [],
                "area2-2": [],
                "area1": [],
                "area2": [
                    "front",
                    "back"
                ],
                "area3": [
                    "*.ffsl7.color3"
                ],
                "area4": [
                    "*.ffsl7.color4"
                ],
                "area5": [
                    "collar",
                    "*.ffsl7.color5"
                ],
                "area6": [],
                "area7": [],
                "area8": [],
                "area9": [],
                "area10": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": "solidColors",
                "area6": "solidColors",
                "area7": "solidColors",
                "area8": "solidColors",
                "area9": "solidColors",
                "area10": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Cowl",
                "area4": "Side Insert",
                "area5": "Collar and Insert stripe",
                "area6": null,
                "area7": null,
                "area8": null,
                "area9": null,
                "area10": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "logoColor": "sublimated",
            "CLSU": false,
            "sleeveStripes": false
        },
        "decoration": {
            "decorationPackages": [
                "fasttrack"
            ],
            "teamnameAlign": "m"
        },
        "skuMap": {
            "fitType_compression": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_lean": {
                "cutType_adult": null,
                "cutType_adultLineman": null,
                "cutType_youth": null
            },
            "fitType_regular": {
                "cutType_adult": {
                    "sku": "FFSL7",
                    "price": null,
                    "note": null
                },
                "cutType_adultLineman": null,
                "cutType_youth": {
                    "sku": "YFFSL7",
                    "price": null,
                    "note": null
                }
            }
        },
        "skuNote": null
    },
    "PPDIV": {
        "icon": "assets/product-icons/PPDIV.jpg?2014-09-30",
        "category": "customPants",
        "optionList": [
            "hasPlacement27",
            "beltColor",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "PPDIV",
        "hasAssets": true,
        "history": "Added 2014",
        "modelId": "PPDIV",
        "textureId": "PPDIV",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "placeholder": null
        },
        "colorAreas": {
            "layers": {
                "area1": [
                    "*.piping"
                ],
                "area2": [
                    "frontRight",
                    "frontLeft",
                    "backRight",
                    "backLeft",
                    "middle",
                    "belt.loop"
                ],
                "area3": [
                    "insetUpperLeft",
                    "insetUpperRight"
                ],
                "area4": [
                    "insetLowerLeft",
                    "insetLowerRight"
                ],
                "area5": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "insert",
                "area5": null
            },
            "titles": {
                "area1": "Piping",
                "area2": "Body",
                "area3": "Top of Inserts",
                "area4": "Side Inserts (Sublimated Design)",
                "area5": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "hasPlacement27": true,
            "beltColor": "embroidery",
            "logoColor": "embroidery"
        },
        "decoration": {
            "decorationPackages": [
                "pantDeco"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "PPDIV",
                "price": null,
                "note": "Fill In Through 9/1/20"
            },
            "cutType_youth": {
                "sku": "YPPDIV",
                "price": null,
                "note": "Fill In Through 9/1/20"
            },
            "cutType_adult7pad": null,
            "cutType_youth7pad": null
        }
    },
    "PP45X": {
        "icon": "assets/product-icons/PP45X.jpg?2014-09-30",
        "category": "customPants",
        "optionList": [
            "hasPlacement27",
            "beltColor",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "quality": "varsity",
        "code": "PP45X",
        "hasAssets": true,
        "history": "Added 2014, Removed 2018",
        "modelId": "PP45X",
        "textureId": "PP45X",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "placeholder": null
        },
        "colorAreas": {
            "layers": {
                "area1": [
                    "*.piping"
                ],
                "area2": [
                    "frontRight",
                    "frontLeft",
                    "backRight",
                    "backLeft",
                    "insetUpperRight",
                    "insetUpperLeft",
                    "middle",
                    "belt.loop"
                ],
                "area3": [
                    "insetWedgeRight",
                    "insetWedgeLeft"
                ],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": "Piping",
                "area2": "Body",
                "area3": "Wedge Insert",
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "customCompression"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "hasPlacement27": true,
            "beltColor": "embroidery",
            "logoColor": "embroidery"
        },
        "decoration": {
            "decorationPackages": [
                "none"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "PP45X",
                "price": null,
                "note": null
            },
            "cutType_youth": null,
            "cutType_adult7pad": null,
            "cutType_youth7pad": null
        }
    },
    "PPTEC": {
        "icon": "assets/product-icons/PPTEC.jpg?2014-09-30",
        "category": "customPants",
        "optionList": [
            "beltColor",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "quality": "varsity",
        "code": "PPTEC",
        "hasAssets": true,
        "history": "Added 2014, Removed ????",
        "modelId": "PPTEC",
        "textureId": "PPTEC",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "placeholder": null
        },
        "colorAreas": {
            "layers": {
                "area1": [
                    "*.piping"
                ],
                "area2": [
                    "backLeft",
                    "backRight",
                    "sideInsetLowerLeft",
                    "sideInsetLowerRight",
                    "sideInsetUpperLeft",
                    "sideInsetUpperRight",
                    "frontLeft",
                    "frontRight",
                    "middle",
                    "belt.loop",
                    "rope"
                ],
                "area3": [
                    "sideInsetMiddleLeft",
                    "sideInsetMiddleRight"
                ],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": "Piping",
                "area2": "Body",
                "area3": "Side Insert",
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "customCompression"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "hasPlacement27": false,
            "beltColor": "embroidery",
            "logoColor": "embroidery"
        },
        "decoration": {
            "decorationPackages": [
                "none"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "PPTEC",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YPPTEC",
                "price": null,
                "note": null
            },
            "cutType_adult7pad": null,
            "cutType_youth7pad": null
        }
    },
    "PPF4": {
        "icon": "assets/product-icons/PPF4.jpg?2014-09-30",
        "category": "customPants",
        "optionList": [
            "hasPlacement27",
            "beltColor",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "quality": "varsity",
        "code": "PPF4",
        "hasAssets": true,
        "history": "Added 2014, Removed 2018",
        "modelId": "PPF4",
        "textureId": "PPF4",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "placeholder": null
        },
        "colorAreas": {
            "layers": {
                "area1": [
                    "*.piping"
                ],
                "area2": [
                    "insetLowerRight",
                    "insetLowerLeft",
                    "frontRight",
                    "frontLeft",
                    "backRight",
                    "backLeft",
                    "middle",
                    "belt.loop"
                ],
                "area3": [
                    "insetUpperRight",
                    "insetUpperLeft"
                ],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": "Piping",
                "area2": "Body",
                "area3": "Insert",
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "customCompression"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "hasPlacement27": true,
            "beltColor": "embroidery",
            "logoColor": "embroidery"
        },
        "decoration": {
            "decorationPackages": [
                "none"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "PPF4",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YPPF4",
                "price": null,
                "note": null
            },
            "cutType_adult7pad": null,
            "cutType_youth7pad": null
        }
    },
    "PPCAL": {
        "icon": "assets/product-icons/PPCAL.jpg?2014-09-30",
        "category": "customPants",
        "optionList": [
            "beltColor",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "quality": "varsity",
        "code": "PPCAL",
        "hasAssets": true,
        "history": "Added 2014, Removed 2018",
        "modelId": "PPCAL",
        "textureId": "PPCAL",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "placeholder": null
        },
        "colorAreas": {
            "layers": {
                "area1": [
                    "insetLowerRight",
                    "insetLowerLeft"
                ],
                "area2": [
                    "backRight",
                    "backLeft",
                    "frontRight",
                    "frontLeft",
                    "insetUpperRight",
                    "insetUpperLeft",
                    "belt.loop",
                    "middle",
                    "rope"
                ],
                "area3": [
                    "insetWedgeRight",
                    "insetWedgeLeft"
                ],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": "Top Scratch",
                "area2": "Body",
                "area3": "Bottom Scratch",
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "customCompression"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "hasPlacement27": false,
            "beltColor": "embroidery",
            "logoColor": "embroidery"
        },
        "decoration": {
            "decorationPackages": [
                "none"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "PPCAL",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YPPCAL",
                "price": null,
                "note": null
            },
            "cutType_adult7pad": null,
            "cutType_youth7pad": null
        }
    },
    "PACPMU": {
        "icon": "assets/product-icons/PACPMU.jpg?2014-09-30",
        "category": "customPants",
        "optionList": [
            "beltColor",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "quality": "varsity",
        "code": "PACPMU",
        "hasAssets": true,
        "history": "Added 2014, Removed ????",
        "modelId": "PACPMU",
        "textureId": "PACPMU",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "placeholder": null
        },
        "colorAreas": {
            "layers": {
                "area1": [
                    "*.piping"
                ],
                "area2": [
                    "backLeft",
                    "backRight",
                    "frontLeft",
                    "frontRight",
                    "middle",
                    "belt.loop",
                    "insetUpperRight",
                    "insetUpperLeft",
                    "rope"
                ],
                "area3": [
                    "insetWedgeRight",
                    "insetWedgeLeft"
                ],
                "area4": [
                    "insetLowerLeft",
                    "insetLowerRight"
                ],
                "area5": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null
            },
            "titles": {
                "area1": "Piping",
                "area2": "Body",
                "area3": "Inset Wedge",
                "area4": "Insert",
                "area5": null
            },
            "colorSets": [
                "customCompression"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "hasPlacement27": false,
            "beltColor": "embroidery",
            "logoColor": "embroidery"
        },
        "decoration": {
            "decorationPackages": [
                "none"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "PACPMU",
                "price": null,
                "note": null
            },
            "cutType_youth": null,
            "cutType_adult7pad": null,
            "cutType_youth7pad": null
        }
    },
    "GLP0909S": {
        "icon": "assets/product-icons/GLP0909S.jpg?2014-09-30",
        "category": "customPants",
        "optionList": [
            "hasPlacement27",
            "beltColor",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "quality": "varsity",
        "code": "GLP0909S",
        "hasAssets": true,
        "history": "Added 2014, Removed ????",
        "modelId": "GLP0909S",
        "textureId": "GLP0909S",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "placeholder": null
        },
        "colorAreas": {
            "layers": {
                "area1": [],
                "area2": [
                    "backLeft",
                    "backRight",
                    "frontLeft",
                    "frontRight",
                    "middle",
                    "belt.loop",
                    "rope"
                ],
                "area3": [],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "customCompression"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "hasPlacement27": true,
            "beltColor": "embroidery",
            "logoColor": "embroidery"
        },
        "decoration": {
            "decorationPackages": [
                "none"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "GLP0909S",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YGLP0909S",
                "price": null,
                "note": null
            },
            "cutType_adult7pad": null,
            "cutType_youth7pad": null
        }
    },
    "GLP0909": {
        "icon": "assets/product-icons/GLP0909.jpg?2014-09-30",
        "category": "customPants",
        "optionList": [
            "hasPlacement27",
            "beltColor",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "GLP0909",
        "hasAssets": true,
        "history": "Added 2014",
        "modelId": "GLP0909",
        "textureId": "GLP0909",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "placeholder": null
        },
        "colorAreas": {
            "layers": {
                "area1": [],
                "area2": [
                    "backLeft",
                    "backRight",
                    "frontLeft",
                    "frontRight",
                    "middle",
                    "belt.loop",
                    "rope"
                ],
                "area3": [
                    "*.sideInsert"
                ],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": "Insert",
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "customCompression"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "hasPlacement27": true,
            "beltColor": "embroidery",
            "logoColor": "embroidery"
        },
        "decoration": {
            "decorationPackages": [
                "none"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "GLP0909",
                "price": null,
                "note": "Fill In Through 9/1/20"
            },
            "cutType_youth": {
                "sku": "YGLP0909",
                "price": null,
                "note": "Fill In Through 9/1/20"
            },
            "cutType_adult7pad": null,
            "cutType_youth7pad": null
        }
    },
    "MPA": {
        "icon": "assets/product-icons/MPA.jpg?2014-09-30",
        "category": "customPants",
        "optionList": [
            "hasPlacement27",
            "beltColor",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "quality": "league",
        "code": "MPA",
        "hasAssets": true,
        "history": "Added 2014, Removed 2018",
        "modelId": "MPA",
        "textureId": "MPA",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "placeholder": null
        },
        "colorAreas": {
            "layers": {
                "area1": [],
                "area2": [
                    "backLeft",
                    "backRight",
                    "frontLeft",
                    "frontRight",
                    "middle",
                    "belt.loop",
                    "rope"
                ],
                "area3": [],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Body",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "customLean"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "hasPlacement27": true,
            "beltColor": "embroidery",
            "logoColor": "embroidery"
        },
        "decoration": {
            "decorationPackages": [
                "none"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "MPA",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YMPA",
                "price": null,
                "note": null
            },
            "cutType_adult7pad": {
                "sku": "MPA-7P",
                "price": null,
                "note": null
            },
            "cutType_youth7pad": {
                "sku": "YMPA-7P",
                "price": null,
                "note": null
            }
        }
    },
    "MPB": {
        "icon": "assets/product-icons/MPB.jpg?2014-09-30",
        "category": "customPants",
        "optionList": [
            "hasPlacement27",
            "beltColor",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "league",
        "code": "MPB",
        "hasAssets": true,
        "history": "Added 2014",
        "modelId": "MPB",
        "textureId": "MPB",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "placeholder": null
        },
        "colorAreas": {
            "layers": {
                "area1": [
                    "*.design1.area1"
                ],
                "area2": [
                    "frontRight",
                    "frontLeft",
                    "insetRight",
                    "insetLeft",
                    "backRight",
                    "backLeft",
                    "middle",
                    "rope"
                ],
                "area3": [
                    "*.design1.area3"
                ],
                "area4": [
                    "*.design1.area4"
                ],
                "area5": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null
            },
            "titles": {
                "area1": "Front Side Insert",
                "area2": "Body",
                "area3": "Middle Side Insert",
                "area4": "Back Side Insert",
                "area5": null
            },
            "colorSets": [
                "customLean"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "hasPlacement27": true,
            "beltColor": "embroidery",
            "logoColor": "embroidery"
        },
        "decoration": {
            "decorationPackages": [
                "none"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "MPB",
                "price": null,
                "note": "Fill In Through 9/1/20"
            },
            "cutType_youth": {
                "sku": "YMPB",
                "price": null,
                "note": "Fill In Through 9/1/20"
            },
            "cutType_adult7pad": {
                "sku": "MPB-7P",
                "price": null,
                "note": "Fill In Through 9/1/20"
            },
            "cutType_youth7pad": {
                "sku": "YMPB-7P",
                "price": null,
                "note": "Fill In Through 9/1/20"
            }
        }
    },
    "MPC": {
        "icon": "assets/product-icons/MPC.jpg?2014-09-30",
        "category": "customPants",
        "optionList": [
            "hasPlacement27",
            "beltColor",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "league",
        "code": "MPC",
        "hasAssets": true,
        "history": "Added 2014",
        "modelId": "MPC",
        "textureId": "MPC",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "placeholder": null
        },
        "colorAreas": {
            "layers": {
                "area1": [
                    "insetLowerRight",
                    "insetLowerLeft"
                ],
                "area2": [
                    "frontRight",
                    "frontLeft",
                    "backRight",
                    "backLeft",
                    "middle",
                    "insetUpperRight",
                    "insetUpperLeft",
                    "belt.loop",
                    "rope"
                ],
                "area3": [
                    "*.piping"
                ],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": "Inserts",
                "area2": "Body",
                "area3": "Piping",
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "customLean"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "hasPlacement27": true,
            "beltColor": "embroidery",
            "logoColor": "embroidery"
        },
        "decoration": {
            "decorationPackages": [
                "none"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "MPC",
                "price": null,
                "note": "Fill In Through 9/1/20"
            },
            "cutType_youth": {
                "sku": "YMPC",
                "price": null,
                "note": "Fill In Through 9/1/20"
            },
            "cutType_adult7pad": {
                "sku": "MPC-7P",
                "price": null,
                "note": "Fill In Through 9/1/20"
            },
            "cutType_youth7pad": {
                "sku": "YMPC-7P",
                "price": null,
                "note": "Fill In Through 9/1/20"
            }
        }
    },
    "MPD": {
        "icon": "assets/product-icons/MPD.jpg?2014-09-30",
        "category": "customPants",
        "optionList": [
            "beltColor",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "league",
        "code": "MPD",
        "hasAssets": true,
        "history": "Added 2014",
        "modelId": "MPD",
        "textureId": "MPD",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [
            "27LR"
        ],
        "asset": {
            "placeholder": null
        },
        "colorAreas": {
            "layers": {
                "area1": [
                    "insetMiddle1Right",
                    "insetMiddle1Left"
                ],
                "area2": [
                    "insetUpperRight",
                    "insetUpperLeft",
                    "frontRight",
                    "frontLeft",
                    "insetLowerRight",
                    "insetLowerLeft",
                    "backRight",
                    "backLeft",
                    "middle",
                    "belt.loop",
                    "rope"
                ],
                "area3": [
                    "insetMiddle2Right",
                    "insetMiddle2Left"
                ],
                "area4": [
                    "insetMiddle3Right",
                    "insetMiddle3Left"
                ],
                "area5": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null
            },
            "titles": {
                "area1": "Top Insert",
                "area2": "Body",
                "area3": "Middle Insert",
                "area4": "Bottom Insert",
                "area5": null
            },
            "colorSets": [
                "customLean"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "hasPlacement27": false,
            "beltColor": "embroidery",
            "logoColor": "embroidery"
        },
        "decoration": {
            "decorationPackages": [
                "none"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "MPD",
                "price": null,
                "note": "Fill In Through 9/1/20"
            },
            "cutType_youth": {
                "sku": "YMPD",
                "price": null,
                "note": "Fill In Through 9/1/20"
            },
            "cutType_adult7pad": {
                "sku": "MPD-7P",
                "price": null,
                "note": "Fill In Through 9/1/20"
            },
            "cutType_youth7pad": {
                "sku": "YMPD-7P",
                "price": null,
                "note": "Fill In Through 9/1/20"
            }
        }
    },
    "MPE": {
        "icon": "assets/product-icons/MPE.jpg?2014-09-30",
        "category": "customPants",
        "optionList": [
            "beltColor",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "league",
        "code": "MPE",
        "hasAssets": true,
        "history": "Added 2014",
        "modelId": "MPE",
        "textureId": "MPE",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [
            "27LR"
        ],
        "asset": {
            "placeholder": null
        },
        "colorAreas": {
            "layers": {
                "area1": [
                    "*.piping"
                ],
                "area2": [
                    "frontRight",
                    "frontLeft",
                    "backRight",
                    "backLeft",
                    "middle",
                    "insetLowerLeft",
                    "insetLowerRight",
                    "belt.loop",
                    "rope"
                ],
                "area3": [
                    "insetUpperLeft",
                    "insetUpperRight"
                ],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": "Piping",
                "area2": "Body",
                "area3": "Insert",
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "customLean"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "hasPlacement27": false,
            "beltColor": "embroidery",
            "logoColor": "embroidery"
        },
        "decoration": {
            "decorationPackages": [
                "none"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "MPE",
                "price": null,
                "note": "Fill In Through 9/1/20"
            },
            "cutType_youth": {
                "sku": "YMPE",
                "price": null,
                "note": "Fill In Through 9/1/20"
            },
            "cutType_adult7pad": {
                "sku": "MPE-7P",
                "price": null,
                "note": "Fill In Through 9/1/20"
            },
            "cutType_youth7pad": {
                "sku": "YMPE-7P",
                "price": null,
                "note": "Fill In Through 9/1/20"
            }
        }
    },
    "F45P": {
        "icon": "assets/product-icons/F45P.jpg?2014-09-30",
        "category": "stockPants",
        "optionList": [
            "hasPlacement27"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "quality": "varsity",
        "code": "F45P",
        "hasAssets": true,
        "history": "Added 2014",
        "modelId": "F45P",
        "textureId": "F45P",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "placeholder": null
        },
        "colorAreas": {
            "layers": {
                "area1": [],
                "area2": [
                    "legRight",
                    "legLeft",
                    "middle",
                    "belt.sash"
                ],
                "area3": [],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Garment",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "F45P"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "hasPlacement27": true,
            "beltColor": null,
            "logoColor": null
        },
        "decoration": {
            "decorationPackages": [
                "none"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "F45P",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YF25P",
                "price": null,
                "note": null
            },
            "cutType_adult7pad": null,
            "cutType_youth7pad": null
        }
    },
    "F4500P": {
        "icon": "assets/product-icons/F4500P.jpg?2014-09-30",
        "category": "stockPants",
        "optionList": [
            "hasPlacement27"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "quality": "league",
        "code": "F4500P",
        "hasAssets": false,
        "history": "Added 2014, Removed 2016",
        "modelId": "F4500P",
        "textureId": "F4500P",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "placeholder": null
        },
        "colorAreas": {
            "layers": {
                "area1": [],
                "area2": [
                    "legRight",
                    "legLeft",
                    "middle",
                    "belt.sash",
                    "rope"
                ],
                "area3": [],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Garment",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "F4500P"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "hasPlacement27": true,
            "beltColor": null,
            "logoColor": null
        },
        "decoration": {
            "decorationPackages": [
                "none"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "F4500P",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "F2500P",
                "price": null,
                "note": null
            },
            "cutType_adult7pad": null,
            "cutType_youth7pad": null
        }
    },
    "FP147": {
        "icon": "assets/product-icons/FP147.jpg?2014-09-30",
        "category": "stockPants",
        "optionList": [],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "FP147",
        "hasAssets": true,
        "history": "Added 2014",
        "modelId": "FP147",
        "textureId": "FP147",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [
            "27LR"
        ],
        "asset": {
            "placeholder": null
        },
        "colorAreas": {
            "layers": {
                "area1": [],
                "area2": [
                    "frontRight",
                    "frontLeft",
                    "backRight",
                    "backLeft",
                    "middle",
                    "belt.loop",
                    "belt.sash"
                ],
                "area3": [],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Garment",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "FP147"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "hasPlacement27": false,
            "beltColor": null,
            "logoColor": null
        },
        "decoration": {
            "decorationPackages": [
                "none"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "FP147",
                "price": "46",
                "note": null
            },
            "cutType_youth": {
                "sku": "YFP147",
                "price": "46",
                "note": null
            },
            "cutType_adult7pad": null,
            "cutType_youth7pad": null
        }
    },
    "F3500P": {
        "icon": "assets/product-icons/F3500P.jpg?2014-09-30",
        "category": "stockPants",
        "optionList": [
            "hasPlacement27"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "quality": "league",
        "code": "F3500P",
        "hasAssets": true,
        "history": "Added 2014, Removed 2018",
        "modelId": "F3500P",
        "textureId": "F3500P",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "placeholder": null
        },
        "colorAreas": {
            "layers": {
                "area1": [],
                "area2": [
                    "legRight",
                    "legLeft",
                    "middle",
                    "belt.loop",
                    "belt.sash",
                    "rope"
                ],
                "area3": [],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Garment",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "F3500P"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "hasPlacement27": true,
            "beltColor": null,
            "logoColor": null
        },
        "decoration": {
            "decorationPackages": [
                "none"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "F3500P",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "F1500P",
                "price": null,
                "note": null
            },
            "cutType_adult7pad": null,
            "cutType_youth7pad": null
        }
    },
    "PPVF1": {
        "icon": "assets/product-icons/PPVF1.jpg?2014-09-30",
        "category": "sublimatedPants",
        "optionList": [
            "hasPlacement27",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "PPVF1",
        "hasAssets": true,
        "history": "Added 2014",
        "modelId": "PPVF",
        "textureId": "PPVF",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "placeholder": null
        },
        "colorAreas": {
            "layers": {
                "area1": [
                    "belt.sash"
                ],
                "area2": [
                    "belt.loop",
                    "insetUpperRight",
                    "insetUpperLeft",
                    "backRight",
                    "backLeft",
                    "frontRight",
                    "frontLeft",
                    "middle",
                    "insetLowerRight",
                    "insetLowerLeft"
                ],
                "area3": [
                    "*.stripe.color3"
                ],
                "area4": [
                    "*.stripe.color4"
                ],
                "area5": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null
            },
            "titles": {
                "area1": "Belt, Front Insert Stripe",
                "area2": "Body, Top Panel Side Inserts",
                "area3": "Middle Stripe Side Inserts",
                "area4": "Back Stripe Side Inserts",
                "area5": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "hasPlacement27": true,
            "beltColor": null,
            "logoColor": "sublimated"
        },
        "decoration": {
            "decorationPackages": [
                "pantDeco"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "PPVF1",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YPPVF1",
                "price": null,
                "note": null
            },
            "cutType_adult7pad": null,
            "cutType_youth7pad": null
        }
    },
    "PPVF2": {
        "icon": "assets/product-icons/PPVF2.jpg?2014-09-30",
        "category": "sublimatedPants",
        "optionList": [
            "hasPlacement27",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "quality": "varsity",
        "code": "PPVF2",
        "hasAssets": true,
        "history": "Added 2014, Removed 2018",
        "modelId": "PPVF",
        "textureId": "PPVF",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "placeholder": null
        },
        "colorAreas": {
            "layers": {
                "area1": [
                    "belt.sash"
                ],
                "area2": [
                    "belt.loop",
                    "backRight",
                    "backLeft",
                    "frontRight",
                    "frontLeft",
                    "middle",
                    "insetLowerRight",
                    "insetLowerLeft"
                ],
                "area3": [
                    "*.camo.color3"
                ],
                "area4": [
                    "*.camo.color4"
                ],
                "area5": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null
            },
            "titles": {
                "area1": "Belt",
                "area2": "Body",
                "area3": "Side Inserts Camo Main Color",
                "area4": "Side Inserts Accent",
                "area5": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "hasPlacement27": true,
            "beltColor": null,
            "logoColor": "sublimated"
        },
        "decoration": {
            "decorationPackages": [
                "pantDeco"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "PPVF2",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YPPVF2",
                "price": null,
                "note": null
            },
            "cutType_adult7pad": null,
            "cutType_youth7pad": null
        }
    },
    "PPVF3": {
        "icon": "assets/product-icons/PPVF3.jpg?2014-09-30",
        "category": "sublimatedPants",
        "optionList": [
            "hasPlacement27",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "PPVF3",
        "hasAssets": true,
        "history": "Added 2014",
        "modelId": "PPVF",
        "textureId": "PPVF",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "placeholder": null
        },
        "colorAreas": {
            "layers": {
                "area1": [
                    "belt.sash"
                ],
                "area2": [
                    "belt.loop",
                    "backRight",
                    "backLeft",
                    "frontRight",
                    "frontLeft",
                    "middle",
                    "insetLowerRight",
                    "insetLowerLeft"
                ],
                "area3": [
                    "*.carbon.color3"
                ],
                "area4": [
                    "*.carbon.color4"
                ],
                "area5": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null
            },
            "titles": {
                "area1": "Belt",
                "area2": "Body, Bottom Side Inserts",
                "area3": "Side Inserts",
                "area4": "Side Inserts Accent",
                "area5": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "hasPlacement27": true,
            "beltColor": null,
            "logoColor": "sublimated"
        },
        "decoration": {
            "decorationPackages": [
                "pantDeco"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "PPVF3",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YPPVF3",
                "price": null,
                "note": null
            },
            "cutType_adult7pad": null,
            "cutType_youth7pad": null
        }
    },
    "PPVF4": {
        "icon": "assets/product-icons/PPVF4.jpg?2014-09-30",
        "category": "sublimatedPants",
        "optionList": [
            "hasPlacement27",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "PPVF4",
        "hasAssets": true,
        "history": "Added 2014",
        "modelId": "PPVF",
        "textureId": "PPVF",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "placeholder": null
        },
        "colorAreas": {
            "layers": {
                "area1": [
                    "belt.sash"
                ],
                "area2": [
                    "belt.loop",
                    "insetUpperRight",
                    "insetUpperLeft",
                    "backRight",
                    "backLeft",
                    "frontRight",
                    "frontLeft",
                    "middle",
                    "insetLowerRight",
                    "insetLowerLeft"
                ],
                "area3": [
                    "*.fade.color3"
                ],
                "area4": [
                    "*.fade.color4"
                ],
                "area5": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null
            },
            "titles": {
                "area1": "Belt",
                "area2": "Body, Top Panel Side Inserts",
                "area3": "Upper Fade Side Inserts",
                "area4": "Lower Fade Side Inserts",
                "area5": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "hasPlacement27": true,
            "beltColor": null,
            "logoColor": "sublimated"
        },
        "decoration": {
            "decorationPackages": [
                "pantDeco"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "PPVF4",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YPPVF4",
                "price": null,
                "note": null
            },
            "cutType_adult7pad": null,
            "cutType_youth7pad": null
        }
    },
    "PPVF5": {
        "icon": "assets/product-icons/PPVF5.jpg?2014-09-30",
        "category": "sublimatedPants",
        "optionList": [
            "hasPlacement27",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "PPVF5",
        "hasAssets": true,
        "history": "Added 2014",
        "modelId": "PPVF",
        "textureId": "PPVF",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "placeholder": null
        },
        "colorAreas": {
            "layers": {
                "area1": [
                    "belt.sash"
                ],
                "area2": [
                    "belt.loop",
                    "insetUpperRight",
                    "insetUpperLeft",
                    "backRight",
                    "backLeft",
                    "frontRight",
                    "frontLeft",
                    "middle",
                    "insetLowerRight",
                    "insetLowerLeft"
                ],
                "area3": [
                    "insetLowerRight.design",
                    "insetLowerLeft.design"
                ],
                "area4": [
                    "*.design.digitalWave-outline"
                ],
                "area5": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null
            },
            "titles": {
                "area1": "Belt",
                "area2": "Body, Top Panel Side Inserts",
                "area3": "Side Inserts",
                "area4": "Side Inserts outline",
                "area5": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "hasPlacement27": true,
            "beltColor": null,
            "logoColor": "sublimated"
        },
        "decoration": {
            "decorationPackages": [
                "pantDeco"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "PPVF5",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YPPVF5",
                "price": null,
                "note": null
            },
            "cutType_adult7pad": null,
            "cutType_youth7pad": null
        }
    },
    "PPVF6": {
        "icon": "assets/product-icons/PPVF6.jpg?2014-09-30",
        "category": "sublimatedPants",
        "optionList": [
            "hasPlacement27",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "quality": "varsity",
        "code": "PPVF6",
        "hasAssets": true,
        "history": "Added 2014, Removed 2018",
        "modelId": "PPVF",
        "textureId": "PPVF",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "placeholder": null
        },
        "colorAreas": {
            "layers": {
                "area1": [
                    "belt.sash"
                ],
                "area2": [
                    "belt.loop",
                    "insetUpperRight",
                    "insetUpperLeft",
                    "backRight",
                    "backLeft",
                    "frontRight",
                    "frontLeft",
                    "middle",
                    "insetLowerRight",
                    "insetLowerLeft"
                ],
                "area3": [
                    "*.hawk.color3"
                ],
                "area4": [
                    "*.hawk.color4"
                ],
                "area5": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null
            },
            "titles": {
                "area1": "Belt",
                "area2": "Body, Top Panel Side Inserts",
                "area3": "Side Inserts",
                "area4": "Pattern on Side Inserts",
                "area5": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "hasPlacement27": true,
            "beltColor": null,
            "logoColor": "sublimated"
        },
        "decoration": {
            "decorationPackages": [
                "pantDeco"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "PPVF6",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YPPVF6",
                "price": null,
                "note": null
            },
            "cutType_adult7pad": null,
            "cutType_youth7pad": null
        }
    },
    "PPVF7": {
        "icon": "assets/product-icons/PPVF7.jpg?2014-09-30",
        "category": "sublimatedPants",
        "optionList": [
            "hasPlacement27",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "PPVF7",
        "hasAssets": true,
        "history": "Added 2014",
        "modelId": "PPVF",
        "textureId": "PPVF",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "placeholder": null
        },
        "colorAreas": {
            "layers": {
                "area1": [
                    "belt.sash"
                ],
                "area2": [
                    "belt.loop",
                    "insetUpperRight",
                    "insetUpperLeft",
                    "backRight",
                    "backLeft",
                    "frontRight",
                    "frontLeft",
                    "middle",
                    "insetLowerRight",
                    "insetLowerLeft"
                ],
                "area3": [
                    "insetLowerRight",
                    "insetLowerLeft"
                ],
                "area4": [
                    "*.wingback.color4"
                ],
                "area5": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null
            },
            "titles": {
                "area1": "Belt",
                "area2": "Body, Top Panel Side Inserts",
                "area3": "Side Inserts",
                "area4": "Pattern on Top Inserts Panel",
                "area5": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "hasPlacement27": true,
            "beltColor": null,
            "logoColor": "sublimated"
        },
        "decoration": {
            "decorationPackages": [
                "pantDeco"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "PPVF7",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YPPVF7",
                "price": null,
                "note": null
            },
            "cutType_adult7pad": null,
            "cutType_youth7pad": null
        }
    },
    "PPVF8": {
        "icon": "assets/product-icons/PPVF8.jpg?2014-09-30",
        "category": "sublimatedPants",
        "optionList": [
            "hasPlacement27",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "PPVF8",
        "hasAssets": true,
        "history": "Added 2014",
        "modelId": "PPVF",
        "textureId": "PPVF",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "placeholder": null
        },
        "colorAreas": {
            "layers": {
                "area1": [
                    "belt.sash"
                ],
                "area2": [
                    "belt.loop",
                    "backRight",
                    "backLeft",
                    "frontRight",
                    "frontLeft",
                    "middle"
                ],
                "area3": [
                    "insetLowerRight",
                    "insetLowerLeft"
                ],
                "area4": [
                    "insetUpperRight",
                    "insetUpperLeft"
                ],
                "area5": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null
            },
            "titles": {
                "area1": "Belt",
                "area2": "Body",
                "area3": "Insert",
                "area4": "Upper Insert",
                "area5": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "hasPlacement27": true,
            "beltColor": null,
            "logoColor": "sublimated"
        },
        "decoration": {
            "decorationPackages": [
                "pantDeco"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "PPVF8",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YPPVF8",
                "price": null,
                "note": null
            },
            "cutType_adult7pad": null,
            "cutType_youth7pad": null
        }
    },
    "PPVF9": {
        "icon": "assets/product-icons/PPVF9.jpg?2014-09-30",
        "category": "sublimatedPants",
        "optionList": [
            "hasPlacement27",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "PPVF9",
        "hasAssets": true,
        "history": "Added 2014",
        "modelId": "PPVF",
        "textureId": "PPVF",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "placeholder": null
        },
        "colorAreas": {
            "layers": {
                "area1": [
                    "belt.sash"
                ],
                "area2": [
                    "belt.loop",
                    "backRight",
                    "backLeft",
                    "frontRight",
                    "frontLeft",
                    "middle",
                    "insetLowerRight",
                    "insetLowerLeft"
                ],
                "area3": [
                    "*.bronco.color3"
                ],
                "area4": [
                    "*.bronco.color4"
                ],
                "area5": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null
            },
            "titles": {
                "area1": "Back Collar",
                "area2": "Body",
                "area3": "Insert",
                "area4": "Piping",
                "area5": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "hasPlacement27": true,
            "beltColor": null,
            "logoColor": "sublimated"
        },
        "decoration": {
            "decorationPackages": [
                "pantDeco"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "PPVF9",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YPPVF9",
                "price": null,
                "note": null
            },
            "cutType_adult7pad": null,
            "cutType_youth7pad": null
        }
    },
    "PPMZ": {
        "icon": "assets/product-icons/PPMZ.jpg?2014-09-30",
        "category": "sublimatedPants",
        "optionList": [
            "hasPlacement27",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "PPMZ",
        "hasAssets": true,
        "history": "Added 2014",
        "modelId": "PPMZ",
        "textureId": "PPMZ",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "placeholder": null
        },
        "colorAreas": {
            "layers": {
                "area1": [
                    "insetLowerRight.frontScratch",
                    "insetLowerLeft.frontScratch"
                ],
                "area2": [
                    "belt.loop",
                    "frontLeft",
                    "frontRight",
                    "insetUpperLeft",
                    "insetUpperRight",
                    "backRight",
                    "backLeft",
                    "middle",
                    "insetLowerRight",
                    "insetLowerLeft"
                ],
                "area3": [
                    "insetLowerRight.backScratch",
                    "insetLowerLeft.backScratch"
                ],
                "area4": [
                    "belt.sash"
                ],
                "area5": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null
            },
            "titles": {
                "area1": "Front Scratch",
                "area2": "Body",
                "area3": "Back Scratch",
                "area4": "Belt",
                "area5": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "hasPlacement27": true,
            "beltColor": null,
            "logoColor": "sublimated"
        },
        "decoration": {
            "decorationPackages": [
                "pantDeco"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "PPMZ",
                "price": null,
                "note": null
            },
            "cutType_youth": null,
            "cutType_adult7pad": null,
            "cutType_youth7pad": null
        }
    },
    "VFP1": {
        "icon": "assets/product-icons/VFP1.jpg?2014-09-30",
        "category": "sublimatedPants",
        "optionList": [
            "hasPlacement27",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "league",
        "code": "VFP1",
        "hasAssets": true,
        "history": "Added 2014",
        "modelId": "VFP",
        "textureId": "VFP",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "placeholder": null
        },
        "colorAreas": {
            "layers": {
                "area1": [
                    "belt.sash",
                    "*.stripe.color1"
                ],
                "area2": [
                    "insetUpperRight",
                    "insetUpperLeft",
                    "insetLowerLeft",
                    "insetLowerRight",
                    "frontRight",
                    "frontLeft",
                    "backRight",
                    "backLeft",
                    "middle",
                    "belt.loop",
                    "rope"
                ],
                "area3": [
                    "*.stripe.color3"
                ],
                "area4": [
                    "*.stripe.color4"
                ],
                "area5": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null
            },
            "titles": {
                "area1": "Belt, Front Insert Stripe",
                "area2": "Body, Top Panel Side Inserts",
                "area3": "Middle Stripe Side Inserts",
                "area4": "Back Stripe Side Inserts",
                "area5": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "hasPlacement27": true,
            "beltColor": null,
            "logoColor": "sublimated"
        },
        "decoration": {
            "decorationPackages": [
                "pantDeco"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "VFP1",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YVFP1",
                "price": null,
                "note": null
            },
            "cutType_adult7pad": {
                "sku": "VFP1-7P",
                "price": null,
                "note": null
            },
            "cutType_youth7pad": {
                "sku": "YVFP1-7P",
                "price": null,
                "note": null
            }
        }
    },
    "VFP2": {
        "icon": "assets/product-icons/VFP2.jpg?2014-09-30",
        "category": "sublimatedPants",
        "optionList": [
            "hasPlacement27",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "quality": "league",
        "code": "VFP2",
        "hasAssets": true,
        "history": "Added 2014, Removed 2018",
        "modelId": "VFP",
        "textureId": "VFP",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "placeholder": null
        },
        "colorAreas": {
            "layers": {
                "area1": [
                    "belt.sash"
                ],
                "area2": [
                    "insetLowerLeft",
                    "insetLowerRight",
                    "frontRight",
                    "frontLeft",
                    "backRight",
                    "backLeft",
                    "middle",
                    "belt.loop",
                    "rope",
                    "insetUpperRight",
                    "insetUpperLeft"
                ],
                "area3": [
                    "*.camo.color3"
                ],
                "area4": [
                    "*.camo.color4"
                ],
                "area5": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null
            },
            "titles": {
                "area1": "Belt",
                "area2": "Body",
                "area3": "Side Inserts Camo Main Color",
                "area4": "Side Inserts Accent",
                "area5": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "hasPlacement27": true,
            "beltColor": null,
            "logoColor": "sublimated"
        },
        "decoration": {
            "decorationPackages": [
                "pantDeco"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "VFP2",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YVFP2",
                "price": null,
                "note": null
            },
            "cutType_adult7pad": {
                "sku": "VFP2-7P",
                "price": null,
                "note": null
            },
            "cutType_youth7pad": {
                "sku": "YVFP2-7P",
                "price": null,
                "note": null
            }
        }
    },
    "VFP3": {
        "icon": "assets/product-icons/VFP3.jpg?2014-09-30",
        "category": "sublimatedPants",
        "optionList": [
            "hasPlacement27",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "league",
        "code": "VFP3",
        "hasAssets": true,
        "history": "Added 2014",
        "modelId": "VFP",
        "textureId": "VFP",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "placeholder": null
        },
        "colorAreas": {
            "layers": {
                "area1": [
                    "belt.sash"
                ],
                "area2": [
                    "insetLowerLeft",
                    "insetLowerRight",
                    "frontRight",
                    "frontLeft",
                    "backRight",
                    "backLeft",
                    "middle",
                    "belt.loop",
                    "rope",
                    "insetUpperRight",
                    "insetUpperLeft"
                ],
                "area3": [
                    "*.carbon.color3"
                ],
                "area4": [
                    "*.carbon.color4"
                ],
                "area5": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null
            },
            "titles": {
                "area1": "Belt",
                "area2": "Body, Bottom Side Inserts",
                "area3": "Side Inserts",
                "area4": "Side Inserts Accent",
                "area5": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "hasPlacement27": true,
            "beltColor": null,
            "logoColor": "sublimated"
        },
        "decoration": {
            "decorationPackages": [
                "pantDeco"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "VFP3",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YVFP3",
                "price": null,
                "note": null
            },
            "cutType_adult7pad": {
                "sku": "VFP3-7P",
                "price": null,
                "note": null
            },
            "cutType_youth7pad": {
                "sku": "YVFP3-7P",
                "price": null,
                "note": null
            }
        }
    },
    "VFP4": {
        "icon": "assets/product-icons/VFP4.jpg?2014-09-30",
        "category": "sublimatedPants",
        "optionList": [
            "hasPlacement27",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "league",
        "code": "VFP4",
        "hasAssets": true,
        "history": "Added 2014",
        "modelId": "VFP",
        "textureId": "VFP",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "placeholder": null
        },
        "colorAreas": {
            "layers": {
                "area1": [
                    "belt.sash"
                ],
                "area2": [
                    "insetUpperRight",
                    "insetUpperLeft",
                    "insetLowerLeft",
                    "insetLowerRight",
                    "frontRight",
                    "frontLeft",
                    "backRight",
                    "backLeft",
                    "middle",
                    "belt.loop",
                    "rope"
                ],
                "area3": [
                    "*.fade.color3"
                ],
                "area4": [
                    "*.fade.color4"
                ],
                "area5": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null
            },
            "titles": {
                "area1": "Belt",
                "area2": "Body, Top Panel Side Inserts",
                "area3": "Upper Fade Side Inserts",
                "area4": "Lower Fade Side Inserts",
                "area5": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "hasPlacement27": true,
            "beltColor": null,
            "logoColor": "sublimated"
        },
        "decoration": {
            "decorationPackages": [
                "pantDeco"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "VFP4",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YVFP4",
                "price": null,
                "note": null
            },
            "cutType_adult7pad": {
                "sku": "VFP4-7P",
                "price": null,
                "note": null
            },
            "cutType_youth7pad": {
                "sku": "YVFP4-7P",
                "price": null,
                "note": null
            }
        }
    },
    "VFP5": {
        "icon": "assets/product-icons/VFP5.jpg?2014-09-30",
        "category": "sublimatedPants",
        "optionList": [
            "hasPlacement27",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "league",
        "code": "VFP5",
        "hasAssets": true,
        "history": "Added 2014",
        "modelId": "VFP",
        "textureId": "VFP",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "placeholder": null
        },
        "colorAreas": {
            "layers": {
                "area1": [
                    "belt.sash"
                ],
                "area2": [
                    "insetUpperRight",
                    "insetUpperLeft",
                    "insetLowerLeft",
                    "insetLowerRight",
                    "frontRight",
                    "frontLeft",
                    "backRight",
                    "backLeft",
                    "middle",
                    "belt.loop",
                    "rope"
                ],
                "area3": [
                    "insetLowerRight.design",
                    "insetLowerLeft.design"
                ],
                "area4": [
                    "insetLowerLeft.design.digitalWave-outline",
                    "insetLowerRight.design.digitalWave-outline"
                ],
                "area5": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null
            },
            "titles": {
                "area1": "Belt",
                "area2": "Body, Top Panel Side Inserts",
                "area3": "Side Inserts",
                "area4": "Side Inserts outline",
                "area5": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "hasPlacement27": true,
            "beltColor": null,
            "logoColor": "sublimated"
        },
        "decoration": {
            "decorationPackages": [
                "pantDeco"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "VFP5",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YVFP5",
                "price": null,
                "note": null
            },
            "cutType_adult7pad": {
                "sku": "VFP5-7P",
                "price": null,
                "note": null
            },
            "cutType_youth7pad": {
                "sku": "YVFP5-7P",
                "price": null,
                "note": null
            }
        }
    },
    "VFP6": {
        "icon": "assets/product-icons/VFP6.jpg?2014-09-30",
        "category": "sublimatedPants",
        "optionList": [
            "hasPlacement27",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": false,
            "bsn": false
        },
        "quality": "league",
        "code": "VFP6",
        "hasAssets": true,
        "history": "Added 2014, Removed 2018",
        "modelId": "VFP",
        "textureId": "VFP",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "placeholder": null
        },
        "colorAreas": {
            "layers": {
                "area1": [
                    "belt.sash"
                ],
                "area2": [
                    "insetUpperRight",
                    "insetUpperLeft",
                    "insetLowerLeft",
                    "insetLowerRight",
                    "frontRight",
                    "frontLeft",
                    "backRight",
                    "backLeft",
                    "middle",
                    "belt.loop",
                    "rope"
                ],
                "area3": [
                    "*.hawk.color3"
                ],
                "area4": [
                    "*.hawk.color4"
                ],
                "area5": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null
            },
            "titles": {
                "area1": "Belt",
                "area2": "Body, Top Panel Side Inserts",
                "area3": "Side Inserts",
                "area4": "Pattern on Side Inserts",
                "area5": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "hasPlacement27": true,
            "beltColor": null,
            "logoColor": "sublimated"
        },
        "decoration": {
            "decorationPackages": [
                "pantDeco"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "VFP6",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YVFP6",
                "price": null,
                "note": null
            },
            "cutType_adult7pad": {
                "sku": "VFP6-7P",
                "price": null,
                "note": null
            },
            "cutType_youth7pad": {
                "sku": "YVFP6-7P",
                "price": null,
                "note": null
            }
        }
    },
    "VFP7": {
        "icon": "assets/product-icons/VFP7.jpg?2014-09-30",
        "category": "sublimatedPants",
        "optionList": [
            "hasPlacement27",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "league",
        "code": "VFP7",
        "hasAssets": true,
        "history": "Added 2014",
        "modelId": "VFP",
        "textureId": "VFP",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "placeholder": null
        },
        "colorAreas": {
            "layers": {
                "area1": [
                    "belt.sash"
                ],
                "area2": [
                    "insetUpperRight",
                    "insetUpperLeft",
                    "insetLowerLeft",
                    "insetLowerRight",
                    "frontRight",
                    "frontLeft",
                    "backRight",
                    "backLeft",
                    "middle",
                    "belt.loop",
                    "rope"
                ],
                "area3": [
                    "insetLowerRight",
                    "insetLowerLeft"
                ],
                "area4": [
                    "*.wingback.color4"
                ],
                "area5": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null
            },
            "titles": {
                "area1": "Belt",
                "area2": "Body, Top Panel Side Inserts",
                "area3": "Side Inserts",
                "area4": "Pattern on Top Inserts Panel",
                "area5": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "hasPlacement27": true,
            "beltColor": null,
            "logoColor": "sublimated"
        },
        "decoration": {
            "decorationPackages": [
                "pantDeco"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "VFP7",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YVFP7",
                "price": null,
                "note": null
            },
            "cutType_adult7pad": {
                "sku": "VFP7-7P",
                "price": null,
                "note": null
            },
            "cutType_youth7pad": {
                "sku": "YVFP7-7P",
                "price": null,
                "note": null
            }
        }
    },
    "VFP8": {
        "icon": "assets/product-icons/VFP8.jpg?2014-09-30",
        "category": "sublimatedPants",
        "optionList": [
            "hasPlacement27",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "league",
        "code": "VFP8",
        "hasAssets": true,
        "history": "Added 2014",
        "modelId": "VFP",
        "textureId": "VFP",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "placeholder": null
        },
        "colorAreas": {
            "layers": {
                "area1": [
                    "belt.sash"
                ],
                "area2": [
                    "insetUpperRight",
                    "insetUpperLeft",
                    "insetLowerLeft",
                    "insetLowerRight",
                    "frontRight",
                    "frontLeft",
                    "backRight",
                    "backLeft",
                    "middle",
                    "belt.loop",
                    "rope"
                ],
                "area3": [
                    "insetLowerRight",
                    "insetLowerLeft"
                ],
                "area4": [
                    "insetUpperRight",
                    "insetUpperLeft"
                ],
                "area5": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null
            },
            "titles": {
                "area1": "Belt",
                "area2": "Body",
                "area3": "Side Inserts",
                "area4": "Top Panel Side Inserts",
                "area5": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "hasPlacement27": true,
            "beltColor": null,
            "logoColor": "sublimated"
        },
        "decoration": {
            "decorationPackages": [
                "pantDeco"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "VFP8",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YVFP8",
                "price": null,
                "note": null
            },
            "cutType_adult7pad": {
                "sku": "VFP8-7P",
                "price": null,
                "note": null
            },
            "cutType_youth7pad": {
                "sku": "YVFP8-7P",
                "price": null,
                "note": null
            }
        }
    },
    "VFP9": {
        "icon": "assets/product-icons/VFP9.jpg?2014-09-30",
        "category": "sublimatedPants",
        "optionList": [
            "hasPlacement27",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "league",
        "code": "VFP9",
        "hasAssets": true,
        "history": "Added 2014",
        "modelId": "VFP",
        "textureId": "VFP",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "placeholder": null
        },
        "colorAreas": {
            "layers": {
                "area1": [
                    "belt.sash"
                ],
                "area2": [
                    "insetLowerLeft",
                    "insetLowerRight",
                    "frontRight",
                    "frontLeft",
                    "backRight",
                    "backLeft",
                    "middle",
                    "belt.loop",
                    "rope",
                    "insetUpperRight",
                    "insetUpperLeft"
                ],
                "area3": [
                    "*.bronco.color3"
                ],
                "area4": [
                    "*.bronco.color4"
                ],
                "area5": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null
            },
            "titles": {
                "area1": "Belt",
                "area2": "Body",
                "area3": "Insert",
                "area4": "Piping",
                "area5": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "hasPlacement27": true,
            "beltColor": null,
            "logoColor": "sublimated"
        },
        "decoration": {
            "decorationPackages": [
                "pantDeco"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "VFP9",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YVFP9",
                "price": null,
                "note": null
            },
            "cutType_adult7pad": {
                "sku": "VFP9-7P",
                "price": null,
                "note": null
            },
            "cutType_youth7pad": {
                "sku": "YVFP9-7P",
                "price": null,
                "note": null
            }
        }
    },
    "FPL": {
        "icon": "assets/product-icons/FPL.jpg?2014-09-30",
        "category": "stockPants",
        "optionList": [],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "league",
        "code": "FPL",
        "hasAssets": true,
        "history": "Added 2016",
        "modelId": "F4500P",
        "textureId": "F4500P",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [
            "27LR"
        ],
        "asset": {
            "placeholder": null
        },
        "colorAreas": {
            "layers": {
                "area1": [],
                "area2": [
                    "legRight",
                    "legLeft",
                    "middle",
                    "belt.sash",
                    "rope"
                ],
                "area3": [],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": null,
                "area2": "solidColors",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "titles": {
                "area1": null,
                "area2": "Garment",
                "area3": null,
                "area4": null,
                "area5": null
            },
            "colorSets": [
                "F4500P"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "hasPlacement27": false,
            "beltColor": null,
            "logoColor": null
        },
        "decoration": {
            "decorationPackages": [
                "none"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "FPL",
                "price": "54",
                "note": null
            },
            "cutType_youth": {
                "sku": "YFPL",
                "price": "44",
                "note": null
            },
            "cutType_adult7pad": null,
            "cutType_youth7pad": null
        }
    },
    "VFPD": {
        "icon": "assets/product-icons/VFPD.jpg?2014-09-30",
        "category": "sublimatedPants",
        "optionList": [
            "hasPlacement27",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "league",
        "code": "VFPD",
        "hasAssets": true,
        "history": "Added 2016",
        "modelId": "VFP",
        "textureId": "VFP",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "placeholder": null
        },
        "colorAreas": {
            "layers": {
                "area1": [
                    "belt.sash"
                ],
                "area2": [
                    "frontRight",
                    "frontLeft",
                    "backRight",
                    "backLeft",
                    "middle",
                    "belt.loop",
                    "rope"
                ],
                "area3": [
                    "insetUpperRight",
                    "insetUpperLeft"
                ],
                "area4": [
                    "insetLowerLeft",
                    "insetLowerRight"
                ],
                "area5": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "insert",
                "area5": null
            },
            "titles": {
                "area1": "Belt",
                "area2": "Body",
                "area3": "Top of Insert",
                "area4": "Side Insert (Sublimated Design)",
                "area5": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "hasPlacement27": true,
            "beltColor": null,
            "logoColor": "embroidery"
        },
        "decoration": {
            "decorationPackages": [
                "pantDeco"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "VFPD",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YVFPD",
                "price": null,
                "note": null
            },
            "cutType_adult7pad": {
                "sku": "VFPD-7P",
                "price": null,
                "note": null
            },
            "cutType_youth7pad": {
                "sku": "YVFPD-7P",
                "price": null,
                "note": null
            }
        }
    },
    "PPVFD": {
        "icon": "assets/product-icons/PPVFD.jpg?2014-09-30",
        "category": "sublimatedPants",
        "optionList": [
            "hasPlacement27",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "PPVFD",
        "hasAssets": true,
        "history": "Added 2016",
        "modelId": "PPVF",
        "textureId": "PPVF",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "placeholder": null
        },
        "colorAreas": {
            "layers": {
                "area1": [
                    "belt.sash"
                ],
                "area2": [
                    "belt.loop",
                    "backRight",
                    "backLeft",
                    "frontRight",
                    "frontLeft",
                    "middle"
                ],
                "area3": [
                    "insetUpperRight",
                    "insetUpperLeft"
                ],
                "area4": [
                    "insetLowerRight",
                    "insetLowerLeft"
                ],
                "area5": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "insert",
                "area5": null
            },
            "titles": {
                "area1": "Belt",
                "area2": "Body",
                "area3": "Top of Insert",
                "area4": "Side Insert (Sublimated Design)",
                "area5": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "hasPlacement27": true,
            "beltColor": null,
            "logoColor": "embroidery"
        },
        "decoration": {
            "decorationPackages": [
                "pantDeco"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "PPVFD",
                "price": null,
                "note": null
            },
            "cutType_youth": null,
            "cutType_adult7pad": null,
            "cutType_youth7pad": null
        }
    },
    "PP147": {
        "icon": "assets/product-icons/PP147.jpg?2014-09-30",
        "category": "sublimatedPants",
        "optionList": [
            "hasPlacement27",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "PP147",
        "hasAssets": false,
        "history": "Added 2016",
        "modelId": null,
        "textureId": null,
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "placeholder": null
        },
        "colorAreas": {
            "layers": {
                "area1": [],
                "area2": [],
                "area3": [],
                "area4": [],
                "area5": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "insert",
                "area5": "solidColors"
            },
            "titles": {
                "area1": "Belt",
                "area2": "Body",
                "area3": "Top Side Insert",
                "area4": "Side Insert (Sublimated Design)",
                "area5": "Bottom Side Insert"
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "hasPlacement27": true,
            "beltColor": null,
            "logoColor": "embroidery"
        },
        "decoration": {
            "decorationPackages": [
                "pantDeco"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "PP147",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YPP147",
                "price": null,
                "note": null
            },
            "cutType_adult7pad": null,
            "cutType_youth7pad": null
        }
    },
    "VFPMZ": {
        "icon": "assets/product-icons/VFPMZ.jpg?2014-09-30",
        "category": "sublimatedPants",
        "optionList": [
            "hasPlacement27",
            "logoColor"
        ],
        "clothingType": "pant",
        "subsets": {
            "default": true,
            "bsn": true
        },
        "quality": "varsity",
        "code": "VFPMZ",
        "hasAssets": true,
        "history": "Added 2017",
        "modelId": "VFP",
        "textureId": "VFP",
        "disabledDecoIds": [],
        "disabledDecoPlacementIds": [],
        "asset": {
            "placeholder": null
        },
        "colorAreas": {
            "layers": {
                "area1": [
                    "*.vfpmz.color1"
                ],
                "area2": [
                    "rope",
                    "insetUpperLeft",
                    "insetUpperRight",
                    "frontLeft",
                    "frontRight",
                    "backLeft",
                    "backRight",
                    "middle",
                    "insetLowerRight",
                    "insetLowerLeft",
                    "belt.loop"
                ],
                "area3": [
                    "*.vfpmz.color3"
                ],
                "area4": [
                    "belt.sash"
                ],
                "area5": []
            },
            "patternSets": {
                "area1": "solidColors",
                "area2": "solidColors",
                "area3": "solidColors",
                "area4": "solidColors",
                "area5": null
            },
            "titles": {
                "area1": "Front Scratch",
                "area2": "Body",
                "area3": "Back Scratch",
                "area4": "Belt",
                "area5": null
            },
            "colorSets": [
                "sublimated"
            ],
            "patterns": [
                "solidColor"
            ]
        },
        "options": {
            "hasPlacement27": true,
            "beltColor": null,
            "logoColor": "sublimated"
        },
        "decoration": {
            "decorationPackages": [
                "pantDeco"
            ]
        },
        "skuMap": {
            "cutType_adult": {
                "sku": "VFPMZ",
                "price": null,
                "note": null
            },
            "cutType_youth": {
                "sku": "YVFPMZ",
                "price": null,
                "note": null
            },
            "cutType_adult7pad": {
                "sku": "VFPMZ-7",
                "price": null,
                "note": null
            },
            "cutType_youth7pad": {
                "sku": "YVFPMZ-7P",
                "price": null,
                "note": null
            }
        }
    }
}