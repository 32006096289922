module.exports={
    "none": {
        "code": "none",
        "abbr": "NONE",
        "name": "None",
        "colorCount": 0,
        "whitelists": {
            "CLSU": true
        }
    },
    "CLSU": {
        "code": "CLSU",
        "abbr": "CLSU",
        "name": "CLSU",
        "colorCount": 3,
        "whitelists": {
            "CLSU": true
        }
    }
}