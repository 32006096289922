module.exports={
    "vt": {
        "code": "vt",
        "name": "Embroidered Team Name / Sublimated Twill Numbers",
        "abbr": "VT",
        "clothingType": "jersey",
        "legalValues": {
            "teamName": {
                "isAvailable": true,
                "fontSetId": "allText",
                "layoutSetId": "straightOnly",
                "fill": {
                    "patternSetId": "solidColors",
                    "colorSetId": "embroidery"
                },
                "outline": {
                    "colorSetId": "embroidery"
                },
                "tail": false
            },
            "playerName": {
                "isAvailable": false,
                "fontSetId": null,
                "layoutSetId": null,
                "fill": {
                    "patternSetId": null,
                    "colorSetId": null
                },
                "outline": {
                    "colorSetId": null
                },
                "tail": false
            },
            "number": {
                "isAvailable": true,
                "fontSetId": "allNumber",
                "layoutSetId": "straightOnly",
                "fill": {
                    "patternSetId": "solidColors",
                    "colorSetId": "sublimated"
                },
                "outline": {
                    "colorSetId": "sublimated"
                },
                "tail": false
            },
            "watermarkText": {
                "isAvailable": false,
                "fontSetId": null,
                "layoutSetId": null,
                "fill": {},
                "outline": {},
                "tail": false
            },
            "stockLogo": {
                "isAvailable": true,
                "fill": {
                    "colorSetId": null
                },
                "outline": {},
                "tail": false
            },
            "customLogo": {
                "isAvailable": true,
                "fill": {},
                "outline": {},
                "tail": false
            },
            "watermarkLogo": {
                "isAvailable": false,
                "fill": {},
                "outline": {},
                "tail": false
            }
        }
    },
    "rvt": {
        "code": "rvt",
        "name": "Reflective Sublimated Twill",
        "abbr": "RVT",
        "clothingType": "jersey",
        "legalValues": {
            "teamName": {
                "isAvailable": true,
                "fontSetId": "allText",
                "layoutSetId": "straightOnly",
                "fill": {
                    "patternSetId": "solidColors",
                    "colorSetId": "embroidery"
                },
                "outline": {
                    "colorSetId": "embroidery"
                },
                "tail": false
            },
            "playerName": {
                "isAvailable": false,
                "fontSetId": null,
                "layoutSetId": null,
                "fill": {
                    "patternSetId": null,
                    "colorSetId": null
                },
                "outline": {
                    "colorSetId": null
                },
                "tail": false
            },
            "number": {
                "isAvailable": true,
                "fontSetId": "allNumber",
                "layoutSetId": "straightOnly",
                "fill": {
                    "patternSetId": "solidColors",
                    "colorSetId": "sublimated"
                },
                "outline": {
                    "colorSetId": "rvt"
                },
                "tail": false
            },
            "watermarkText": {
                "isAvailable": false,
                "fontSetId": null,
                "layoutSetId": null,
                "fill": {},
                "outline": {},
                "tail": false
            },
            "stockLogo": {
                "isAvailable": true,
                "fill": {
                    "colorSetId": null
                },
                "outline": {},
                "tail": false
            },
            "customLogo": {
                "isAvailable": true,
                "fill": {},
                "outline": {},
                "tail": false
            },
            "watermarkLogo": {
                "isAvailable": false,
                "fill": {},
                "outline": {},
                "tail": false
            }
        }
    },
    "fullSublimation": {
        "code": "fullSublimation",
        "name": "Full Sublimation",
        "abbr": "FULL",
        "clothingType": "jersey",
        "legalValues": {
            "teamName": {
                "isAvailable": true,
                "fontSetId": "allText",
                "layoutSetId": "straightOnly",
                "fill": {
                    "patternSetId": "lettering",
                    "colorSetId": "sublimated"
                },
                "outline": {
                    "colorSetId": "sublimated"
                },
                "tail": false
            },
            "playerName": {
                "isAvailable": true,
                "fontSetId": "allText",
                "layoutSetId": "straightOnly",
                "fill": {
                    "patternSetId": "lettering",
                    "colorSetId": "sublimated"
                },
                "outline": {
                    "colorSetId": "sublimated"
                },
                "tail": false
            },
            "number": {
                "isAvailable": true,
                "fontSetId": "allNumber",
                "layoutSetId": "straightOnly",
                "fill": {
                    "patternSetId": "lettering",
                    "colorSetId": "sublimated"
                },
                "outline": {
                    "colorSetId": "sublimated"
                },
                "tail": false
            },
            "watermarkText": {
                "isAvailable": false,
                "fontSetId": null,
                "layoutSetId": null,
                "fill": {},
                "outline": {},
                "tail": false
            },
            "stockLogo": {
                "isAvailable": true,
                "fill": {
                    "colorSetId": "sublimated"
                },
                "outline": {},
                "tail": false
            },
            "customLogo": {
                "isAvailable": true,
                "fill": {},
                "outline": {},
                "tail": false
            },
            "watermarkLogo": {
                "isAvailable": false,
                "fill": {},
                "outline": {},
                "tail": false
            }
        }
    },
    "fasttrack": {
        "code": "fasttrack",
        "name": "Fasttrack",
        "abbr": "FAST",
        "clothingType": "jersey",
        "legalValues": {
            "teamName": {
                "isAvailable": true,
                "fontSetId": "fasttrackText",
                "layoutSetId": "fasttrack",
                "fill": {
                    "patternSetId": "lettering7v7",
                    "colorSetId": "sublimated"
                },
                "outline": {
                    "colorSetId": "sublimated"
                },
                "tail": true
            },
            "playerName": {
                "isAvailable": true,
                "fontSetId": "fasttrackText",
                "layoutSetId": "straightOnly",
                "fill": {
                    "patternSetId": "lettering7v7",
                    "colorSetId": "sublimated"
                },
                "outline": {
                    "colorSetId": "sublimated"
                },
                "tail": false
            },
            "number": {
                "isAvailable": true,
                "fontSetId": "fasttrackNumber",
                "layoutSetId": "straightOnly",
                "fill": {
                    "patternSetId": "lettering7v7",
                    "colorSetId": "sublimated"
                },
                "outline": {
                    "colorSetId": "sublimated"
                },
                "tail": false
            },
            "watermarkText": {
                "isAvailable": true,
                "fontSetId": "fasttrackText",
                "layoutSetId": "straightOnly",
                "fill": {},
                "outline": {},
                "tail": false
            },
            "stockLogo": {
                "isAvailable": true,
                "fill": {
                    "colorSetId": "embroidery"
                },
                "outline": {},
                "tail": false
            },
            "customLogo": {
                "isAvailable": true,
                "fill": {},
                "outline": {},
                "tail": false
            },
            "watermarkLogo": {
                "isAvailable": true,
                "fill": {},
                "outline": {},
                "tail": false
            }
        }
    },
    "pantDeco": {
        "code": "pantDeco",
        "name": "Pant Decoration",
        "abbr": "RPD",
        "clothingType": "pant",
        "legalValues": {
            "teamName": {
                "isAvailable": true,
                "fontSetId": "allText",
                "layoutSetId": "straightOnly",
                "fill": {
                    "patternSetId": "solidColors",
                    "colorSetId": "sublimated"
                },
                "outline": {
                    "colorSetId": "sublimated"
                },
                "tail": false
            },
            "playerName": {
                "isAvailable": false,
                "fontSetId": null,
                "layoutSetId": null,
                "fill": {
                    "patternSetId": null,
                    "colorSetId": null
                },
                "outline": {
                    "colorSetId": null
                },
                "tail": false
            },
            "number": {
                "isAvailable": false,
                "fontSetId": null,
                "layoutSetId": null,
                "fill": {
                    "patternSetId": null,
                    "colorSetId": null
                },
                "outline": {
                    "colorSetId": null
                },
                "tail": false
            },
            "watermarkText": {
                "isAvailable": false,
                "fontSetId": null,
                "layoutSetId": null,
                "fill": {},
                "outline": {},
                "tail": false
            },
            "stockLogo": {
                "isAvailable": true,
                "fill": {
                    "colorSetId": "sublimated"
                },
                "outline": {},
                "tail": false
            },
            "customLogo": {
                "isAvailable": true,
                "fill": {},
                "outline": {},
                "tail": false
            },
            "watermarkLogo": {
                "isAvailable": false,
                "fill": {},
                "outline": {},
                "tail": false
            }
        }
    },
    "none": {
        "code": "none",
        "name": "None",
        "abbr": "NONE",
        "clothingType": "pant",
        "legalValues": {
            "teamName": {
                "isAvailable": false,
                "fontSetId": null,
                "layoutSetId": null,
                "fill": {
                    "patternSetId": null,
                    "colorSetId": null
                },
                "outline": {
                    "colorSetId": null
                },
                "tail": false
            },
            "playerName": {
                "isAvailable": false,
                "fontSetId": null,
                "layoutSetId": null,
                "fill": {
                    "patternSetId": null,
                    "colorSetId": null
                },
                "outline": {
                    "colorSetId": null
                },
                "tail": false
            },
            "number": {
                "isAvailable": false,
                "fontSetId": null,
                "layoutSetId": null,
                "fill": {
                    "patternSetId": null,
                    "colorSetId": null
                },
                "outline": {
                    "colorSetId": null
                },
                "tail": false
            },
            "watermarkText": {
                "isAvailable": false,
                "fontSetId": null,
                "layoutSetId": null,
                "fill": {},
                "outline": {},
                "tail": false
            },
            "stockLogo": {
                "isAvailable": false,
                "fill": {
                    "colorSetId": null
                },
                "outline": {},
                "tail": false
            },
            "customLogo": {
                "isAvailable": false,
                "fill": {},
                "outline": {},
                "tail": false
            },
            "watermarkLogo": {
                "isAvailable": false,
                "fill": {},
                "outline": {},
                "tail": false
            }
        }
    }
}