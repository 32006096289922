var _ = require('lodash')
var sheets = require('./sheets')
var whitelists = require('./whitelists.json')

function getKeys(sheet) {
    return _.map(sheet, function(value) {
        return value.id || value.code
    })
}

whitelists = _.mapValues(sheets, function(sheet, sheetId) {
    var original = whitelists[sheetId]
    var firstItem = _.find(sheet, _.identity)
    if (!firstItem) {
        return original
    }
    var subsets = firstItem.subsets
    if (!subsets) {
        return original
    }
    return _.assign(
        {
            all: getKeys(sheet),
        },
        _.mapValues(subsets, function(__, id) {
            return getKeys(
                _.filter(sheet, function(row) {
                    return row.subsets[id]
                })
            )
        }),
        original
    )
})
console.log('whitelists', whitelists)

module.exports = whitelists
