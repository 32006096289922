exports.sheets = require('./sheets')
exports.whitelists = require('./whitelists')
exports.config = require('./config')

var _ = require('lodash')
var garmentVariants = {}

function addGarmentVariant (baseId, variantName, shouldUseVariant, variantConfig) {
    var baseDef = exports.sheets.garments[baseId]
    var variant = _.assign({},
        baseDef,
        {
            code: baseId + '-' + variantName,
            baseId: baseId,
            modelId: baseDef.modelId + '-' + variantName,
            textureId: baseDef.textureId + '-' + variantName,
            shouldUseVariant: shouldUseVariant,
        },
        variantConfig
    )
    garmentVariants[variant.code] = variant
}
function findGarment (code, design) {
    return _.find(exports.garmentsWithVariants, function (garmentDef) {
        if(garmentDef.baseId === code){
            return garmentDef.shouldUseVariant(design)
        }
        return garmentDef.code === code
    })
}

exports.garmentsWithVariants = _.assign({}, garmentVariants, exports.sheets.garments)
exports.findGarment = findGarment
