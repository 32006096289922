module.exports={
    "PJHD47": [
        {
            "code": "PJHD47",
            "area": "area7",
            "colorSet": "reflectiveOptional",
            "patterns": []
        }
    ],
    "PJ47VF2": [
        {
            "code": "PJ47VF2",
            "area": "area2",
            "colorSet": null,
            "patterns": [
                "autoDigitalCamo"
            ]
        }
    ],
    "PJ47VF3": [
        {
            "code": "PJ47VF3",
            "area": "area1",
            "colorSet": null,
            "patterns": [
                "carbonBlock"
            ]
        }
    ],
    "PJ47VF5": [
        {
            "code": "PJ47VF5",
            "area": "area1",
            "colorSet": null,
            "patterns": [
                "digitalWave-element"
            ]
        }
    ],
    "VFCJ2": [
        {
            "code": "VFCJ2",
            "area": "area2",
            "colorSet": null,
            "patterns": [
                "autoDigitalCamo"
            ]
        }
    ],
    "VFCJ3": [
        {
            "code": "VFCJ3",
            "area": "area1",
            "colorSet": null,
            "patterns": [
                "carbonBlock"
            ]
        }
    ],
    "VFCJ5": [
        {
            "code": "VFCJ5",
            "area": "area1",
            "colorSet": null,
            "patterns": [
                "digitalWave-element"
            ]
        }
    ],
    "CJEHS": [
        {
            "code": "CJEHS",
            "area": "area1",
            "colorSet": "embroidery",
            "patterns": []
        },
        {
            "code": "CJEHS",
            "area": "area2",
            "colorSet": null,
            "patterns": [
                "solidColor",
                "fade"
            ]
        }
    ],
    "RVFJ4": [
        {
            "code": "RVFJ4",
            "area": "area1",
            "colorSet": null,
            "patterns": [
                "rvfj4"
            ]
        },
        {
            "code": "RVFJ4",
            "area": "area2",
            "colorSet": null,
            "patterns": [
                "rvfj4"
            ]
        }
    ],
    "RVFJ7": [
        {
            "code": "RVFJ7",
            "area": "area1",
            "colorSet": null,
            "patterns": [
                "rvfj7"
            ]
        },
        {
            "code": "RVFJ7",
            "area": "area2",
            "colorSet": null,
            "patterns": [
                "rvfj7"
            ]
        }
    ],
    "RVFJ8": [
        {
            "code": "RVFJ8",
            "area": "area1",
            "colorSet": null,
            "patterns": [
                "rvfj8"
            ]
        },
        {
            "code": "RVFJ8",
            "area": "area2",
            "colorSet": null,
            "patterns": [
                "rvfj8"
            ]
        }
    ],
    "RVFJFG": [
        {
            "code": "RVFJFG",
            "area": "area1",
            "colorSet": null,
            "patterns": [
                "rvfjfg"
            ]
        },
        {
            "code": "RVFJFG",
            "area": "area2",
            "colorSet": null,
            "patterns": [
                "rvfjfg"
            ]
        }
    ],
    "RVFJMZ": [
        {
            "code": "RVFJMZ",
            "area": "area1",
            "colorSet": null,
            "patterns": [
                "rvfjmz"
            ]
        },
        {
            "code": "RVFJMZ",
            "area": "area2",
            "colorSet": null,
            "patterns": [
                "rvfjmz"
            ]
        }
    ],
    "RVFJM": [
        {
            "code": "RVFJM",
            "area": "area1",
            "colorSet": null,
            "patterns": [
                "rvfj4",
                "rvfj7",
                "rvfj8",
                "rvfjfg",
                "rvfjmz"
            ]
        },
        {
            "code": "RVFJM",
            "area": "area2",
            "colorSet": null,
            "patterns": [
                "rvfj4",
                "rvfj7",
                "rvfj8",
                "rvfjfg",
                "rvfjmz"
            ]
        }
    ],
    "RVFJME": [
        {
            "code": "RVFJME",
            "area": "area1",
            "colorSet": null,
            "patterns": [
                "rvfj4",
                "rvfj7",
                "rvfj8",
                "rvfjfg",
                "rvfjmz"
            ]
        },
        {
            "code": "RVFJME",
            "area": "area2",
            "colorSet": null,
            "patterns": [
                "rvfj4",
                "rvfj7",
                "rvfj8",
                "rvfjfg",
                "rvfjmz"
            ]
        }
    ],
    "GLJ905547": [
        {
            "code": "GLJ905547",
            "area": "area5",
            "colorSet": "knit",
            "patterns": []
        },
        {
            "code": "GLJ905547",
            "area": "area6",
            "colorSet": "knit",
            "patterns": []
        }
    ],
    "GLJ905547VF": [
        {
            "code": "GLJ905547VF",
            "area": "area5",
            "colorSet": "knit",
            "patterns": []
        },
        {
            "code": "GLJ905547VF",
            "area": "area6",
            "colorSet": "knit",
            "patterns": []
        }
    ],
    "PACJSR": [
        {
            "code": "PACJSR",
            "area": "area5",
            "colorSet": "knit",
            "patterns": []
        },
        {
            "code": "PACJSR",
            "area": "area6",
            "colorSet": "knit",
            "patterns": []
        }
    ],
    "MPB": [
        {
            "code": "MPB",
            "area": "area1",
            "colorSet": "knit",
            "patterns": []
        },
        {
            "code": "MPB",
            "area": "area3",
            "colorSet": "knit",
            "patterns": []
        },
        {
            "code": "MPB",
            "area": "area4",
            "colorSet": "knit",
            "patterns": []
        }
    ],
    "GLP0909": [
        {
            "code": "GLP0909",
            "area": "area3",
            "colorSet": "knit",
            "patterns": [
                "ribKnit"
            ]
        }
    ],
    "PPVF2": [
        {
            "code": "PPVF2",
            "area": "area3",
            "colorSet": null,
            "patterns": [
                "autoDigitalCamo"
            ]
        }
    ],
    "PPVF3": [
        {
            "code": "PPVF3",
            "area": "area3",
            "colorSet": null,
            "patterns": [
                "carbonBlock"
            ]
        }
    ],
    "PPVF5": [
        {
            "code": "PPVF5",
            "area": "area3",
            "colorSet": null,
            "patterns": [
                "digitalWave-element"
            ]
        }
    ],
    "VFP2": [
        {
            "code": "VFP2",
            "area": "area3",
            "colorSet": null,
            "patterns": [
                "autoDigitalCamo"
            ]
        }
    ],
    "VFP3": [
        {
            "code": "VFP3",
            "area": "area3",
            "colorSet": null,
            "patterns": [
                "carbonBlock"
            ]
        }
    ],
    "VFP5": [
        {
            "code": "VFP5",
            "area": "area3",
            "colorSet": null,
            "patterns": [
                "digitalWave-element"
            ]
        }
    ],
    "PPMZ": [
        {
            "code": "PPMZ",
            "area": "area1",
            "colorSet": null,
            "patterns": [
                "tigerStripe"
            ]
        },
        {
            "code": "PPMZ",
            "area": "area3",
            "colorSet": null,
            "patterns": [
                "tigerStripe"
            ]
        }
    ]
}