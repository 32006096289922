var _ = require('lodash')
var getThinModel = require('./getThinModel')
var config = require('./../../data').config

module.exports = getSummary

function getOptionSummary(optionName, data, getChildOptionName) {
    getChildOptionName = getChildOptionName || _.startCase
    if (data == null) {
        return {
            optionName: optionName,
            valueId: 'N/A',
            valueName: 'Not available',
        }
    }
    if (!_.isPlainObject(data)) {
        if (data.item && data.item() == null) {
            console.log(
                'No value for',
                data.$path,
                '-',
                data._value,
                data._isDisabled
            )
        }
        return {
            optionName: optionName,
            valueId: data.value(),
            valueName: data.text(),
        }
    }
    return {
        optionName: optionName,
        options: _.map(data, function(value, key) {
            var optionName = getChildOptionName(key)
            return getOptionSummary(optionName, value)
        }),
    }
}

function getColorAreasSummary(optionName, colorAreas, product) {
    var isReversible = product.isReversible
    var areaTitles = product.colorAreas.titles

    return {
        optionName: optionName,
        // options: getOptionSummary(optionName, data.area1)
        options: _.map(colorAreas, function(areaData, areaId) {
            var getChildOptionName = undefined

            var areaKeyMap = {}

            if (product.isReversible) {
                areaKeyMap = {
                    area1: 'area1/2',
                    area2: 'area3/4',
                    area3: 'area5',
                }

                var colorKeyMap = {}
                if (areaId === 'area2') {
                    colorKeyMap = {
                        color_1: 'color_3',
                        color_2: 'color_4',
                    }
                }

                if (product.code === 'RVFJFG') {
                    if (areaId === 'area1') {
                        colorKeyMap = {
                            color_1: 'color_2',
                        }
                    }
                    if (areaId === 'area2') {
                        colorKeyMap = {
                            color_1: 'color_4',
                        }
                    }
                }

                getChildOptionName = function(key) {
                    return _.startCase(colorKeyMap[key] || key)
                }
            }

            var areaKey = areaKeyMap[areaId] || areaId
            var areaNr = areaKey.replace('area', '')
            var optionName = areaTitles[areaId] + ' (Area ' + areaNr + ')'
            return getOptionSummary(optionName, areaData, getChildOptionName)
        }),
    }
}

function getSummary(designData) {
    console.log('designData', designData)
    var data = getThinModel(designData)

    return _.compact([
        {
            optionName: config.sportTitle + ' ' + data.clothingType.text(),
            options: [
                {
                    optionName: 'Model',
                    valueId: data.product.value(),
                    valueName: data.product.text() || data.product.value(),
                },
            ],
        },
        getColorAreasSummary(
            'Fabric colors',
            data.colorAreas,
            data.product.item()
        ),
        getOptionSummary('Options', data.options),
        getOptionSummary('Decoration', data.decoration),
        data.optionsInside &&
            getOptionSummary('Options (inside)', data.optionsInside),
        data.decorationInside &&
            getOptionSummary(
                'Decoration (inside - d placements)',
                data.decorationInside
            ),
    ])
}
