module.exports={
    "vt-0": {
        "code": "vt-0",
        "name": "0",
        "decoPackageId": "vt",
        "legalValues": {
            "teamName": {
                "height": [
                    "1",
                    "1.5"
                ]
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "vt-1": {
        "code": "vt-1",
        "name": "1",
        "decoPackageId": "vt",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "vt-2": {
        "code": "vt-2",
        "name": "2",
        "decoPackageId": "vt",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "vt-3": {
        "code": "vt-3",
        "name": "3",
        "decoPackageId": "vt",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "vt-4": {
        "code": "vt-4",
        "name": "4",
        "decoPackageId": "vt",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "vt-5": {
        "code": "vt-5",
        "name": "5",
        "decoPackageId": "vt",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": [
                    "8",
                    "10"
                ]
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "vt-5B": {
        "code": "vt-5B",
        "name": "5B",
        "decoPackageId": "vt",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "vt-6": {
        "code": "vt-6",
        "name": "6",
        "decoPackageId": "vt",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "vt-14P": {
        "code": "vt-14P",
        "name": "14P",
        "decoPackageId": "vt",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "vt-14": {
        "code": "vt-14",
        "name": "14",
        "decoPackageId": "vt",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": [
                    "2.5"
                ]
            },
            "customLogo": {
                "height": [
                    "2.5"
                ]
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "vt-17L": {
        "code": "vt-17L",
        "name": "17L",
        "decoPackageId": "vt",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "vt-17R": {
        "code": "vt-17R",
        "name": "17R",
        "decoPackageId": "vt",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "vt-17LR": {
        "code": "vt-17LR",
        "name": "17LR",
        "decoPackageId": "vt",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": [
                    "3",
                    "4"
                ]
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": [
                    "2",
                    "3.5"
                ]
            },
            "customLogo": {
                "height": [
                    "2",
                    "3.5"
                ]
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "vt-18L": {
        "code": "vt-18L",
        "name": "18L",
        "decoPackageId": "vt",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "vt-18R": {
        "code": "vt-18R",
        "name": "18R",
        "decoPackageId": "vt",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "vt-18LR": {
        "code": "vt-18LR",
        "name": "18LR",
        "decoPackageId": "vt",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": [
                    "3",
                    "4"
                ]
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": [
                    "2",
                    "3.5"
                ]
            },
            "customLogo": {
                "height": [
                    "2",
                    "3.5"
                ]
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "vt-30": {
        "code": "vt-30",
        "name": "30",
        "decoPackageId": "vt",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": [
                    "10",
                    "12"
                ]
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "vt-30B": {
        "code": "vt-30B",
        "name": "30B",
        "decoPackageId": "vt",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "rvt-0": {
        "code": "rvt-0",
        "name": "0",
        "decoPackageId": "rvt",
        "legalValues": {
            "teamName": {
                "height": [
                    "1",
                    "1.5"
                ]
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "rvt-1": {
        "code": "rvt-1",
        "name": "1",
        "decoPackageId": "rvt",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "rvt-2": {
        "code": "rvt-2",
        "name": "2",
        "decoPackageId": "rvt",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "rvt-3": {
        "code": "rvt-3",
        "name": "3",
        "decoPackageId": "rvt",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "rvt-4": {
        "code": "rvt-4",
        "name": "4",
        "decoPackageId": "rvt",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "rvt-5": {
        "code": "rvt-5",
        "name": "5",
        "decoPackageId": "rvt",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": [
                    "8",
                    "10"
                ]
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "rvt-5B": {
        "code": "rvt-5B",
        "name": "5B",
        "decoPackageId": "rvt",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "rvt-6": {
        "code": "rvt-6",
        "name": "6",
        "decoPackageId": "rvt",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "rvt-14P": {
        "code": "rvt-14P",
        "name": "14P",
        "decoPackageId": "rvt",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "rvt-14": {
        "code": "rvt-14",
        "name": "14",
        "decoPackageId": "rvt",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": [
                    "2.5"
                ]
            },
            "customLogo": {
                "height": [
                    "2.5"
                ]
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "rvt-17L": {
        "code": "rvt-17L",
        "name": "17L",
        "decoPackageId": "rvt",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "rvt-17R": {
        "code": "rvt-17R",
        "name": "17R",
        "decoPackageId": "rvt",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "rvt-17LR": {
        "code": "rvt-17LR",
        "name": "17LR",
        "decoPackageId": "rvt",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": [
                    "3",
                    "4"
                ]
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": [
                    "2",
                    "3.5"
                ]
            },
            "customLogo": {
                "height": [
                    "2",
                    "3.5"
                ]
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "rvt-18L": {
        "code": "rvt-18L",
        "name": "18L",
        "decoPackageId": "rvt",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "rvt-18R": {
        "code": "rvt-18R",
        "name": "18R",
        "decoPackageId": "rvt",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "rvt-18LR": {
        "code": "rvt-18LR",
        "name": "18LR",
        "decoPackageId": "rvt",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": [
                    "3",
                    "4"
                ]
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": [
                    "2",
                    "3.5"
                ]
            },
            "customLogo": {
                "height": [
                    "2",
                    "3.5"
                ]
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "rvt-30": {
        "code": "rvt-30",
        "name": "30",
        "decoPackageId": "rvt",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": [
                    "10",
                    "12"
                ]
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "rvt-30B": {
        "code": "rvt-30B",
        "name": "30B",
        "decoPackageId": "rvt",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "fullSublimation-0": {
        "code": "fullSublimation-0",
        "name": "0",
        "decoPackageId": "fullSublimation",
        "legalValues": {
            "teamName": {
                "height": [
                    "1",
                    "1.5"
                ]
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": [
                    "2.5"
                ]
            },
            "customLogo": {
                "height": [
                    "2.5"
                ]
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "fullSublimation-1": {
        "code": "fullSublimation-1",
        "name": "1",
        "decoPackageId": "fullSublimation",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "fullSublimation-2": {
        "code": "fullSublimation-2",
        "name": "2",
        "decoPackageId": "fullSublimation",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "fullSublimation-3": {
        "code": "fullSublimation-3",
        "name": "3",
        "decoPackageId": "fullSublimation",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "fullSublimation-4": {
        "code": "fullSublimation-4",
        "name": "4",
        "decoPackageId": "fullSublimation",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "fullSublimation-5": {
        "code": "fullSublimation-5",
        "name": "5",
        "decoPackageId": "fullSublimation",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": [
                    "8",
                    "10"
                ]
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "fullSublimation-5B": {
        "code": "fullSublimation-5B",
        "name": "5B",
        "decoPackageId": "fullSublimation",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "fullSublimation-6": {
        "code": "fullSublimation-6",
        "name": "6",
        "decoPackageId": "fullSublimation",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "fullSublimation-14P": {
        "code": "fullSublimation-14P",
        "name": "14P",
        "decoPackageId": "fullSublimation",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "fullSublimation-14": {
        "code": "fullSublimation-14",
        "name": "14",
        "decoPackageId": "fullSublimation",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": [
                    "2.5"
                ]
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": [
                    "2.5"
                ]
            },
            "customLogo": {
                "height": [
                    "2.5"
                ]
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "fullSublimation-17L": {
        "code": "fullSublimation-17L",
        "name": "17L",
        "decoPackageId": "fullSublimation",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "fullSublimation-17R": {
        "code": "fullSublimation-17R",
        "name": "17R",
        "decoPackageId": "fullSublimation",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "fullSublimation-17LR": {
        "code": "fullSublimation-17LR",
        "name": "17LR",
        "decoPackageId": "fullSublimation",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": [
                    "3",
                    "4"
                ]
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": [
                    "2",
                    "3.5"
                ]
            },
            "customLogo": {
                "height": [
                    "2",
                    "3.5"
                ]
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "fullSublimation-18L": {
        "code": "fullSublimation-18L",
        "name": "18L",
        "decoPackageId": "fullSublimation",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "fullSublimation-18R": {
        "code": "fullSublimation-18R",
        "name": "18R",
        "decoPackageId": "fullSublimation",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "fullSublimation-18LR": {
        "code": "fullSublimation-18LR",
        "name": "18LR",
        "decoPackageId": "fullSublimation",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": [
                    "3",
                    "4"
                ]
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": [
                    "2",
                    "3.5"
                ]
            },
            "customLogo": {
                "height": [
                    "2",
                    "3.5"
                ]
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "fullSublimation-30": {
        "code": "fullSublimation-30",
        "name": "30",
        "decoPackageId": "fullSublimation",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": [
                    "10",
                    "12"
                ]
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "fullSublimation-30B": {
        "code": "fullSublimation-30B",
        "name": "30B",
        "decoPackageId": "fullSublimation",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "fasttrack-0": {
        "code": "fasttrack-0",
        "name": "0",
        "decoPackageId": "fasttrack",
        "legalValues": {
            "teamName": {
                "height": [
                    "1",
                    "1.5"
                ]
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": [
                    "1",
                    "1.5"
                ]
            },
            "customLogo": {
                "height": [
                    "1",
                    "1.5"
                ]
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "fasttrack-1": {
        "code": "fasttrack-1",
        "name": "1",
        "decoPackageId": "fasttrack",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "fasttrack-2": {
        "code": "fasttrack-2",
        "name": "2",
        "decoPackageId": "fasttrack",
        "legalValues": {
            "teamName": {
                "height": [
                    "3",
                    "4"
                ]
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "fasttrack-3": {
        "code": "fasttrack-3",
        "name": "3",
        "decoPackageId": "fasttrack",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": [
                    "3",
                    "4"
                ]
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": [
                    "3",
                    "4"
                ]
            },
            "customLogo": {
                "height": [
                    "3",
                    "4"
                ]
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "fasttrack-4": {
        "code": "fasttrack-4",
        "name": "4",
        "decoPackageId": "fasttrack",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": [
                    "3",
                    "4"
                ]
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": [
                    "3",
                    "4"
                ]
            },
            "customLogo": {
                "height": [
                    "3",
                    "4"
                ]
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "fasttrack-5": {
        "code": "fasttrack-5",
        "name": "5",
        "decoPackageId": "fasttrack",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": [
                    "6",
                    "8",
                    "10"
                ]
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": [
                    "6",
                    "8",
                    "10"
                ]
            },
            "customLogo": {
                "height": [
                    "6",
                    "8",
                    "10"
                ]
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "fasttrack-5B": {
        "code": "fasttrack-5B",
        "name": "5B",
        "decoPackageId": "fasttrack",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": [
                    "6",
                    "8",
                    "10"
                ]
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": [
                    "6",
                    "8",
                    "10"
                ]
            }
        }
    },
    "fasttrack-6": {
        "code": "fasttrack-6",
        "name": "6",
        "decoPackageId": "fasttrack",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": [
                    "3",
                    "4"
                ]
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": [
                    "3",
                    "4"
                ]
            },
            "customLogo": {
                "height": [
                    "3",
                    "4"
                ]
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "fasttrack-14P": {
        "code": "fasttrack-14P",
        "name": "14P",
        "decoPackageId": "fasttrack",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": [
                    "2"
                ]
            },
            "customLogo": {
                "height": [
                    "2"
                ]
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "fasttrack-14": {
        "code": "fasttrack-14",
        "name": "14",
        "decoPackageId": "fasttrack",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": [
                    "2",
                    "2.5"
                ]
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": [
                    "2"
                ]
            },
            "customLogo": {
                "height": [
                    "2"
                ]
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "fasttrack-17L": {
        "code": "fasttrack-17L",
        "name": "17L",
        "decoPackageId": "fasttrack",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "fasttrack-17R": {
        "code": "fasttrack-17R",
        "name": "17R",
        "decoPackageId": "fasttrack",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "fasttrack-17LR": {
        "code": "fasttrack-17LR",
        "name": "17LR",
        "decoPackageId": "fasttrack",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": [
                    "3",
                    "5"
                ]
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": [
                    "3",
                    "5"
                ]
            },
            "customLogo": {
                "height": [
                    "3",
                    "5"
                ]
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "fasttrack-18L": {
        "code": "fasttrack-18L",
        "name": "18L",
        "decoPackageId": "fasttrack",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "fasttrack-18R": {
        "code": "fasttrack-18R",
        "name": "18R",
        "decoPackageId": "fasttrack",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "fasttrack-18LR": {
        "code": "fasttrack-18LR",
        "name": "18LR",
        "decoPackageId": "fasttrack",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "fasttrack-30": {
        "code": "fasttrack-30",
        "name": "30",
        "decoPackageId": "fasttrack",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": [
                    "8",
                    "10",
                    "12"
                ]
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": [
                    "8",
                    "10",
                    "12"
                ]
            },
            "customLogo": {
                "height": [
                    "8",
                    "10",
                    "12"
                ]
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "fasttrack-30B": {
        "code": "fasttrack-30B",
        "name": "30B",
        "decoPackageId": "fasttrack",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": [
                    "8",
                    "10",
                    "12"
                ]
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": [
                    "8",
                    "10",
                    "12"
                ]
            }
        }
    },
    "pantDeco-27L": {
        "code": "pantDeco-27L",
        "name": "27L",
        "decoPackageId": "pantDeco",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "pantDeco-27R": {
        "code": "pantDeco-27R",
        "name": "27R",
        "decoPackageId": "pantDeco",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "pantDeco-27LR": {
        "code": "pantDeco-27LR",
        "name": "27LR",
        "decoPackageId": "pantDeco",
        "legalValues": {
            "teamName": {
                "height": [
                    "1.5",
                    "2"
                ]
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": [
                    "2"
                ]
            },
            "customLogo": {
                "height": [
                    "1.5",
                    "2"
                ]
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "pantDeco-28L": {
        "code": "pantDeco-28L",
        "name": "28L",
        "decoPackageId": "pantDeco",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "pantDeco-28R": {
        "code": "pantDeco-28R",
        "name": "28R",
        "decoPackageId": "pantDeco",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": []
            },
            "customLogo": {
                "height": []
            },
            "watermarkLogo": {
                "height": []
            }
        }
    },
    "pantDeco-28LR": {
        "code": "pantDeco-28LR",
        "name": "28LR",
        "decoPackageId": "pantDeco",
        "legalValues": {
            "teamName": {
                "height": []
            },
            "playerName": {
                "height": []
            },
            "numberFront": {
                "height": []
            },
            "numberBack": {
                "height": []
            },
            "numberSleeve": {
                "height": []
            },
            "watermarkText": {
                "height": []
            },
            "stockLogo": {
                "height": [
                    "2"
                ]
            },
            "customLogo": {
                "height": [
                    "1.5",
                    "3"
                ]
            },
            "watermarkLogo": {
                "height": []
            }
        }
    }
}