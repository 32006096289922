var _ = require('lodash')

module.exports = function getThinModel(designData){
    var clothingType = designData.clothingType
    var product = designData.designTemplate
    var productData = designData[clothingType.value()]
    var productDataInside = designData[clothingType.value()+'Inside']

    return reduceNull({
        clothingType: clothingType,
        product: product,
        colorAreas: _.mapValues(productData.colorAreas, reduceAreaFill),
        options: _.assign({
            fitType: !designData.filter.clothingFitType.isDisabled() && designData.filter.clothingFitType,
            cutType: designData.filter.clothingCutType,
        }, _.mapValues(productData.options, reduceOption)),
        decoration: _.mapValues(productData.decoration, reduceDecoration),
        optionsInside: productDataInside && !productDataInside.isDisabled() && _.mapValues(productDataInside.options, reduceOption),
        decorationInside: productDataInside && !productDataInside.isDisabled() && _.mapValues(productDataInside.decoration, reduceDecoration),
    })
}

function reduceNull(value){
    if(value == null){
        return null
    }
    if(_.isFunction(value.value)){
        return value
    }
    var next = _.omit(_.mapValues(value, reduceNull), _.isEmpty)
    if(_.isEmpty(next)){
        return null
    }
    return next
}

function reduceAreaFill(areaFill) {
    if(areaFill.isDisabled && areaFill.isDisabled()){
        return null
    }
    var pattern = areaFill.pattern.item()
    if(!pattern){
        return null
    }
    if(areaFill.pattern.value() == 'solidColor'){
        return areaFill.color_1
    }
    if(pattern.colorCount === 0){
        return areaFill.pattern
    }
    return _.transform(areaFill, function(result, prop, key){
        if(_.startsWith(key, 'color_')){
            var colorIndex = parseInt(key.replace('color_', ''))
            if(colorIndex > pattern.colorCount){
                return
            }
        }
        result[key] = prop
    })
}

function reduceOption(option){
    if(option.type && option.type.value() === 'none'){
        return null
    }
    return reduceDisabled(option)
}

function reduceDecoration(decoration, name){
    if(decoration.placement && decoration.placement.value() === 'none'){
        return null
    }
    if(decoration.enabled && _.isFunction(decoration.enabled.value) && decoration.enabled.value() === 'off'){
        return null
    }
    if(decoration.text && _.isFunction(decoration.text.value) && decoration.text.value() === ''){
      // Tailing can have empty `text`
        if(!decoration.enabled) {
            return null
        }
    }

    if(decoration.pattern){
        return reduceAreaFill(decoration)
    }

    if(_.isPlainObject(decoration)){
        return _.mapValues(decoration, reduceDecoration)
    }
    return reduceDisabled(decoration)
}

function reduceDisabled(obj){
    if(obj.isDisabled && obj.isDisabled()){
        return null
    }
    if(_.isFunction(obj.value) && obj.value() === 'none'){
        return null
    }
    if(_.isPlainObject(obj)){
        return _.transform(obj, function(result, prop, key){
            var value = reduceDisabled(prop)
            if(value != null){
                result[key] = value
            }
        })
    }
    return obj
}
