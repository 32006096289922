module.exports={
    "0": {
        "name": "Center Chest",
        "abbr": "0",
        "code": "0",
        "conflictsWith": [
            "1",
            "2",
            "3"
        ],
        "layerName": null,
        "limitations": {
            "sizeList": []
        }
    },
    "1": {
        "name": "Upper Left Chest",
        "abbr": "1",
        "code": "1",
        "conflictsWith": [
            "0",
            "2"
        ],
        "layerName": null,
        "limitations": {
            "sizeList": []
        }
    },
    "2": {
        "name": "Upper Center Chest",
        "abbr": "2",
        "code": "2",
        "conflictsWith": [
            "0"
        ],
        "layerName": null,
        "limitations": {
            "sizeList": []
        }
    },
    "3": {
        "name": "Upper Left Chest",
        "abbr": "3",
        "code": "3",
        "conflictsWith": [
            "0",
            "2"
        ],
        "layerName": null,
        "limitations": {
            "sizeList": []
        }
    },
    "4": {
        "name": "Middle Right",
        "abbr": "4",
        "code": "4",
        "conflictsWith": [
            "5"
        ],
        "layerName": null,
        "limitations": {
            "sizeList": [
                "3",
                "4"
            ]
        }
    },
    "5": {
        "name": "Front Center",
        "abbr": "5",
        "code": "5",
        "conflictsWith": [
            "4"
        ],
        "layerName": null,
        "limitations": {
            "sizeList": [
                "8",
                "10"
            ]
        }
    },
    "6": {
        "name": "Middle Left",
        "abbr": "6",
        "code": "6",
        "conflictsWith": [
            "5"
        ],
        "layerName": null,
        "limitations": {
            "sizeList": [
                "3",
                "4"
            ]
        }
    },
    "14": {
        "name": "Upper Back",
        "abbr": "14",
        "code": "14",
        "conflictsWith": [],
        "layerName": null,
        "limitations": {
            "sizeList": [
                "2.5"
            ]
        }
    },
    "30": {
        "name": "Back",
        "abbr": "30",
        "code": "30",
        "conflictsWith": [],
        "layerName": null,
        "limitations": {
            "sizeList": []
        }
    },
    "none": {
        "name": "None",
        "abbr": "NONE",
        "code": "none",
        "conflictsWith": [],
        "layerName": null,
        "limitations": {
            "sizeList": []
        }
    },
    "5B": {
        "name": "Watermark (front)",
        "abbr": "5B",
        "code": "5B",
        "conflictsWith": [],
        "layerName": null,
        "limitations": {
            "sizeList": []
        }
    },
    "14P": {
        "name": "Upper Back",
        "abbr": "14P",
        "code": "14P",
        "conflictsWith": [],
        "layerName": null,
        "limitations": {
            "sizeList": []
        }
    },
    "17LR": {
        "name": "Center Sleeve",
        "abbr": "17LR",
        "code": "17LR",
        "conflictsWith": [],
        "layerName": "18LR",
        "limitations": {
            "sizeList": [
                "2",
                "3.5"
            ]
        }
    },
    "18LR": {
        "name": "Cowl",
        "abbr": "18LR",
        "code": "18LR",
        "conflictsWith": [],
        "layerName": "17LR",
        "limitations": {
            "sizeList": [
                "2",
                "3.5"
            ]
        }
    },
    "27LR": {
        "name": "Side top to bottom",
        "abbr": "27LR",
        "code": "27LR",
        "conflictsWith": [],
        "layerName": null,
        "limitations": {
            "sizeList": []
        }
    },
    "28LR": {
        "name": "Upper Side",
        "abbr": "28LR",
        "code": "28LR",
        "conflictsWith": [],
        "layerName": null,
        "limitations": {
            "sizeList": []
        }
    },
    "30B": {
        "name": "Watermark (back)",
        "abbr": "30B",
        "code": "30B",
        "conflictsWith": [],
        "layerName": null,
        "limitations": {
            "sizeList": []
        }
    },
    "5and30": {
        "name": "Front and Back",
        "abbr": "&&",
        "code": "5and30",
        "conflictsWith": [],
        "layerName": null,
        "limitations": {
            "sizeList": []
        }
    }
}