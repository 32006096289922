var _ = require('lodash')

var viewList = [
    {
        code: 'front',
        name: 'Front',
        angles: {
            azimuthal: -28,
            polar: -10,
        },
        snapshotDetails: {
            width: 960,
            height: 960,
        },
    },
    {
        code: 'left',
        name: 'Left',
        angles: {
            azimuthal: 90,
            polar: -22,
        },
    },
    {
        code: 'back',
        name: 'Back',
        angles: {
            azimuthal: 180 - 28,
            polar: -10,
        },
        snapshotDetails: {
            width: 960,
            height: 960,
        },
    },
    {
        code: 'right',
        name: 'Right',
        angles: {
            azimuthal: -90,
            polar: -22,
        },
    },
]
var categoryMap = {
    baseball: 2,
    softball: 16,
    football: 13,
}

module.exports = {
    projectName: 'rawlingsFootballUniform',
    projectTitle: 'Rawlings Football Uniform Builder',
    sportTitle: 'Football',
    sportId: 'football',
    vendorConfig: {
        'productSubset': 'default', //also supports 'bsn'
        'homePageTitle': 'return to mylocker.rawlings.com',
        'homePageUrl': 'http://mylocker.rawlings.com',
        'landingPageUrl': 'none',
        'shoppingCartUrl':
            'https://mylocker.rawlings.com/save.aspx?category=' +
            categoryMap.football,
        'shoppingCartMethod': 'LABELED-POST',
        'optionalParameters': {
        },
        'mobileTriggerWidth': 960,
        'ui': {
            'orderSectionTitle': 'Save',
        },
    },
    projectConfig: {
        defaultMenu: 'clothingStyle',
    },

    viewList: viewList,
    viewMap: _.indexBy(viewList, 'code'),
    snapshotList: _.filter(viewList, 'snapshotDetails'),

    canvasConfig: {
        path: '/canvas-preview-assets/',
        majorVersion: '2015-01-14',
        minorVersion: '2015-01-14',
    },
    assetConfig: {
        minorVersion: '2014-09-30',
    },
}
