module.exports={
    "beltColor": {
        "code": "beltColor",
        "name": "Belt Color"
    },
    "logoColor": {
        "code": "logoColor",
        "name": "Rawlings Logo Color"
    },
    "CLSU": {
        "code": "CLSU",
        "name": "CLSU insert"
    },
    "sleeveStripes": {
        "code": "sleeveStripes",
        "name": "Sleeve Stripes"
    }
}