module.exports={
    "straight": {
        "code": "straight",
        "name": "Straight",
        "canHaveTailing": true,
        "subsets": {
            "fasttrack": true,
            "straightOnly": true
        }
    },
    "angled": {
        "code": "angled",
        "name": "Angled",
        "canHaveTailing": true,
        "subsets": {
            "fasttrack": true,
            "straightOnly": false
        }
    },
    "verticalArch": {
        "code": "verticalArch",
        "name": "Vertical Arch",
        "canHaveTailing": false,
        "subsets": {
            "fasttrack": true,
            "straightOnly": false
        }
    }
}