module.exports={
    "jersey": {
        "frontBackNumber": {
            "name": "Front / Back Number",
            "clothingType": "jersey",
            "code": "frontBackNumber",
            "contentType": "text",
            "placementList": [
                "none",
                "5",
                "30",
                "5and30"
            ],
            "sizeList": [],
            "configurationList": [],
            "align": "top",
            "fitMode": "fitHeight scaleWidth",
            "maxCharCount": null,
            "maxWidth": null,
            "maxHeight": null
        },
        "teamName": {
            "name": "Team Name",
            "clothingType": "jersey",
            "code": "teamName",
            "contentType": "text",
            "placementList": [
                "none",
                "0"
            ],
            "sizeList": [
                "1",
                "1.5"
            ],
            "configurationList": [
                "straight"
            ],
            "align": "top",
            "fitMode": "fitHeight scaleWidth",
            "maxCharCount": 15,
            "maxWidth": "width??",
            "maxHeight": "1.5"
        },
        "frontNumber": {
            "name": "Front Number",
            "clothingType": "jersey",
            "code": "frontNumber",
            "contentType": "text",
            "placementList": [
                "none",
                "5"
            ],
            "sizeList": [
                "8",
                "10"
            ],
            "configurationList": [],
            "align": "top",
            "fitMode": "fitHeight scaleWidth",
            "maxCharCount": 2,
            "maxWidth": null,
            "maxHeight": "10"
        },
        "backNumber": {
            "name": "Back Number",
            "clothingType": "jersey",
            "code": "backNumber",
            "contentType": "text",
            "placementList": [
                "none",
                "30"
            ],
            "sizeList": [
                "10",
                "12"
            ],
            "configurationList": [],
            "align": "top",
            "fitMode": "fitHeight scaleWidth",
            "maxCharCount": 2,
            "maxWidth": null,
            "maxHeight": "12"
        },
        "sleeveNumber": {
            "name": "Cowl or Sleeve Number",
            "clothingType": "jersey",
            "code": "sleeveNumber",
            "contentType": "text",
            "placementList": [
                "none",
                "17LR",
                "18LR"
            ],
            "sizeList": [
                "3",
                "4"
            ],
            "configurationList": [],
            "align": "top",
            "fitMode": "fitHeight scaleWidth",
            "maxCharCount": 2,
            "maxWidth": null,
            "maxHeight": "4"
        },
        "playerName": {
            "name": "Player Name",
            "clothingType": "jersey",
            "code": "playerName",
            "contentType": "text",
            "placementList": [
                "none",
                "14"
            ],
            "sizeList": [
                "2.5"
            ],
            "configurationList": [
                "straight"
            ],
            "align": "top",
            "fitMode": "fitHeight scaleWidth",
            "maxCharCount": 15,
            "maxWidth": "width??",
            "maxHeight": "2.5"
        },
        "customLogo": {
            "name": "Custom Logo",
            "clothingType": "jersey",
            "code": "customLogo",
            "contentType": "graphic",
            "placementList": [
                "none",
                "17LR",
                "18LR",
                "14"
            ],
            "sizeList": [
                "3",
                "2.5",
                "4"
            ],
            "configurationList": [],
            "align": "center",
            "fitMode": "fitBox",
            "maxCharCount": null,
            "maxWidth": "4",
            "maxHeight": "4"
        },
        "stockLogo": {
            "name": "Stock Logo",
            "clothingType": "jersey",
            "code": "stockLogo",
            "contentType": "graphic",
            "placementList": [
                "none",
                "17LR",
                "18LR",
                "14"
            ],
            "sizeList": [
                "2",
                "2.5",
                "3.5"
            ],
            "configurationList": [],
            "align": "center",
            "fitMode": "fitBox",
            "maxCharCount": null,
            "maxWidth": "3.5",
            "maxHeight": "3.5"
        },
        "sleeveStripes": {
            "name": "Sleeve Stripes",
            "clothingType": "jersey",
            "code": "sleeveStripes",
            "contentType": "graphic",
            "placementList": [
                "none",
                "17LR"
            ],
            "sizeList": [],
            "configurationList": [],
            "align": null,
            "fitMode": null,
            "maxCharCount": null,
            "maxWidth": null,
            "maxHeight": null
        },
        "watermarkText": {
            "name": "Watermark Text",
            "clothingType": "jersey",
            "code": "watermarkText",
            "contentType": "text",
            "placementList": [
                "none",
                "5B",
                "30B"
            ],
            "sizeList": [
                "6",
                "8",
                "10",
                "12"
            ],
            "configurationList": [
                "straight"
            ],
            "align": "center",
            "fitMode": "fitHeight scaleWidth",
            "maxCharCount": 3,
            "maxWidth": null,
            "maxHeight": null
        },
        "watermarkLogo": {
            "name": "Watermark Logo",
            "clothingType": "jersey",
            "code": "watermarkLogo",
            "contentType": "graphic",
            "placementList": [
                "none",
                "5B",
                "30B"
            ],
            "sizeList": [
                "6",
                "8",
                "10",
                "12"
            ],
            "configurationList": [],
            "align": "center",
            "fitMode": "fitBox",
            "maxCharCount": null,
            "maxWidth": null,
            "maxHeight": null
        }
    },
    "pant": {
        "teamName": {
            "name": "Team Name",
            "clothingType": "pant",
            "code": "teamName",
            "contentType": "text",
            "placementList": [
                "none",
                "27LR"
            ],
            "sizeList": [
                "1.5",
                "2"
            ],
            "configurationList": [
                "straight"
            ],
            "align": "center",
            "fitMode": "fitHeight scaleWidth",
            "maxCharCount": 15,
            "maxWidth": "width??",
            "maxHeight": "2"
        },
        "customLogo": {
            "name": "Custom Logo",
            "clothingType": "pant",
            "code": "customLogo",
            "contentType": "graphic",
            "placementList": [
                "none",
                "27LR"
            ],
            "sizeList": [
                "1.5",
                "2"
            ],
            "configurationList": [],
            "align": "center",
            "fitMode": "fitBox",
            "maxCharCount": null,
            "maxWidth": "2",
            "maxHeight": "3"
        },
        "stockLogo": {
            "name": "Stock Logo",
            "clothingType": "pant",
            "code": "stockLogo",
            "contentType": "graphic",
            "placementList": [
                "none",
                "27LR"
            ],
            "sizeList": [
                "1.5",
                "2"
            ],
            "configurationList": [],
            "align": "center",
            "fitMode": "fitBox",
            "maxCharCount": null,
            "maxWidth": "2",
            "maxHeight": "3"
        },
        "upperLogo": {
            "name": "Upper Logo",
            "clothingType": "pant",
            "code": "upperLogo",
            "contentType": "graphic",
            "placementList": [
                "none",
                "28LR"
            ],
            "sizeList": [
                "1.5",
                "3"
            ],
            "configurationList": [],
            "align": "center",
            "fitMode": "fitBox",
            "maxCharCount": null,
            "maxWidth": "3",
            "maxHeight": "4"
        }
    }
}