module.exports={
    "2": {
        "icon": "assets/sleeveStripes/2.png?2014-09-30",
        "code": "2",
        "name": "2",
        "abbr": "2",
        "colorCount": 2
    },
    "5": {
        "icon": "assets/sleeveStripes/5.png?2014-09-30",
        "code": "5",
        "name": "5",
        "abbr": "5",
        "colorCount": 2
    },
    "6": {
        "icon": "assets/sleeveStripes/6.png?2014-09-30",
        "code": "6",
        "name": "6",
        "abbr": "6",
        "colorCount": 2
    },
    "15": {
        "icon": "assets/sleeveStripes/15.png?2014-09-30",
        "code": "15",
        "name": "15",
        "abbr": "15",
        "colorCount": 2
    },
    "26": {
        "icon": "assets/sleeveStripes/26.png?2014-09-30",
        "code": "26",
        "name": "26",
        "abbr": "26",
        "colorCount": 2
    },
    "none": {
        "icon": "assets/sleeveStripes/none.png?2014-09-30",
        "code": "none",
        "name": "None",
        "abbr": "NONE",
        "colorCount": 0
    }
}